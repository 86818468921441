<template>
  <section>
    <fieldset v-for="option in items" :key="option.id" class="py-1">
      <nice-radio
        :button="false"
        :value="option.id"
        :model-value="modelValue"
        @update:model-value="$emit('update:model-value', $event)"
        :label="option.id"
        class="leading-6"
      >
        {{ option[labelKey] }}
      </nice-radio>
    </fieldset>
  </section>
</template>

<script>
export default {
  props: {
    emits: ["update:model-value"],
    modelValue: {},
    collection: {
      type: String,
      required: true,
    },
    additionalOptions: {
      type: Array,
      required: false,
    },
    filterFunc: {
      type: Function,
      required: false,
    },
  },
  computed: {
    activeBrokers() {
      return this.$db.get("brokers").filter(b => b.active)
    },
    unarchivedBrokers() {
      return this.$db.get("brokers").filter(b => !b.archived)
    },
    uniqConnections() {
      return this.$db.get("connections").filter(o => !o.parentConnectionId)
    },
    roomResources() {
      return this.$db.get("roomResources").map(rr => ({ id: rr.email, name: rr.name }))
    },
    labelKey() {
      if (["brokers", "activeBrokers", "unarchivedBrokers"].includes(this.collection)) {
        return "internalName"
      }

      return "name"
    },
    items() {
      let items =
        this[this.collection] ||
        (typeof this.$db[this.collection] == "function" ? this.$db[this.collection]() : this.$db.get(this.collection))
      if (this.additionalOptions) items = items.concat(this.additionalOptions)
      if (this.filterFunc) items = items.filter(this.filterFunc)
      return items
    },
  },
}
</script>

<style></style>
