<template>
  <div v-if="participants.length > 0">
    <label>Teilnehmer:</label>
    <div v-for="item in participants" :key="item.email" class="flex items-center mb-2 mt-2">
      <div class="mr-3 relative">
        <avatar :broker="item.user" />
        <div
          class="absolute -right-2 -bottom-1 rounded-full w-5 h-5 flex justify-center items-center text-xs"
          :class="{
            'bg-green-200': item.status == 'yes',
            'bg-red-200': item.status == 'no',
            'bg-yellow-200': item.status == 'maybe',
          }"
        >
          <fa-icon name="check" v-if="item.status == 'yes'" />
          <fa-icon name="times" v-if="item.status == 'no'" />
          <fa-icon name="question" v-if="item.status == 'maybe'" />
        </div>
      </div>
      <span :title="item.email">
        {{ item.name || item.email }}
      </span>
    </div>
  </div>
</template>

<script>
import TodoItem from "./Todoitem"

export default {
  props: {
    resource: {},
  },
  components: { TodoItem },
  computed: {
    participants() {
      return (this.resource.clientsStatus || []).map(p => ({
        ...p,
        user: this.$db.shopData.brokers.find(b => b.email == (p.email || "").toLowerCase()) || {
          name: p.name || p.email,
          color: "#AAAAAA",
        },
      }))
    },
  },
}
</script>
