<template>
  <span>
    <slot />
  </span>
</template>

<style scoped>
span {
  padding: 10px 10px;
  background-position: center center;
  background-size: 100% 24px;
  background-repeat: no-repeat;
  background-image: url("../assets/images/promo-highlighter.png");
  font-weight: 600;
  display: inline-flex;
}
</style>