<template>
  <fa-icon :name="iconName" :class="colorClass" />
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    contentType: String,
  },
  computed: {
    iconName() {
      if (this.contentType == "application/pdf") return "file-pdf"
      if (this.contentType.match(/image\//i)) return "file-image"
      if (this.contentType.match(/word/i)) return "file-word"
      if (this.contentType.match(/sheet/i)) return "file-excel"
      return "file-alt"
    },
    colorClass() {
      if (this.contentType == "application/pdf") return "text-red-600"
      if (this.contentType.match(/image\//i)) return "text-purple-600"
      if (this.contentType.match(/word/i)) return "text-blue-600"
      if (this.contentType.match(/sheet/i)) return "text-green-600"
    },
  },
})
</script>