<template>
  <p class="niceBar" :style="style">
    <slot>
      {{ label }}
    </slot>
  </p>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"

const COLORS = {
  light: "#999999",
  success: "#22dd33",
  warning: "#cc7700",
  danger: "#ff0000",
  info: "#3388ff",
  dark: "#999999",
}
type Style = { background: string }

export default defineComponent({
  props: {
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "success",
      validator: value => Object.keys(COLORS).includes(value),
    },
  },
  computed: {
    color(): string {
      return COLORS[this.type]
    },
    style(): Style {
      return {
        // color: this.color,
        background: `${this.color}30`,
      }
    },
  },
})
</script>

<style scoped>
.niceBar {
  padding: 0.35rem 0.7rem;
  background: rgba(#999999, 0.15);
  color: #222222;
  border-radius: 3px;
  font-size: 0.85rem;
  margin: 0.5rem -0.7rem;
}
</style>