import Chartist from "chartist"

const getChartType = chart => chart.constructor.toString().match(/\.(\w+)\.super\.constructor/)?.[1]

export const ChartistTooltip = options => {
  options = Chartist.extend(
    {},
    {
      currency: undefined,
      currencyFormatCallback: undefined,
      tooltipOffset: {
        x: 0,
        y: -20,
      },
      anchorToPoint: false,
      appendToBody: true,
      class: undefined,
      pointClass: "ct-point",
    },
    options
  )

  return function tooltip(chart) {
    var chartType = getChartType(chart)

    // Warning: If you are using npm link or yarn link, these instanceof checks will fail and you won't any tooltips
    var tooltipSelector = options.pointClass
    if (chartType === "Bar") {
      tooltipSelector = "ct-bar"
    } else if (chartType === "Pie") {
      // Added support for donut graph
      if (chart.options.donut) {
        // Added support for the solid donut graph
        tooltipSelector = chart.options.donutSolid ? "ct-slice-donut-solid" : "ct-slice-donut"
      } else {
        tooltipSelector = "ct-slice-pie"
      }
    }

    var $chart = chart.container
    var $toolTipIsShown = false
    var $tooltipOffsetParent = offsetParent($chart)
    var $toolTip

    if (!options.appendToBody) {
      // searching for existing tooltip in the chart, because appendToBody is disabled
      $toolTip = $chart.querySelector(".chartist-tooltip")
    } else {
      // searching for existing tooltip in the body, because appendToBody is enabled
      $toolTip = document.querySelector(".chartist-tooltip")
    }
    if (!$toolTip) {
      $toolTip = document.createElement("div")
      $toolTip.className = !options.class ? "chartist-tooltip" : "chartist-tooltip " + options.class
      if (!options.appendToBody) {
        $chart.appendChild($toolTip)
      } else {
        document.body.appendChild($toolTip)
      }
    }
    var height = $toolTip.offsetHeight
    var width = $toolTip.offsetWidth

    hide($toolTip)

    function on(event, selector, callback) {
      $chart.addEventListener(event, function (e) {
        if (!selector || hasClass(e.target, selector)) callback(e)
      })
    }

    on("mouseover", tooltipSelector, function (event) {
      var $point = event.target
      var tooltipText = ""

      var isPieChart = chartType === "Pie" ? $point : $point.parentNode
      var seriesName = isPieChart
        ? $point.parentNode.getAttribute("ct:meta") || $point.parentNode.getAttribute("ct:series-name")
        : ""
      var meta = $point.getAttribute("ct:meta") || seriesName || ""
      var hasMeta = !!meta
      var value = $point.getAttribute("ct:value")

      if (options.transformTooltipTextFnc && typeof options.transformTooltipTextFnc === "function") {
        value = options.transformTooltipTextFnc(value)
      }

      if (options.tooltipFnc && typeof options.tooltipFnc === "function") {
        tooltipText = options.tooltipFnc(meta, value)
      } else {
        if (options.metaIsHTML) {
          var txt = document.createElement("textarea")
          txt.innerHTML = meta
          meta = txt.value
        }

        meta = '<span class="chartist-tooltip-meta">' + meta + "</span>"

        if (hasMeta) {
          tooltipText += meta + "<br>"
        } else {
          // For Pie Charts also take the labels into account
          // Could add support for more charts here as well!
          if (chart.constructor.name === Chartist.Pie.prototype.constructor.name) {
            var label = next($point, "ct-label")
            if (label) {
              tooltipText += text(label) + "<br>"
            }
          }
        }

        if (value) {
          if (options.currency) {
            if (options.currencyFormatCallback != undefined) {
              value = options.currencyFormatCallback(value, options)
            } else {
              value = options.currency + value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            }
          }
          value = '<span class="chartist-tooltip-value">' + value + "</span>"
          tooltipText += value
        }
      }

      if (tooltipText) {
        $toolTip.innerHTML = tooltipText

        // Calculate new width and height, as toolTip width/height may have changed with innerHTML change
        height = $toolTip.offsetHeight
        width = $toolTip.offsetWidth

        if (options.appendToBody !== true) {
          $tooltipOffsetParent = offsetParent($chart)
        }
        if ($toolTip.style.display !== "absolute") {
          $toolTip.style.display = "absolute"
        }
        setPosition(event)
        show($toolTip)

        // Remember height and width to avoid wrong position in IE
        height = $toolTip.offsetHeight
        width = $toolTip.offsetWidth
      }
    })

    on("mouseout", tooltipSelector, function () {
      hide($toolTip)
    })

    on("mousemove", null, function (event) {
      if (options.anchorToPoint === false && $toolTipIsShown) {
        setPosition(event)
      }
    })

    function setPosition(event) {
      height = height || $toolTip.offsetHeight
      width = width || $toolTip.offsetWidth
      var offsetX = -width / 2 + options.tooltipOffset.x
      var offsetY = -height + options.tooltipOffset.y

      var anchor = options.anchorToPoint === true && event.target.x2 && event.target.y2

      if (options.appendToBody === true) {
        if (anchor) {
          var box = $chart.getBoundingClientRect()
          var left = event.target.x2.baseVal.value + box.left + window.pageXOffset
          var top = event.target.y2.baseVal.value + box.top + window.pageYOffset

          $toolTip.style.left = left + offsetX + "px"
          $toolTip.style.top = top + offsetY + "px"
        } else {
          $toolTip.style.left = event.pageX + offsetX + "px"
          $toolTip.style.top = event.pageY + offsetY + "px"
        }
      } else {
        var offsetBox = $tooltipOffsetParent.getBoundingClientRect()
        var allOffsetLeft = -offsetBox.left - window.pageXOffset + offsetX
        var allOffsetTop = -offsetBox.top - window.pageYOffset + offsetY

        if (anchor) {
          var box = $chart.getBoundingClientRect()
          var left = event.target.x2.baseVal.value + box.left + window.pageXOffset
          var top = event.target.y2.baseVal.value + box.top + window.pageYOffset

          $toolTip.style.left = left + allOffsetLeft + "px"
          $toolTip.style.top = top + allOffsetTop + "px"
        } else {
          $toolTip.style.left = event.pageX + allOffsetLeft + "px"
          $toolTip.style.top = event.pageY + allOffsetTop + "px"
        }
      }
    }

    /**
     * Shows the tooltip element, if not shown
     * @param element
     */
    function show(element) {
      $toolTipIsShown = true
      if (!hasClass(element, "tooltip-show")) {
        element.className = element.className + " tooltip-show"
      }
    }

    /**
     * Hides the tooltip element
     * @param element
     */
    function hide(element) {
      $toolTipIsShown = false
      var regex = new RegExp("tooltip-show" + "\\s*", "gi")
      element.className = element.className.replace(regex, "").trim()
    }
  }
}

/**
 * Returns whether a element has a css class called className
 * @param element
 * @param className
 * @return {boolean}
 */
function hasClass(element, className) {
  return (" " + element.getAttribute("class") + " ").indexOf(" " + className + " ") > -1
}

function next(element, className) {
  do {
    element = element.nextSibling
  } while (element && !hasClass(element, className))
  return element
}

/**
 *
 * @param element
 * @return {string | string}
 */
function text(element) {
  return element.innerText || element.textContent
}

/**
 * Returns the first positioned parent of the element
 * @return HTMLElement
 */
function offsetParent(elem) {
  if (offsetParent in elem) {
    // Using the native property if possible
    var parent = elem.offsetParent

    if (!parent) {
      parent = document.body.parentElement
    }

    return parent
  }

  var parent = elem.parentNode
  if (!parent) {
    return document.body.parentElement
  }

  if (window.getComputedStyle(parent).position !== "static") {
    return parent
  } else if (parent.tagName === "BODY") {
    return parent.parentElement
  } else {
    return offsetParent(parent)
  }
}

export const ChartistLegend = options => {
  // Catch invalid options
  if (options && options.position) {
    if (!(options.position === "top" || options.position === "bottom" || options.position instanceof HTMLElement)) {
      throw Error("The position you entered is not a valid position")
    }
    if (options.position instanceof HTMLElement) {
      // Detatch DOM element from options object, because Chartist.extend
      // currently chokes on circular references present in HTMLElements
      var cachedDOMPosition = options.position
      delete options.position
    }
  }

  options = Chartist.extend(
    {},
    {
      className: "",
      classNames: false,
      removeAll: false,
      legendNames: false,
      clickable: true,
      onClick: null,
      position: "top",
      colors: null,
    },
    options
  )

  if (cachedDOMPosition) {
    // Reattatch the DOM Element position if it was removed before
    options.position = cachedDOMPosition
  }

  return function legend(chart) {
    var chartType = getChartType(chart)

    function removeLegendElement() {
      var legendElement = chart.container.querySelector(".ct-legend")
      if (legendElement) {
        legendElement.parentNode.removeChild(legendElement)
      }
    }

    // Set a unique className for each series so that when a series is removed,
    // the other series still have the same color.
    function setSeriesClassNames() {
      chart.data.series = chart.data.series.map(function (series, seriesIndex) {
        if (typeof series !== "object") {
          series = {
            value: series,
          }
        }
        series.className =
          series.className || chart.options.classNames.series + "-" + Chartist.alphaNumerate(seriesIndex)
        return series
      })
    }

    function createLegendElement() {
      var legendElement = document.createElement("ul")
      legendElement.className = "ct-legend"
      if (chartType == "Pie") {
        legendElement.classList.add("ct-legend-inside")
      }
      if (typeof options.className === "string" && options.className.length > 0) {
        legendElement.classList.add(options.className)
      }
      if (chart.options.width) {
        legendElement.style.cssText = "width: " + chart.options.width + "px;margin: 0 auto;"
      }
      return legendElement
    }

    // Get the right array to use for generating the legend.
    function getLegendNames(useLabels) {
      return options.legendNames || (useLabels ? chart.data.labels : chart.data.series)
    }

    // Initialize the array that associates series with legends.
    // -1 indicates that there is no legend associated with it.
    function initSeriesMetadata(useLabels) {
      var seriesMetadata = new Array(chart.data.series.length)
      for (var i = 0; i < chart.data.series.length; i++) {
        seriesMetadata[i] = {
          data: chart.data.series[i],
          label: useLabels ? chart.data.labels[i] : null,
          legend: -1,
        }
      }
      return seriesMetadata
    }

    function createNameElement(i, legendText, classNamesViable, metadata) {
      var li = document.createElement("li")
      li.classList.add("ct-series-" + i)
      // Append specific class to a legend element, if viable classes are given
      if (classNamesViable) {
        li.classList.add(options.classNames[i])
      }
      let style = ""
      if (typeof options.colors === "function") {
        const color = options.colors(i)
        style = `background-color: ${color}; border-color: ${color}`
      }
      li.setAttribute("data-legend", i)
      li.innerHTML = `<i style="${style}"></i> ${legendText}`
      return li
    }

    // Append the legend element to the DOM
    function appendLegendToDOM(legendElement) {
      if (!(options.position instanceof HTMLElement)) {
        switch (options.position) {
          case "top":
            chart.container.insertBefore(legendElement, chart.container.childNodes[0])
            break

          case "bottom":
            chart.container.insertBefore(legendElement, null)
            break
        }
      } else {
        // Appends the legend element as the last child of a given HTMLElement
        options.position.insertBefore(legendElement, null)
      }
    }

    function addClickHandler(legendElement, legends, seriesMetadata, useLabels) {
      legendElement.addEventListener("click", function (e) {
        var li = e.target
        if (li.parentNode !== legendElement || !li.hasAttribute("data-legend")) return
        e.preventDefault()

        var legendIndex = parseInt(li.getAttribute("data-legend"))
        var legend = legends[legendIndex]

        if (!legend.active) {
          legend.active = true
          li.classList.remove("inactive")
        } else {
          legend.active = false
          li.classList.add("inactive")

          var activeCount = legends.filter(function (legend) {
            return legend.active
          }).length
          if (!options.removeAll && activeCount == 0) {
            // If we can't disable all series at the same time, let's
            // reenable all of them:
            for (var i = 0; i < legends.length; i++) {
              legends[i].active = true
              legendElement.childNodes[i].classList.remove("inactive")
            }
          }
        }

        var newSeries = []
        var newLabels = []

        for (var i = 0; i < seriesMetadata.length; i++) {
          if (seriesMetadata[i].legend != -1 && legends[seriesMetadata[i].legend].active) {
            newSeries.push(seriesMetadata[i].data)
            newLabels.push(seriesMetadata[i].label)
          }
        }

        chart.data.series = newSeries
        if (useLabels) {
          chart.data.labels = newLabels
        }

        chart.update()

        if (options.onClick) {
          options.onClick(chart, e)
        }
      })
    }

    removeLegendElement()

    var legendElement = createLegendElement()
    var useLabels = chartType === "Pie" && chart.data.labels && chart.data.labels.length
    var legendNames = getLegendNames(useLabels)
    var seriesMetadata = initSeriesMetadata(useLabels)
    var legends = []

    // Check if given class names are viable to append to legends
    var classNamesViable = Array.isArray(options.classNames) && options.classNames.length === legendNames.length

    // Loop through all legends to set each name in a list item.
    legendNames.forEach(function (legend, i) {
      var legendText = legend.name || legend
      var legendSeries = legend.series || [i]

      var li = createNameElement(i, legendText, classNamesViable, legendSeries)
      legendElement.appendChild(li)

      legendSeries.forEach(function (seriesIndex) {
        seriesMetadata[seriesIndex].legend = i
      })

      legends.push({
        text: legendText,
        series: legendSeries,
        active: true,
      })
    })

    chart.on("created", function (data) {
      appendLegendToDOM(legendElement)
    })

    if (options.clickable) {
      setSeriesClassNames()
      addClickHandler(legendElement, legends, seriesMetadata, useLabels)
    }
  }
}
