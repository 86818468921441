<template>
  <form-dialog
    :title="dialogTitle"
    :visible="visible"
    :submit-func="submit"
    @update:visible="updateVisible"
    @complete="complete"
  >
    <form-section v-if="property">
      <form-row title="Neuer Objekt-Status">
        <db-select fixed-position v-model="property.property_status_id" collection="propertyStatuses" />
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
    },
    editable: {
      type: Object,
    },
  },
  data(props) {
    return {
      property: props.editable,
    }
  },
  watch: {
    editable(newValue) {
      this.property = JSON.parse(JSON.stringify(newValue))
    },
  },
  methods: {
    submit() {
      return this.$axios.put(`/portfolio/properties/${this.property.id}`, {
        property: {
          property_status_id: this.property.property_status_id,
        },
      })
    },
    complete() {
      this.$emit("complete", this.property)
      App.flashy("Objekt wurde erfolgreich aktualisiert!")
    },
    updateVisible(visible) {
      if (!visible) this.$emit("close")
    },
  },
  computed: {
    dialogTitle() {
      if (this.editable) return `${this.editable.name} bearbeiten`
    },
  },
}
</script>
