<template>
  <span>
    <template v-for="translation in record.translations" :key="translation.locale">
      <form-row :title="`${fieldLabel} ${flags[translation.locale] || translation.locale} `">
        <nice-input size="small" v-model="translation[fieldName]" @change="toggleDestroyMark(translation)" />
      </form-row>
    </template>
  </span>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
    },
    fieldName: {
      type: String,
    },
    fieldLabel: {
      type: String,
    },
  },
  methods: {
    toggleDestroyMark(translation) {
      if (!translation.id) return

      if (translation[this.fieldName] === "") {
        translation._destroy = true
      } else {
        delete translation._destroy
      }
    },
  },
  computed: {
    locales() {
      return [this.$db.shopData.language].concat(this.$db.shopData.supportedClientLocales || []).filter(Boolean)
    },
    flags() {
      return {
        en: "🇬🇧",
        de: "🇩🇪",
        hu: "🇭🇺",
        es: "🇪🇸",
        fr: "🇫🇷",
        it: "🇮🇹",
      }
    },
  },
  watch: {
    "record.translations": {
      handler(newVal) {
        this.locales.forEach(locale => {
          if (!this.record.translations.find(t => t.locale === locale)) {
            this.record.translations = this.record.translations.concat({ locale, [this.fieldName]: "" })
          }
        })
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
