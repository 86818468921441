import FORM_FIELDS from "@/config/property/form-fields"
import { getObjectTypes, getRelevantRsCategories, getRelevantRsTypes } from "./get-property-options-mapping"
import { formatGroupsByCategories } from "@/config/detail-view-form"

const snakeCase = (text: string): string =>
  text
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase()

const optionsType = {
  name: "String",
  emails: "String",
  address: "String",
  phone: "String",
  link: "String",
  boolean: "Boolean",
  date: "Date",
  dropdown: "Array",
  string: "String",
  text: "String",
  relationship: "Array",
  phones: "String",
  multiselect: "Array",
  tags: "Array",
  number: "Number",
  richtext: "String",
}

export const getPropertyColumns = (db, t) => {
  const options: any[] = []

  const mappedOptions = Object.entries(FORM_FIELDS)
    .map(([k, v]: [string, any]) => ({
      group: v.group,
      value: v.filterName ? v.filterName : snakeCase(k),
      type: optionsType[v.type],
      dboptions: v.dbOptions,
      options: typeof v.options == "function" ? v.options({ db, t }) : v.options,
      filterable: v.filterable,
      remote: v.group == "links" ? "client" : null,
      unit: v.unit,
    }))
    .filter(n => (typeof n.filterable == "function" ? n.filterable({ db }) : n.filterable))
  options.push(...mappedOptions)

  options.push(
    {
      group: "general",
      value: "rs_type",
      type: "Array",
      options: getRelevantRsTypes(db).map(({ value, label }) => ({ id: value, name: label })),
    },
    {
      group: "general",
      value: "rs_category",
      type: "Array",
      options: getRelevantRsCategories(db).map(({ value, label }) => ({ id: value, name: label })),
    },
    {
      group: "general",
      value: "object_type",
      type: "Array",
      options: getObjectTypes(db).map(({ value, label }) => ({ id: value, name: label })),
    },
    {
      group: "address",
      value: "zip_code",
      type: "String",
    },
    {
      group: "address",
      value: "city.raw",
      type: "Array",
      options: db.shopData.cities.map(o => ({ id: o, name: o })),
    },
    {
      group: "address",
      value: "region.raw",
      type: "Array",
      options: db.shopData.regions.map(o => ({ id: o, name: o })),
    },
    {
      group: "address",
      value: "country",
      type: "Array",
      options: db.shopData.countries.map(o => ({ id: o.alphaThree, name: o.name })),
    },
    {
      group: "general",
      value: "marketing_type",
      type: "Array",
      options: [
        { id: "BUY", name: t(`properties.formFieldOptions.buy`) },
        { id: "RENT", name: t(`properties.formFieldOptions.rent`) },
      ],
    },
    {
      group: "general",
      value: "price_per_sqm",
      type: "Number",
    },
    {
      group: "general",
      value: "exposee_id.number",
      type: "Number",
    },
    {
      group: "general",
      value: "publishings",
      type: "Array",
      dboptions: "connections",
    },
    {
      group: "general",
      value: "created_at",
      type: "Date",
    },
    {
      group: "general",
      value: "status_updated_at",
      type: "Date",
    },
    {
      group: "area",
      value: "property_space_value",
      type: "Number",
    },
    ...db.shopData.relationshipPartnerNames.map(p => ({
      nested: "relationships",
      distinct: true,
      group: "links",
      value: `relationships.${p.id}`,
      field: `relationships.client_id`,
      params: {
        term: { "relationships.name": p.name },
      },
      type: "Array",
      remote: "client",
      label: p.name,
      isCustomField: true,
    }))
  )

  if (db.shopData.franchisee || db.shopData.franchiser || db.shopData.connections.some(p => p.format === "MLS")) {
    options.push(
      ...[
        {
          group: "general",
          value: "received_via_mls",
          type: "Boolean",
        },
      ]
    )
  }

  options.push(
    ...db.shopData.customFieldGroupsForProperties
      .map(g =>
        g.customFields.map(cf => {
          let filterType
          let filterOptions
          let dbOptions
          switch (cf.fieldType) {
            case "Broker":
            case "CountrySelect":
            case "Dropdown":
            case "Tags":
            case "TagsByCategory":
            case "Multiselect":
              filterType = "Array"
              break
            case "Boolean":
              filterType = "Boolean"
              break
            case "Number":
              filterType = "Number"
              break
            case "Date":
            case "DateTime":
              filterType = "Date"
              break
            case "String":
            case "Text":
              filterType = "String"
          }
          switch (cf.fieldType) {
            case "Dropdown":
            case "Tags":
            case "Multiselect":
              filterOptions = cf.customOptions.map(c => ({ id: c.id, name: c.name }))
              break
            case "TagsByCategory":
              filterOptions = formatGroupsByCategories(cf)({ db: db })
              break
            case "Broker":
              dbOptions = "activeBrokers"
              break
            case "CountrySelect":
              filterOptions = db.shopData.countries.map(c => ({ id: c.id, name: c.name }))
              break
          }
          return {
            group: g.name,
            value: cf.indexName,
            type: filterType,
            dboptions: dbOptions,
            options: filterOptions,
            label: cf.prettyName,
            isCustomField: true,
          }
        })
      )
      .flat()
  )

  return options
}
