<template>
  <import-csv
    v-bind="$attrs"
    @update:modalVisible="$emit('update:modalVisible', $event)"
    v-slot="{ importer, isVisible }"
    :schema="schema"
    :validate-async="validateAsync"
    :submit-async="submitAsync"
    type="client"
    :store-key="`clients-csv-${$db.broker.id}`"
    example-url="https://pro-propstack-public.s3.eu-west-1.amazonaws.com/helpcenter/Kontakte.csv"
    :default-params="{ overrideSavedQueries: false }"
  >
    <p v-if="isVisible('Suche')" class="m-4">
      <fa-icon name="info-circle" class="text-info mr-1" />
      {{ $t("clients.csv.title") }}
      <nice-radio-group
        v-model="importer.params.overrideSavedQueries"
        size="small"
        class="ml-2"
        :options="[
          { id: false, name: $t('clients.csv.add') },
          { id: true, name: $t('clients.csv.replace') },
        ]"
      />
    </p>
  </import-csv>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import ImportCsv from "@/components/ImportCsv.vue"
import { ALLOWED_HEADERS } from "@/config/client"
import * as _ from "lodash"

const SCHEMA = ALLOWED_HEADERS
const customFieldKey = "customFieldGroupsForClients"

export default defineComponent({
  components: {
    ImportCsv,
  },
  computed: {
    schema(): { id: string; name: string }[] {
      return [
        ...SCHEMA.map(r => ({ id: r, name: this.$t(`clients.csv.modal.columns.client.${r}`) as string })),
        ...this.$db.shopData[customFieldKey]
          .flatMap(g => g.customFields)
          .map(r => ({ id: `CF ${r.name}`, name: r.prettyName })),
      ]
    },
  },
  methods: {
    validateAsync(_1, importer) {
      return {
        jsonImportValidation: {
          unknownHeaders: _.reject(
            Object.values(importer.map),
            (o: any) => ALLOWED_HEADERS.includes(o) || o.match(/CF /)
          ),
        },
      }
    },
    submitAsync(rows, importer) {
      return this.$axios.post("/contacts/import", {
        rows: rows,
        override_saved_queries: importer.params.overrideSavedQueries,
      })
    },
  },
})
</script>
