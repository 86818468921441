import { IIntegrationOrdersService } from "../types/integration-order-service"
import IntegrationOrdersService from "./integration-order-service"
import IntegrationService from "./integration-service"
import Is24CustomerService from "./is24-customer-service"

export class ServiceProvider {
  public readonly integrations: typeof IntegrationService
  public readonly orders: IIntegrationOrdersService
  public readonly is24CustomerService: typeof Is24CustomerService

  constructor() {
    this.integrations = IntegrationService
    this.orders = IntegrationOrdersService
    this.is24CustomerService = Is24CustomerService
  }
}

export default new ServiceProvider()
