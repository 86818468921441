<template>
  <tooltip v-if="fullBrokers && fullBrokers.length" :content="`Nutzer gesamt: ${fullBrokers.length}`">
    <div class="flex ml-2">
      <avatar
        v-for="(broker, idx) in fullBrokers.slice(0, maxBrokers)"
        :key="broker.id"
        :broker="broker"
        :class="{ '-ml-2': idx > 0 }"
        :size="size"
      />
      <avatar v-if="fullBrokers.length > count" :broker="fakeBroker" class="-ml-2" :size="size" />
    </div>
  </tooltip>
  <div v-else class="flex ml-2">
    <avatar
      v-for="(broker, idx) in brokers"
      :key="broker.id"
      :broker="broker"
      :class="{ '-ml-2': idx > 0 }"
      :size="size"
    />
    <avatar v-if="brokerIds.length > maxBrokers" :broker="fakeBroker" class="-ml-2" :size="size" />
  </div>
</template>

<script lang="ts">
import { Broker } from "@/interfaces/db"
import { PropType, defineComponent } from "vue"

export default defineComponent({
  props: {
    brokerIds: {
      type: Array as PropType<number[]>,
    },
    fullBrokers: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 11,
    },
    size: {
      type: String,
      required: false,
    },
  },
  computed: {
    maxBrokers(): number {
      if (this.fullBrokers && this.fullBrokers.length) {
        return this.fullBrokers.length == this.count ? this.count : this.count - 1
      } else {
        return this.brokerIds.length == this.count ? this.count : this.count - 1
      }
    },
    brokers(): Broker[] {
      return this.fullBrokers && this.fullBrokers.length
        ? []
        : this.$db.shopData.brokers.filter(b => this.brokerIds.includes(b.id)).slice(0, this.maxBrokers)
    },
    fakeBroker(): Partial<Broker> {
      if (this.fullBrokers && this.fullBrokers.length) {
        return {
          name: `+ ${this.fullBrokers.length - this.maxBrokers}`,
        }
      }
      return {
        name: `+ ${this.brokerIds.length - this.maxBrokers}`,
      }
    },
  },
})
</script>
