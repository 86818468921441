<template>
  <nice-popover
    ref="popover"
    placement="bottom"
    transition="none"
    :show-arrow="false"
    :width="width"
    :visible="visible"
  >
    <!-- cta button -->
    <template #reference>
      <button
        :disabled="disabled || undefined"
        @click.stop="visible = !visible"
        :class="['tableActions-action', { 'tableActions-action-active': activeFilters }, $attrs.class]"
      >
        <fa-icon name="filter" />
        <span class="ml-2">{{ $t("filterPopover.btn") }}</span>
        <span v-show="activeFilters > 0">({{ activeFilters }})</span>
      </button>
    </template>
    <!-- Modal content -->
    <template #default>
      <div class="el-popover-inner" v-click-outside="close">
        <slot name="default" v-bind="{ close }" />
        <button v-if="resetFilters" class="mt-3 w-full border hover:bg-gray-50 p-2" @click="resetFilters">
          <fa-icon name="trash-alt" />
          <span class="ml-2">{{ $t("filterPopover.reset") }}</span>
        </button>
      </div>
    </template>
  </nice-popover>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 400,
    },
    activeFilters: {
      type: Number,
      default: 0,
    },
    resetFilters: {
      type: Function,
    },
  },
  methods: {
    close(e) {
      this.visible = false
    },
  },
}
</script>
