<template>
  <el-collapse v-model="active" accordion>
    <el-collapse-item :title="getTitle(category)" :name="idx" v-for="(category, idx) in categories" :key="idx">
      <slot :items="category.items"></slot>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import * as _ from "lodash"

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    property: {
      type: String,
      required: true,
    },
    titleFormatter: {
      type: Function,
      default: val => val,
    },
    sortBy: {
      type: Function,
      default: (a, b) => {
        const cp = e => (e.title ? e.title.charCodeAt(0) : -1)
        return cp(a) - cp(b)
      },
    },
    initial: {
      type: Number,
      default: 0,
    },
    nullTitle: {
      type: String,
      default: "settings.categorizeable.uncategorized",
    },
  },
  data() {
    return {
      active: this.initial,
    }
  },
  methods: {
    getTitle(category) {
      return `${category.title || this.$t(this.nullTitle)} (${category.items.length})`
    },
  },
  computed: {
    categories() {
      return Object.entries(_.groupBy(this.list, e => this.titleFormatter(e[this.property]) || ""))
        .map(([title, items]) => ({
          title,
          items,
        }))
        .sort(this.sortBy)
    },
  },
}
</script>
