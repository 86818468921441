<script lang="ts" setup>
import { ShopData } from "@/interfaces/db"
import { computed } from "vue"

type Ta = {
  activityType: string
  payload: {
    noteTypeId: number
    title: string
  }
  prefilledNoteTypeId: number
}

const props = defineProps<{
  shopData?: ShopData
  ta: Ta
}>()

const options = computed(() => props.shopData?.noteCategories || [])
</script>

<template>
  <div>
    <div class="mb-2" v-if="!ta.prefilledNoteTypeId">
      <nice-select
        filterable
        fixed-position
        v-model="ta.payload.noteTypeId"
        placeholder="$t('activities.note.category')"
        :options="options"
      ></nice-select>
    </div>
    <div class="mb-2">
      <input type="text" v-model="ta.payload.title" class="form-control" :placeholder="$t('activities.note.title')" />
    </div>
  </div>
</template>
