<template>
  <el-header class="notification-bar">
    <span class="notification-text">{{ message }}</span>

    <a :href="buttonLink" target="_blank" class="notification-button">
      <nice-button size="small" plain class="button-text">{{ buttonText }}</nice-button>
    </a>
  </el-header>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    buttonLink: {
      type: String,
      default: "",
    },
  },
}
</script>

<style scoped>
.notification-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ps-color-warning-light);
  font-size: 13px;
  height: 40px;
}
.notification-button {
  margin-left: 10px;
}

.button-text {
  font-size: 11px;
  color: var(--ps-color-grey);
}
.button-text:hover {
  border-color: var(--ps-color-grey);
  color: var(--ps-color-grey);
}
</style>
