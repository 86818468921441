<template>
  <nice-dialog
    :model-value="modalVisible"
    @update:model-value="close"
    :title="$t('clients.csv.modal.title')"
    width="800px"
    class="csv-dialog"
  >
    <div class="fluid-item">
      <div class="fluid">
        <div class="align-items-center">
          <progress-steps :items="formSteps" class="w-full mb-24" style="padding: 0 6.5rem" :key="currentStep" />

          <main v-if="currentStep === 0">
            <section class="mt-3 px-12">
              <upload-button
                :title="$t('clients.csv.modal.selectFile')"
                accepted-files=".csv"
                class="mr-1"
                client-only
                :onAdded="processCSV"
                v-slot="{ open, uploading }"
              >
                <button class="upload-btn rounded py-8" @click="open">
                  <div>
                    <span v-if="uploading" class="spinner tableActions-actionIcon"></span>
                    <fa-icon v-else name="file-csv" class="text-gray-400 text-3xl" />
                  </div>
                  <small>{{ $t("clients.csv.modal.csvFile") }}</small>
                  <div class="flex justify-center mt-3">
                    <span class="flex items-center text-white bg-blue-600 w-max py-1 px-3 rounded center">
                      <fa-icon name="cloud-upload" class="mr-2" />
                      {{ $t("clients.csv.modal.selectFile") }}
                    </span>
                  </div>
                </button>
              </upload-button>
            </section>
            <section class="flex justify-center items-center my-4 bg-green-50 py-4 rounded">
              <div class="flex text-sm mr-8">
                <fa-icon name="info-circle" class="text-green-400 text-2xl mr-2" />
                <span>
                  <strong>{{ $t("clients.csv.modal.exampleHeading") }}</strong>
                  <p class="whitespace-pre-wrap">{{ $t("clients.csv.modal.exampleText") }}</p>
                </span>
              </div>
              <div>
                <a :href="exampleUrl" class="flex items-center text-sm hover:underline">
                  <fa-icon name="file-csv" class="mr-2 text-xl" />
                  {{ $t("clients.csv.modal.example") }}
                </a>
              </div>
            </section>
          </main>
          <main v-else-if="currentStep == 1 || currentStep == 2">
            <div class="flex">
              <ps-data-table
                :columns="columns"
                :data="data"
                :per="25"
                v-model:page="currentPage"
                :total="total"
                :loading="loading"
                style="height: auto !important"
              >
                <template v-slot:checked="{ resource }">
                  <fa-icon name="check" v-if="resource.value" class="text-green-600" />
                </template>
                <template v-slot:csvColumn="{ resource }">
                  <p style="font-weight: 500">{{ resource.title }}</p>
                </template>
                <template v-slot:to="{ resource }">
                  <p v-if="resource">&rarr;</p>
                </template>
                <template v-slot:psField="{ resource }">
                  <nice-select
                    v-model="importer.map[resource.title]"
                    :options="schema"
                    searchable
                    :item-disabled-fn="item => reverseMap[item.id] && reverseMap[item.id] !== resource.title"
                  />
                </template>
                <template v-slot:preview="{ resource }">
                  <tooltip
                    :content="
                      importer.preview
                        .filter(p => p[resource.value])
                        .map(p => p[resource.value])
                        .join('<br />')
                    "
                    v-if="importer.preview"
                  >
                    <p class="truncate">
                      {{
                        importer.preview
                          .filter(p => p[resource.value])
                          .map(p => p[resource.value])
                          .join(", ")
                      }}
                    </p>
                  </tooltip>
                </template>
              </ps-data-table>
            </div>
            <slot :importer="importer" :isVisible="isVisible" />
            <div class="m-t-20 flex justify-end">
              <nice-button
                type="primary"
                @click="validateBeforeSubmit"
                :disabled="importer.rows.length <= 0 && isMapped"
                :loading="uploading"
                class="-mb-3"
              >
                {{ $t("clients.csv.modal.next") }}
              </nice-button>
            </div>
          </main>
          <main v-else>
            <p v-if="importer.errors.length > 0" class="mb-2">
              <fa-icon name="times" class="text-danger mr-1" />
              {{ $t("clients.csv.modal.logError") }}
              <strong>{{ importer.errors.join(", ") }}</strong>
            </p>
            <div v-if="importer.errors.length <= 0">
              <p class="mb-2">
                <fa-icon name="check" class="text-success mr-1" />
                {{ $t("clients.csv.modal.logChecked") }}
                <strong>{{ importer.activeFile }}</strong>
              </p>
              <p class="mb-2">
                <i v-if="importer.rows.length > 0" class="fal fa-check text-success mr-1"></i>
                <i v-if="importer.rows.length <= 0" class="fal fa-times text danger mr-1"></i>
                {{ $t("clients.csv.modal.logData") }}
                <strong>{{ importer.rows.length }}</strong>
              </p>
              <p v-if="importer.unknownHeaders.length <= 0" class="mb-2">
                <fa-icon name="check" class="text-success mr-1" />
                {{ $t("clients.csv.modal.logChecked2") }}
              </p>
              <p v-if="importer.unknownHeaders.length > 0" class="mb-2">
                <fa-icon name="times" class="text-warning mr-2" />
                {{ $t("clients.csv.modal.logUnknown") }}
                <strong>{{ importer.unknownHeaders.join(", ") }}</strong>
              </p>
              <div v-if="importer.unknownValues.length > 0" class="mb-2">
                <p>
                  <fa-icon name="times" class="text-warning mr-1" />
                  {{ $t("clients.csv.modal.logUnknown2") }}
                </p>
                <p v-for="value in importer.unknownValues" :key="value.jsonHeader">
                  <strong>{{ value.jsonHeader }}</strong>
                  {{ value.invalidValues.join(", ") }}
                </p>
              </div>
            </div>

            <div class="m-t-20 flex justify-end">
              <nice-button type="primary" @click="() => close(false)" class="mb-3">
                {{ $t("clients.csv.modal.reset") }}
              </nice-button>
            </div>
          </main>
        </div>
      </div>
    </div>
  </nice-dialog>
</template>

<script lang="ts">
import { parseCSV } from "@/config/csv-parser"
import { defineComponent } from "vue"
import ProgressSteps from "@/components/ProgressSteps.vue"
import NiceSelect from "@/components/NiceSelect.vue"
import UploadButton from "@/components/media/UploadButton.vue"

const TABLE_COLUMNS = [
  {
    key: "checked",
    main: true,
    width: 20,
  },
  {
    key: "csvColumn",
    title: "csvColumn",
    main: true,
    width: 155,
  },
  {
    key: "to",
    main: true,
    width: 20,
  },
  {
    key: "psField",
    title: "psField",
    main: true,
    width: 270,
  },
  {
    key: "preview",
    title: "preview",
    main: true,
    width: 150,
  },
]

const STEPS = [
  {
    label: "stepOne",
  },
  {
    label: "stepTwo",
  },
  {
    label: "stepThree",
  },
]

export default defineComponent({
  components: { ProgressSteps, NiceSelect, UploadButton },
  props: {
    modalVisible: Boolean,
    type: {
      type: String,
      required: true,
    },
    schema: {
      type: Array,
      required: true,
    },
    validateAsync: {
      type: Function,
      required: false,
    },
    submitAsync: {
      type: Function,
      required: true,
    },
    storeKey: {
      type: String,
      required: false,
    },
    exampleUrl: {
      type: String,
    },
    defaultParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      uploading: false,
      submitted: undefined as any,
      loading: false,
      importer: {
        activeFile: null as any,
        fields: [] as string[],
        errors: [] as any[],
        rows: [] as any[],
        unknownHeaders: [] as any[],
        unknownValues: [] as any[],
        params: this.defaultParams as Record<string, any>,
        map: {} as Record<string, string>,
        // requiresMapping: false,
        preview: null as any,
      },
      currentPage: 1,
      total: 0,
      currentStep: 0,
    }
  },
  computed: {
    totalNumberOfKeys(): number {
      return this.importer.fields.length
    },
    numberOfKeysMapped(): number {
      return Object.keys(this.importer.map).length
    },
    isFullyMapped(): boolean {
      return this.numberOfKeysMapped === this.totalNumberOfKeys
    },
    isMapped(): boolean {
      return this.numberOfKeysMapped > 0
    },
    reverseMap(): Record<string, string> {
      return _.invert(this.importer.map)
    },
    schemaFields(): any[] {
      return (this.schema as any[]).map(({ id }) => id)
    },
    columns(): any[] {
      return TABLE_COLUMNS.map(c => ({ ...c, title: c.title ? this.$t(`clients.csv.modal.${c.title}`) : "" }))
    },
    data(): any {
      return this.importer.fields.map((v, idx) => ({ title: v, value: this.importer.map[v] }))
    },
    formSteps(): any[] {
      return STEPS.map((s, i) => ({ label: this.$t(`clients.csv.modal.${s.label}`), active: i < this.currentStep }))
    },
  },
  watch: {
    "importer.fields": {
      handler(newVal) {
        if (newVal.length === 0) return
        this.evaluateHeaders(newVal)
      },
      deep: true,
    },
    "importer.map": {
      deep: true,
      handler(newVal) {
        this.importer.preview = Object.values(newVal).filter(Boolean).length
          ? this.createRows(this.importer.fields, this.importer.rows.slice(0, 5), newVal)
          : null
      },
    },
  },
  methods: {
    // editMapping() {
    //   this.importer.requiresMapping = true
    // },
    // proceedNextStep() {
    //   if (this.currentStep >= this.currentStep.length) return
    //   // this.formSteps[this.stepCount].active = true

    // },
    close($event) {
      this.$emit("update:modalVisible", $event)
      this.reset()
    },
    isVisible(fieldName) {
      return Object.values(this.importer.map).find(o => o.includes(fieldName))
    },
    evaluateHeaders(headers) {
      const normalizedSchema = this.schemaFields.map(key => this.normalizeEntry(key))
      const indices = [] as number[]
      this.importer.map = headers.reduce((agg, header) => {
        const idx = normalizedSchema.indexOf(this.normalizeEntry(header))
        if (idx > -1 && indices.indexOf(idx) === -1 && !(header in agg)) {
          agg[header] = this.schemaFields[idx]
          indices.push(idx)
        }
        return agg
      }, {})
      this.loadStateIfNecessary(this.schemaFields, headers)
    },
    normalizeEntry(value) {
      value = value.toLowerCase()
      value = value.replace(/ä/g, "ae")
      value = value.replace(/ö/g, "oe")
      value = value.replace(/ü/g, "ue")
      value = value.replace(/ß/g, "ss")
      value = value.replace(/[^a-z0-9]/g, "")
      return value
    },
    createRows(headers, data, map) {
      return data.map(row =>
        row.reduce((agg, cur, idx) => {
          const field = map[headers[idx]]
          if (field) agg[field] = cur
          return agg
        }, {})
      )
    },
    validateBeforeSubmit() {
      this.currentStep++

      const rows = this.createRows(this.importer.fields, this.importer.rows, this.importer.map)
      // this.importer.requiresMapping = false
      this.loading = true

      this.uploading = true
      let promise = Promise.resolve(this.validateAsync?.(rows, this.importer))
      promise
        .then(data => {
          if (data) Object.assign(this.importer, _.omit(data.jsonImportValidation, "rows"))
        })
        .finally(() => {
          this.loading = false
          this.uploadCSV()
        })
    },
    saveStateIfNecessary(map) {
      if (!this.storeKey) return
      localStorage.setItem(this.storeKey, JSON.stringify(_.pickBy(map, value => !!value)))
    },
    loadStateIfNecessary(schema, fields) {
      if (!this.storeKey) return
      const fromStore = localStorage.getItem(this.storeKey)
      if (!fromStore) return
      Object.assign(
        this.importer.map,
        _.pickBy(JSON.parse(fromStore), (value, key) => fields.includes(key) && schema.includes(value))
      )
    },
    processCSV(file) {
      this.reset()
      this.loading = true
      this.importer.activeFile = file.name
      this.currentStep++
      const self = this
      parseCSV(file).then(results => {
        if (self.$refs.unitsFileField) {
          ;(self.$refs.unitsFileField as any).value = null
        }
        this.loading = false

        if (results.errors.length > 0) {
          self.importer.errors = results.errors.map(o => o.message).slice(0, 3)
        }

        if (self.importer.errors.length > 0) {
          return
        }

        const counts = {}
        self.importer.fields = results.data[0].reduce((agg, cur) => {
          let current = counts[cur]
          counts[cur] = current = (isNaN(current) ? -1 : current) + 1
          agg.push(!current ? cur : `${cur} (${current})`)
          return agg
        }, [])
        self.importer.rows = results.data.slice(1)
      })
    },

    reset() {
      this.importer = {
        activeFile: null,
        errors: [],
        rows: [],
        fields: [],
        unknownHeaders: [],
        unknownValues: [],
        params: {},
        map: {},
        // requiresMapping: false,
        preview: null,
      }
      this.uploading = false
      this.loading = false
      this.submitted = undefined
      this.currentStep = 0
      // this.formSteps.map(s => (s.active = false))
    },

    uploadCSV() {
      this.saveStateIfNecessary(this.importer.map)
      const rows = this.createRows(this.importer.fields, this.importer.rows, this.importer.map)
      this.submitAsync(rows, this.importer).then(() => {
        this.submitted = this.importer
        this.currentStep++
        // this.$emit("update:modalVisible", false)
        App.flashy(this.$t("clients.csv.modal.upload", { type: this.type }))
      })
    },
  },
})
</script>
<style>
.code-block {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 20px;
  display: block;
  white-space: pre-wrap;
}

.csv-dialog > .el-dialog > .el-dialog__header {
  background-color: white;
  border-radius: 5px;
}

.step-bubble {
  width: 20px;
  height: 20px;
  padding: 0px !important;
  text-align: center;
  font-size: 12px;
}

.upload-btn:hover {
  border-color: #0f55eb;
}
.upload-btn {
  border: 1px dashed #d9d9d9;
  width: 100%;
}
</style>
