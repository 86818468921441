export const getActivityColumns = (db, t, tm) => {
  const options: any[] = []

  options.push(
    ...[
      {
        group: "general",
        value: "broker_id",
        type: "Array",
        dboptions: "activeBrokers",
      },
      {
        group: "general",
        value: "creator_id",
        type: "Array",
        dboptions: "activeBrokers",
      },
      {
        group: "general",
        value: "client_broker_ids",
        type: "Array",
        dboptions: "activeBrokers",
      },
      {
        group: "general",
        value: "client_ids",
        type: "Array",
        remote: "client",
      },
      {
        group: "general",
        value: "property_ids",
        type: "Array",
        remote: "property",
      },
      {
        group: "general",
        value: "project_ids",
        type: "Array",
        dboptions: "projects",
      },
      {
        group: "general",
        value: "created_at",
        type: "Date",
      },
      {
        group: "general",
        value: "original_created_at",
        type: "Date",
      },
      {
        group: "general",
        value: "title",
        type: "String",
      },
      {
        group: "general",
        value: "group_ids",
        type: "Array",
        dboptions: "formattedActivityGroups",
      },
      {
        group: "general",
        value: "source_id",
        type: "Array",
        dboptions: "clientSources",
      },
      {
        group: "general",
        value: "conversation_type",
        type: "Array",
        specific: true,
        options: Object.entries(tm(`activities.itemTypes`)).map(([key, value]) => ({
          id: key,
          name: value,
        })),
        render: value => {
          if (value === "inquiries") {
            return [
              {
                key: "must",
                value: { exists: { field: "source_id" } },
              },
            ]
          }
          return [
            {
              key: "must",
              value: { term: { conversation_type: value } },
            },
            {
              key: "must_not",
              value: { exists: { field: "source_id" } },
            },
          ]
        },
      },
      {
        group: "general",
        value: "reason_id",
        type: "Array",
        dependsOn: values => values.conversation_type === "cancellation",
        dboptions: "cancelationReasons",
      },
      {
        group: "general",
        value: "category_id",
        type: "Array",
        dependsOn: values => values.conversation_type === "message",
        dboptions: "messageCategories",
      },
      {
        group: "general",
        value: "category_id",
        type: "Array",
        dependsOn: values => values.conversation_type === "note",
        dboptions: "noteCategories",
      },
      {
        group: "general",
        value: "category_id",
        type: "Array",
        dependsOn: values => values.conversation_type === "letter",
        dboptions: "letterCategories",
      },
      {
        group: "general",
        value: "category_id",
        type: "Array",
        dependsOn: values => values.conversation_type === "reminder",
        dboptions: "todoCategories",
      },
      {
        group: "general",
        value: "category_id",
        type: "Array",
        dependsOn: values => values.conversation_type === "event",
        dboptions: "eventCategories",
      },
      {
        group: "general",
        value: "category_id",
        type: "Array",
        dependsOn: values => values.conversation_type === "letter",
        dboptions: "letterCategories",
      },
      {
        group: "general",
        value: "starts_at",
        type: "Date",
        dependsOn: values => values.conversation_type === "event",
      },
      {
        group: "general",
        value: "done",
        type: "Boolean",
        dependsOn: values => values.conversation_type === "reminder",
      },
      {
        group: "general",
        value: "sender_type",
        type: "Array",
        dependsOn: values => values.conversation_type === "message",
        options: [
          { id: "1", name: t("activities.senderTypeOptions.incoming") },
          { id: "2", name: t("activities.senderTypeOptions.outgoing") },
        ],
      },
      {
        group: "general",
        value: "property_status_ids",
        type: "Array",
        dboptions: "propertyStatuses",
      },
      {
        group: "general",
        value: "property_marketing_types",
        type: "Array",
        options: [
          { id: "buy", name: t("activities.propertyMarketingTypes.buy") },
          { id: "rent", name: t("activities.propertyMarketingTypes.rent") },
        ],
      },
      {
        group: "general",
        value: "property_broker_ids",
        type: "Array",
        dboptions: "activeBrokers",
      },
    ]
  )

  return options
}
