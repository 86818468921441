<template>
  <spinner v-if="loading" centered />
  <div v-else-if="resource" class="app-panel">
    <div id="task-detail-container" class="app-panel-body scrollable">
      <nav class="py-3 px-4 mb-2 d-flex align-items-center justify-content-between">
        <div>
          <a href="#" @click.prevent="close" class="fs-18 mr-3 ml-1" style="position: relative; top: 2px">
            <fa-icon name="times" />
          </a>
        </div>
        <div>
          <dropdown>
            <dropdown-section>
              <dropdown-item @click="deleteTask" title="Löschen" class="text-red-600" />
            </dropdown-section>
            <dropdown-section>
              <p @click.stop class="text-gray-600 text-xs px-3 py-2">Kennung: {{ externalId }}</p>
            </dropdown-section>
          </dropdown>
        </div>
      </nav>
      <main class="taskDetails-wrap">
        <header class="form-group">
          <h3 class="notes-title">Nachricht an {{ resource.recipient.name }}</h3>
        </header>
        <div class="mb-1" v-if="statusLabelMapping[resource.messagebirdStatus]">
          <label>Status</label>
          <nice-label
            :label="statusLabelMapping[resource.messagebirdStatus]"
            :color="statusColorMapping[resource.messagebirdStatus]"
          />
        </div>
        <div class="mb-1" v-if="resource.messagebirdErrorMessage">
          <label>Fehlermeldung</label>
          <strong class="text-red-600">{{ resource.messagebirdErrorMessage }}</strong>
        </div>

        <div class="mb-1" v-if="resource.smsEvent">
          <label>Termin:</label>
          <strong>
            <a href="#" @click.prevent="openEvent">{{ resource.smsEvent.title }}</a>
          </strong>
        </div>

        <div class="form-group mt-3">
          <div class="msg-body" v-html="resource.body.replace(/\n/g, '<br>')"></div>
        </div>

        <div class="mb-3" v-if="resource.projects.length > 0">
          <h4 class="text-base">Projekte</h4>
          <nice-bar v-for="item in resource.projects" :key="item.id" type="light" class="mx-0">
            <router-link :to="item.path">{{ item.name }}</router-link>
          </nice-bar>
        </div>
        <div class="mb-3" v-if="resource.properties.length > 0">
          <h4 class="text-base">Objekte</h4>
          <nice-bar v-for="item in resource.properties" :key="item.id" type="light" class="mx-0">
            <router-link :to="item.path">{{ item.name }}</router-link>
          </nice-bar>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import eventBus from "@/config/event-bus"
export default {
  props: {
    id: { type: Number },
  },
  data() {
    return {
      loading: true,
      resource: null,
    }
  },
  methods: {
    async fetchData() {
      const { textMessage } = await this.$graphql(`query textMessage {
        textMessage(id: ${this.id}) {
          id createdAt body brokerId creatorId
          recipient { id name }
          properties { id name path }
          projects { id name path }
          messagebirdStatus messagebirdErrorMessage
          smsEvent { id title }
        }
      }`)

      this.resource = textMessage
      this.loading = false
    },
    deleteTask() {
      this.$confirm("Soll die Aktivität wirklich gelöscht werden?", "Aktivität löschen")
        .then(_ => {
          this.$axios.delete(`/services/text_messages/${this.id}`)
          this.$emit("close")
          App.flashy("Aktivität wurde erfolgreich gelöscht")
        })
        .catch(_ => {})
    },
    close() {
      this.$emit("close")
    },
    openEvent() {
      eventBus.$emit("quick-view", { type: "event", mode: "view", id: this.resource.smsEvent.id })
    },
  },
  computed: {
    taskCreator() {
      return this.$db.shopData.brokers.find(o => o.id == this.resource.creatorId)
    },
    externalId() {
      return this.resource.oldCrmExternalId || `T${this.id}`
    },
    statusLabelMapping() {
      return {
        scheduled: "Geplant",
        sent: "Zugesandt",
        buffered: "gepuffert",
        delivered: "Zugestellt",
        expired: "Verfallen",
        delivery_failed: "Fehlgeschlagen",
      }
    },
    statusColorMapping() {
      return {
        scheduled: "#cc7700",
        sent: "#ffbb00",
        buffered: "#ffbb00",
        delivered: "#22dd33",
        expired: "#ff0000",
        delivery_failed: "#ff0000",
      }
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style scoped>
.taskDetails-wrap {
  padding: 20px 20px 0 25px;
}
</style>
