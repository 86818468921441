<template>
  <div>
    <div v-if="$db.shopData.logoUrl" class="logo-wrap">
      <img :src="$db.shopData.logoUrl" height="60" />
    </div>
    <h1 class="headline">
      {{ project.name }}
      <p>Stand: {{ todaysDate }}</p>
    </h1>
    <table class="table">
      <thead>
        <tr>
          <th v-for="col in visibleColumns" :key="col.name">
            {{ col.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) in rows" :key="i">
          <td v-for="(col, i) in row" :key="i">
            <slot :name="col.name" :value="col.value" :resource="col.resource">
              <p style="white-space: nowrap">{{ col.value }}</p>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatRow, formatOptions } from "../config/property/index"
import { format as formatField } from "../config/property/field-config"

export default {
  props: {
    project: {
      type: Object,
    },
    properties: {
      type: Array,
    },
    columnConfiguration: {
      type: Array,
    },
  },
  methods: {
    customFormatter(col, row) {
      if (col.name === "highest_deal.deal_stage_id") {
        if (!row.highest_deal_stage) return
        return row.highest_deal_stage.name
      }
    },
  },
  computed: {
    visibleColumns() {
      return this.columnConfiguration.filter(col => col.visible)
    },
    rows() {
      return this.properties.map(prop => {
        const fullRow = formatRow(prop, this.rowOptions)
        const row = []
        this.visibleColumns.forEach(col => {
          const rawValue = this.customFormatter(col, fullRow) || fullRow[col.name]
          const value = this.$slots[col.name] ? rawValue : formatField(col, rawValue, this.$db)
          row.push({ value, name: col.name, resource: prop })
        })
        return row
      })
    },
    rowOptions() {
      return formatOptions({
        brokers: this.$db.get("brokers"),
        propertyStatuses: this.$db.get("propertyStatuses"),
        dealStages: this.$db.get("dealStages"),
        propertyGroups: this.$db.get("propertyGroups"),
        connections: this.$db.get("connections"),
        projects: this.$db.get("projects"),
        teams: this.$db.get("teams"),
      })
    },
    todaysDate() {
      return new Date().toLocaleString("de-DE", { day: "numeric", month: "long", year: "numeric" })
    },
  },
}

export const css = `

    body  {
        color: #272828;
        font-size: 12px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
    }

    .table {
        width:100%;
        text-align:center;
        border-spacing: 0px;
        border: 1px solid #e9e9e9;
    }

    th {
        font-weight:bold;
        font-size:0.8rem;
        text-transform:uppercase;
        padding:12px 0;
    }

    td {
        padding: 8px 6px;
    }

    tbody td {
        border-top:1px solid #ddd;
    }

    tbody tr:nth-child(odd) td {
        background-color:#f3f3f3; 
    }

    .headline {
        width: 100%;
        padding-bottom: 10px;
        font-size: 28px;
        margin-bottom: 1rem;
        border-bottom: 1px solid #e9e9e9;
        color: #555;
        text-align: center;
        font-weight:300;
    }

    .headline > p {
        font-size: 11px;
    }

    .logo-wrap {
        text-align: center;
        margin-bottom: 3rem;
    }

`
</script>
