<template>
  <div class="fake-select">
    <select @change="changed" class="form-control">
      <option value="">{{ $t("variableSelect.btn") }}</option>
      <optgroup v-for="group in options" :key="group[0]" :label="group[0]">
        <option v-for="item in group[1]" :key="item[1]" :value="item[1]">
          {{ item[0] }}
        </option>
      </optgroup>
    </select>
  </div>
</template>

<script>
import { getVariables } from "@/utils/get-variables"

let cache

export default {
  emits: ["selected"],
  data() {
    return {
      options: [],
    }
  },
  methods: {
    async fetchData() {
      const { shop } = await this.$graphql(`query variables {
        shop {
          sendableFolders: folders(sendable: true) { id name }
          uploadableFolders: folders(forUpload: true, excludePlaceholder: true) { id name }
          bookingCalendars { id name }
        }
      }`)

      this.options = getVariables(this.$db.shopData, {
        folders: shop.sendableFolders,
        uploadableFolders: shop.uploadableFolders,
        bookingCalendars: shop.bookingCalendars,
      }).map(o => [
        this.$t(`variableSelect.titles.${o[0]}`),
        o[1].map(a => [
          a[2]
            ? a[2] == "custom"
              ? a[0]
              : this.$t(`variableSelect.names.${a[0]}`, { folder: a[2] })
            : this.$t(`variableSelect.names.${a[0]}`),
          a[1],
        ]),
      ])
      cache = this.options
    },
    changed($event) {
      const val = $($event.target).val()
      if (!val) return

      this.$emit("selected", val)

      $($event.target).val("")
    },
  },
  mounted() {
    if (cache) {
      this.options = cache
    } else {
      this.fetchData()
    }
  },
}
</script>
