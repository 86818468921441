<template>
  <dropdown>
    <template #reference>
      <button @click.prevent class="details-action-link">
        <tooltip :content="$t('followupsDropdown.tooltip')">
          <fa-icon name="bell" />
        </tooltip>
      </button>
    </template>
    <dropdown-section>
      <dropdown-item
        v-for="date in translatedDates"
        :key="date.value"
        :title="date.label"
        @click="setFollowupDate(date)"
      />
    </dropdown-section>
    <dropdown-section v-if="followupDate">
      <dropdown-item :title="$t('followupsDropdown.reset')" @click="reset" />
    </dropdown-section>
  </dropdown>
</template>

<script>
import { FOLLOWUP_DATES } from "../config/client"

export default {
  props: ["clientId", "followupDate"],
  computed: {
    translatedDates() {
      return FOLLOWUP_DATES.map(d => ({ ...d, label: this.$t(`followupsDropdown.dates.${d.label}`) }))
    },
  },
  methods: {
    setFollowupDate(date) {
      this.$axios.post(`/services/contacts/${this.clientId}/set_followup_date`, {
        days: date.value,
      })

      if (date.value > 0) {
        App.flashy(this.$t("followupsDropdown.successDate", { date: date.label }))
      } else {
        App.flashy(this.$t("followupsDropdown.success"))
      }

      this.$emit("followup", this.clientId)
    },
    reset() {
      this.$axios.post(`/services/contacts/${this.clientId}/set_followup_date`, { reset: true })
      App.flashy(this.$t("followupsDropdown.successReset"))
    },
  },
}
</script>
