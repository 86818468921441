<script lang="ts" setup>
import { ref, toRaw } from "vue"
import ColumnsList from "./columns/List.vue"
import { computed } from "vue"
import { useI18n } from "vue-i18n"

type Props = {
  title?: string
  translateFor?: string
  canReset?: boolean
}

type ColumnDef = {
  visible: boolean
  name: string
  width: number
  title: string
  key?: string
  description?: string
}

const visible = defineModel<boolean>("visible", {
  required: true,
  default: false,
})

const columns = defineModel<Array<ColumnDef>>("columns")

const props = withDefaults(defineProps<Props>(), {
  title: "",
  translateFor: "",
  canReset: false,
})

const newColumn = ref("")
const { t } = useI18n()

const emit = defineEmits(["update:visible", "update:columns", "change", "reset"])

const addColumn = (value: string) => {
  const updatedColumns = structuredClone(toRaw(columns.value)) || []
  const current = updatedColumns.find(c => c.name === value)
  if (current) current.visible = true

  emit("update:columns", updatedColumns)
  newColumn.value = ""
}

const translatedColumns = computed(() => {
  const columnsCopy = toRaw(columns.value)?.filter(c => !c.visible) || []

  return columnsCopy.map(c => ({
    name: c.description || c.name || c.key,
    title: props.translateFor
      ? t(`${props.translateFor}.columns.${c.description || c.name || c.key}`)
      : c.description || c.title,
  }))
})

const closeCallback = () => emit("update:visible", false)
</script>

<template>
  <nice-dialog
    width="450px"
    :model-value="visible"
    @close="closeCallback"
    :title="props.title || $t('columnsModal.title')"
  >
    <button class="light m-l-15 pull-right -mt-7" @click.prevent="$emit('reset')" v-if="canReset">
      {{ $t("followupsDropdown.reset") }}
    </button>
    <columns-list
      :columns="columns"
      @update:columns="$emit('update:columns', $event)"
      :translate-for="translateFor"
      @change="$emit('change', $event)"
    />
    <nice-select
      :fixed-position="true"
      class="mt-2"
      id-key="name"
      label-key="title"
      v-model="newColumn"
      @change="addColumn"
      :placeholder="$t('columnsModal.addColumn')"
      :label-fn="item => (props.translateFor ? $t(`${props.translateFor}.columns.${item.name}`) : item.title)"
      :options="translatedColumns"
    ></nice-select>
  </nice-dialog>
</template>
