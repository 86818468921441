<template>
  <component
    :is="props.button ? 'el-radio-button' : 'el-radio'"
    :value="props.value"
    @change="handleChange"
    :label="props.label"
    :disabled="props.disabled"
    v-bind="$attrs"
  >
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    value?: string | number | boolean
    label?: string | number
    disabled?: boolean
    button?: boolean
  }>(),
  {
    disabled: false,
    button: true,
  }
)

const emit = defineEmits(["change"])

const handleChange = value => {
  emit("change", value)
}
</script>

<style>
.el-radio-button {
  --el-radio-button-checked-bg-color: var(--ps-color-transparent-dark);
  --el-radio-button-checked-text-color: var(--ps-color-neutral-default);
  --el-radio-button-checked-border-color: var(--ps-color-transparent-dark);
}
/* Bugfix: global label style has margin-bottom */
.el-radio-button {
  margin: 0;
}

/* Make Radios narrower */
.el-radio-button--large .el-radio-button__inner {
  padding: 10px;
}

.el-radio-button--default .el-radio-button__inner {
  padding: 7px 10px;
  font-size: 12px;
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: black;
  box-shadow: none;
}

/* fix: remove ugly outline on safari when radio is clicked */
.el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
  box-shadow: none;
}

.el-radio.is-bordered {
  margin-right: 8px;
}
</style>
