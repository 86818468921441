<template>
  <form-dialog
    v-bind="$attrs"
    append-to-body
    :name="label"
    :id="editing && editing.id"
    :visible="!!editing"
    :submit-func="save"
    @close="$emit('update:editing', null)"
    @complete="handleSave"
    :saving="saving"
  >
    <slot v-if="editing" name="form" v-bind="{ item: editing, saving }"></slot>
  </form-dialog>
</template>

<script>
export default {
  emits: ["update:editing", "complete"],
  props: {
    editing: {},
    label: {
      type: String,
      required: true,
    },
    submitFunc: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
    }
  },
  methods: {
    async save() {
      this.saving = true
      let res

      try {
        res = await this.submitFunc(this.editing)
      } catch (err) {
        throw err
      } finally {
        this.saving = false
      }
      return res
    },

    handleSave(data) {
      if (this.editing.id) {
        App.flashy(this.label + this.$t("resourceDialog.successfulEdit"))
      } else {
        App.flashy(this.label + this.$t("resourceDialog.successfulCreate"))
      }
      this.$emit("complete", data)
      this.$emit("update:editing", null)
    },
  },
}
</script>
