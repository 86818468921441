<template>
  <bubble
    v-if="gdprStatus > -1"
    :tooltip="$t('client.gdpr.tooltip', { status: getGdrpStatusMessage })"
    :type="color"
    class="relative"
  >
    <fa-icon name="save" />
    <fa-icon v-if="gdprStatus !== 2" name="slash" class="absolute" />
  </bubble>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import { GdprStatus } from "@/interfaces"

export default defineComponent({
  props: {
    gdprStatus: {
      type: Number as PropType<GdprStatus>,
    },
  },
  computed: {
    color(): string {
      if (this.gdprStatus === 2) return "success"
      if (this.gdprStatus === 3) return "danger"
      return "light"
    },
    getGdrpStatusMessage(): any {
      if (this.gdprStatus === 0) return this.$t("client.gdpr.messages.unknown")
      if (this.gdprStatus === 1) return this.$t("client.gdpr.messages.ignore")
      if (this.gdprStatus === 2) return this.$t("client.gdpr.messages.y")
      if (this.gdprStatus === 3) return this.$t("client.gdpr.messages.n")
      return ""
    },
  },
})
</script>