import { BrokerDetail, Plan, ShopData } from "@/interfaces"
import {
  getSavedStateFromIndexedDb,
  removeState,
  removeStateFromIndexedDb,
  saveStateToIndexedDb,
} from "@/mixins/save-state-mixin"
import api from "@/plugins/api"
import { calculateInboxMenuItems } from "@/utils/get-inboxes"
import { getLocationGroupsFlat } from "@/utils/get-locations"
import * as _ from "lodash"
import axios from "./axios"
import { formatPayloadToElastic } from "./todo/elastic-payload"

/*
 * Pseudo Databank, welche simple Daten alle auf ein mal zieht,
 * und dann cached, um sie nicht jedes Mal erneut zu ziehen, oder einzeln zu ziehen.
 */

const detailViewFieldQuery = `
  name slot splitIntoTwoColumns
  readBrokerIds writeBrokerIds readDepartmentIds writeDepartmentIds
  allowedMarketingType allowedRsTypes propertyStatusIds taskCategoryIds taskTypes
  dealPipelineIds dealStageIds countries clientGroupIds
  detailViewFields {
    id fieldName title hint
    readBrokerIds writeBrokerIds readDepartmentIds writeDepartmentIds
    allowedMarketingType allowedRsTypes containerFieldNames propertyStatusIds
    taskCategoryIds taskTypes color mandatory
    dealPipelineIds dealStageIds countries clientGroupIds
  }
`
const VERSION_QUERY = `query Version {
  appVersion
  announcement
  shop {
    updatedAt
  }
}`

const STATS_QUERY = `query Stats($filterSet: JSON!) {
  broker {
    uncompletedTodos(filterSet: $filterSet)
    unreadMessagesCount
    unreadTrackingsCount
    unreadNotificationsCount
    lastVisited
    availability
  }
}`

const BROKER_QUERY = `broker {
  webcallUrl hideIntercom firstName
  id name avatarUrl(size: "small_thumb") color headerColor initials
  createdAt
  sharedInboxBrokerIds
  email position phone cell
  dob teamId admin locale
  hideInDropdowns archived blocked
  connected
  senderEmail primarySenderBrokerId
  departmentIds
  availableInboxes {
    id name internalName email signature
    smartFolders { id name color }
    mailboxFolders { id parentId label path }
  }
  allAvailableMailboxes {
    id name internalName email signature
  }
  canSendEmails
  canEditUnits
  canEditSharedUnits
  cannotEditOneself
  canDeleteProperties
  canExportToPortals
  canExport
  canExportClients
  canSeeAllClients
  canSeeAllProperties
  canSeeAllDealPipelines
  canEditUserStatus
  canSyncContacts
  canDeleteClients
  canEditClients
  canEditSharedClients
  canChangeClientStatus
  canChangeBroker
  canSeeTodos
  canSeeMarketing
  canMassMail
  canDeleteLinkedMessages
  canDeleteDeals
  canEditSnippets
  canEditGroups
  canEditPipelines
  canChangeTasks
  canEditLetterTemplates
  canSeeActivityOverview
  canImport
  cannotSeeAllDeals
  canBulkEdit
  canEditPortals
  canEditExtensions
  canEditShopData
  canEditGdprData
  canEditLandingpages
  canEditSavedQuerySettings
  canEditLeadScoreSettings
  canEditEventSettings
  canEditDepartments
  canEditBrokers
  clientsOpenInDetails accessTeamIds
  propertyDetailViewGroups: detailViewGroups(entity: for_properties) { ${detailViewFieldQuery} }
  searchFilters { id name forEntity workList esFilters params mcLastExportedAt mcExportable storageType brokerIds departmentIds isPublic brokerId widgetActive widgetUrl }
  propertySearchSortBy
  propertySearchOrder
  propertySearchPer
  defaultClientsFilter
  defaultPropertiesFilter
  defaultActivitiesFilter
  defaultTodosFilter
  defaultDealsFilter
  clientSearchSortBy
  clientSearchOrder
  clientSearchPer
  customColumnsForProperties
  customColumnsForClients
  customColumnsForTodos
  customColumnsForActivities
  customColumnsForProjects
  intercomId
  intercomHash
  apolloUrl
  hideActivityLogs
  commentsOrder
  calendarVisibility
  sendingBrokerIds
  commissionBeneficiary
  sidebarPins { title modelId modelType }
  showInboxMultiselect
  followupActivityAssignee
  localHeroAutoPublish
  glid
  role
  notificationEnabled
  paperlessAuthenticated
}`

const SHOP_QUERY = `query InitialData {
  appVersion
  announcement
  shop {
    id updatedAt realtimeSubscriptionNamespace availableLicenses
    brokers {
      id name email phone internalName active avatarUrl(size: "small_thumb") initials color admin archived dob senderEmail
      availability role
    }
    departments { id name brokerIds useInInquiryDistribution teamId }
    disableCustomizeableEmailSignature
    messageTemplates { id name body }
    messageCategories { id name displayName color hideInReports }
    eventCategories { id name displayName color hideInReports internal titleFormula roomResourceEmails body }
    todoCategories { id name displayName color hideInReports internal titleFormula body taskPipelineId reviewerIds brokerId departmentId }
    noteCategories { id name displayName color hideInReports internal titleFormula body locked }
    letterCategories { id name displayName color hideInReports internal titleFormula }
    decisionCategories { id name displayName color hideInReports internal titleFormula body }
    activityGroups { id name superGroupId }
    projects { id name address }
    projectStatuses { id name }
    propertyStatuses { id name color writeBrokerIds writeDepartmentIds }
    propertyGroups { id name superGroupId }
    clientSources { id name inquiryDepartmentId inquiryDepartmentRules { rule inquiryDepartmentId } }
    clientStatuses { id name color }
    superGroups { id name entity }
    clientGroups { id name superGroupId }
    clientReasons { id name }
    dealPipelines { id name brokerIds departmentIds }
    dealStages { id name color chance excludedBrokerIds dealPipelineId }
    taskPipelines { id name }
    taskStages { id name color dealPipelineId }
    cancelationReasons { id name }
    connections: portals(withIs24SubTypes: true) { id name parentConnectionId format popularWebsite accessBrokerIds accessDepartmentIds accountUrl is24OtoaAccessBrokerIds is24OtoaAccessDepartmentIds }
    salutations { id name internalName }
    letterTemplates(onlyAccessible: true) { id name category noteTypeId usesNumberRange pdfOutput }
    phoneNoteTypeId
    incomingPhoneNoteTypeId
    teams { id name mailSignature logoUrl }
    locations { id name parentLocationId }
    folders(forConnections: true) { id name }
    scoutContacts { id name }
    scoutProjects { id name }
    textSnippets { id name category body role }
    clientDetailViewGroups: detailViewGroups(entity: for_clients) { ${detailViewFieldQuery} }
    childrenDetailViewGroups: detailViewGroups(entity: for_client_children) { ${detailViewFieldQuery} }
    clientMailboxDetailViewGroups: detailViewGroups(entity: for_client_mailbox) { ${detailViewFieldQuery} }
    projectDetailViewGroups: detailViewGroups(entity: for_projects) { ${detailViewFieldQuery} }
    dealDetailViewGroups: detailViewGroups(entity: for_deals) { ${detailViewFieldQuery} }
    taskDetailViewGroups: detailViewGroups(entity: for_tasks) { ${detailViewFieldQuery} }
    savedQueryDetailViewGroups: detailViewGroups(entity: for_saved_queries) { ${detailViewFieldQuery} }
    hideSectionHeadlines,
    customFieldGroupsForClients: customFieldGroups(entity: for_clients) {
      name,
      customFields {
        name prettyName title fieldType unit formula indexName entity tagsSuperCategoryIds
        customOptions { id name condition title groupId }
      }
    }
    customFieldGroupsForProperties {
      name,
      customFields {
        name prettyName title fieldType unit formula forSavedQueries indexName entity tagsSuperCategoryIds
        customOptions { id name condition title groupId }
      }
    }
    customFieldGroupsForProjects: customFieldGroups(entity: for_projects) {
      name,
      customFields {
        name prettyName title fieldType unit formula
        customOptions { id name condition title groupId }
      }
    }
    customFieldGroupsForBrokers: customFieldGroups(entity: for_brokers) {
      name,
      customFields {
        name prettyName title fieldType unit formula permittedBrokerIds
        customOptions { id name condition title groupId }
      }
    }
    customFieldGroupsForTasks: customFieldGroups(entity: for_tasks) {
      name,
      customFields {
        name prettyName title fieldType unit formula entity tagsSuperCategoryIds
        customOptions { id name condition title groupId }
      }
    }
    customFieldGroupsForDeals: customFieldGroups(entity: for_deals) {
      name,
      customFields {
        name prettyName title fieldType unit formula
        customOptions { id name condition title groupId }
      }
    }
    externalReports(forBroker: true) { id }
    allowedRsTypesInSavedQueries forbiddenRsCategories forbiddenFieldsInSavedQueryForm
    roomResources { email name }
    webExposeeLiveDuration
    supportedPropertyLocales
    supportedClientLocales
    propertyOptionsMapping
    rights
    roles { id name description brokerIds rights }
    relationshipPartnerNames { id name }
    projectRelationshipPartnerNames { id name }
    availableCalendars
    subscribedCalendarKeys
    cities
    countries { name alphaTwo alphaThree }
    regions
    enterprise
    plan
    env
    addonsEnabled
    visibleColumnsInPropertyDeals
    color
    activeFeatures
    extensions { id enabled }
    showBrokerAvatarsInCrm
    followupLastContactTimeframe followupGroups followupStatuses followupSources
    autocompleteEventLocations
    canSendSmsReminders
    usesNumberRange
    internationalCountry
    currency isoCurrency
    useStaticDropdownForCitySelect
    logoUrl
    defaultClientAttributes
    suggestedClientsColumns
    documentTags
    commissionSplitNames
    docusignTemplates {
      id name
    }
    intercomId
    homepage
    createdAt
    name
    sellsInternationally
    sprengnetterEmail
    imogentEmail
    propnowTenant
    enabledScoutFeatures
    language
    selectableCurrencies
    selectedCurrencies
    exchangeRates
    franchiser
    franchisee
    contactAcceptanceRequestSnippetId
    excludeNotAccepted
    gdprCompliantMailSending
    outOfOfficeEmailForwarding
    allFieldsInSavedQueryForm
    upgrade
    watermarkOnPortals
    legacySearchServer
    emailReadTrackingDisabled
    taskReviewsEnabled
    demo
    offmarketEnabled
    outgoingCallUrl
    deeplConnected
    openaiConnected
    dealsPageDefaultPipelineId
    purchasePropertyDefaultPipelineId
    rentalPropertyDefaultPipelineId
    messageTranslatorSelections
    recordAllPhoneCalls
    hiddenTabsInSidebar
    customCategories { id name objectType rsType }
    paperlessRemoteTemplates { id name }
    tenantTeamActivitiesAssignment
    mailchimpInterestCategories
    mailchimpInterests
  }
  ${BROKER_QUERY}
}`

const findClosingBracketMatchIndex = (str, pos) => {
  if (str[pos] != "{") {
    throw new Error("No '{' at index " + pos)
  }
  let depth = 1
  for (let i = pos + 1; i < str.length; i++) {
    switch (str[i]) {
      case "{":
        depth++
        break
      case "}":
        if (--depth == 0) {
          return i
        }
        break
    }
  }
  return -1
}

const findSubQuery = (query, name) => {
  const subQueryIdx = query.indexOf(name)
  if (subQueryIdx === -1) return
  const nextBracketIdx = subQueryIdx + query.substring(subQueryIdx).indexOf("{")
  const nextLinebreakIdx = subQueryIdx + query.substring(subQueryIdx).indexOf("\n")
  if (nextLinebreakIdx > -1 && nextLinebreakIdx <= nextBracketIdx) return query.substring(subQueryIdx, nextLinebreakIdx)
  // breaks more than it helps
  // const nextWhitespaceIdx = subQueryIdx + query.substring(subQueryIdx).indexOf(" ")
  // if (nextWhitespaceIdx > -1 && nextWhitespaceIdx + 1 < nextBracketIdx)
  //   return query.substring(subQueryIdx, nextWhitespaceIdx)
  const closingBracketIdx = findClosingBracketMatchIndex(query, nextBracketIdx)
  return query.substring(subQueryIdx, closingBracketIdx + 1)
}

export class ActiveRecord {
  shopData: ShopData
  broker: BrokerDetail
  version: string
  announcement: any
  itemFields: any
  itemFieldType: any

  get fetched() {
    return !!this.shopData
  }

  get<T = any>(collectionName: string): Array<T> {
    if (!this.shopData) throw "Shop Data not yet fetched"

    const collection = this.shopData[collectionName]
    if (!collection) return []
    return collection.map(o => ({ ...o, id: parseInt(o.id) || o.id }))
  }

  getMap(collectionName: string): Record<string, string> {
    return this.get(collectionName).reduce((agg, cur) => {
      agg[cur.id] = cur.name
      return agg
    }, {})
  }

  async retrieve() {
    const { shop, broker, version: storedVersion } = (await getSavedStateFromIndexedDb(`shop-data`)) || {}

    if (shop && broker && !(window as any).freshlyLoggedIn) {
      var {
        shop: { updatedAt },
        appVersion: newVersion,
        announcement: newAnnouncement,
      } = await this.fetchVersion()
      if (updatedAt === shop.updatedAt && storedVersion === newVersion) {
        this.shopData = shop as ShopData
        this.broker = broker
        this.announcement = newAnnouncement
      }
    }

    if (!this.shopData) {
      const { shop, broker, appVersion, announcement } = await this.fetchData()

      this.shopData = shop as ShopData
      this.broker = broker
      this.version = appVersion
      this.announcement = announcement
      this.updateLocalStorage()
    }

    this.assignCalculatedShopFields()

    const reactiveBrokerFields = {
      uncompletedTodos: undefined,
      unreadMessagesCount: undefined,
      unreadTrackingsCount: undefined,
      unreadNotificationsCount: undefined,
      availability: undefined,
      lastVisited: undefined,
    }

    // wrapped in reactive by $db, this will trigger a reactivity update
    this.broker = Object.assign({}, this.broker, reactiveBrokerFields)

    this.assignCalculatedBrokerFields()
    return this.broker
  }

  async updateLocalStorage() {
    // uses cloneDeep to remove reactive proxies before serializing, otherwise IDB can not serialize nested structures
    await saveStateToIndexedDb(`shop-data`, {
      shop: _.omit(_.cloneDeep(this.shopData as any).$data, "groupedLocations"),
      broker: _.omit(_.cloneDeep((this.broker as any).$data), "inboxMenuItemsByBroker"),
      version: this.version,
    })
  }

  async fetchQuery(query: string, variables: Record<string, any> | undefined = undefined) {
    const response = await axios.post(`/api/v1/graphql`, {
      query,
      variables,
    })
    if (!response.data.data) {
      console.error(response.data)
      throw "Fehler beim Laden der Daten aufgetreten."
    }

    return response.data.data
  }

  async fetchStats() {
    const payload = formatPayloadToElastic(
      {
        isDue: "open",
        brokerId: [],
        brokers: [this.broker.id],
        type: "uncompleted",
        projects: [],
        clients: [],
        properties: [],
        group_ids: [],
        priority: [],
        categories: [],
      },
      this
    )
    const { broker: brokerCounters } = await this.fetchQuery(STATS_QUERY, { filterSet: payload["filter_set"] })
    Object.entries(brokerCounters).forEach(([key, value]) => {
      this.broker[key] = value
    })
  }

  async fetchVersion() {
    return this.fetchQuery(VERSION_QUERY)
  }

  async fetchData() {
    return this.fetchQuery(SHOP_QUERY)
  }

  async fetchPartialData(model: string, realtime = true) {
    console.debug("fetching partial data from shop for", model, realtime)
    const partialQuery = findSubQuery(SHOP_QUERY, model)
    if (!partialQuery) return
    const query = `{ shop { ${partialQuery} } }`
    const { data } = await axios.post(`/api/v1/graphql`, { query, realtime_push: realtime })

    if (!data.data) {
      console.error(data)
      throw "Fehler beim Laden der Daten aufgetreten."
    }

    this.setPartialData(model, data.data.shop[model])
  }

  setPartialData(model, data) {
    this.shopData[model] = data
    this.assignCalculatedShopFields(model)
    this.updateLocalStorage()
  }

  async assignCalculatedShopFields(model?: string) {
    const calcFields = {
      groupedLocations: () => getLocationGroupsFlat(this),
    }
    const dependencyMap = {
      locations: ["groupedLocations"],
    }
    if (model) {
      const fields = dependencyMap[model]
      fields?.forEach(field => {
        this.shopData[field] = calcFields[field]()
      })
    } else {
      Object.keys(calcFields).forEach(field => {
        this.shopData[field] = calcFields[field]()
      })
    }
  }

  async fetchPartialDataBroker(model: string) {
    console.log("fetching partial data from broker for", model)
    const partialQuery = findSubQuery(SHOP_QUERY, model)
    if (!partialQuery) return
    const query = `{ broker { ${partialQuery} } }`
    const { data } = await axios.post(`/api/v1/graphql`, { query })

    if (!data.data) {
      console.error(data)
      throw "Fehler beim Laden der Daten aufgetreten."
    }

    this.setPartialDataBroker(model, data.data.broker[model])
  }

  setPartialDataBroker(model, data) {
    this.broker[model] = data
    this.updateLocalStorage()
    this.assignCalculatedBrokerFields(model)
  }

  async assignCalculatedBrokerFields(model?: string) {
    const calcFields = {
      inboxMenuItemsByBroker: () => calculateInboxMenuItems(this),
    }
    const dependencyMap = {
      availableInboxes: ["inboxMenuItemsByBroker"],
    }
    if (model) {
      const fields = dependencyMap[model]
      fields?.forEach(field => {
        this.broker[field] = calcFields[field]()
      })
    } else {
      Object.keys(calcFields).forEach(field => {
        this.broker[field] = calcFields[field]()
      })
    }
  }

  async fetchBrokerData(realtime = true) {
    console.log("fetching all data from broker")
    const query = `{ ${BROKER_QUERY} }`
    const { data } = await axios.post(`/api/v1/graphql`, { query, realtime_push: realtime })

    if (!data.data) {
      console.error(data)
      throw "Fehler beim Laden der Daten aufgetreten."
    }

    this.setBrokerData(data.data.broker)
  }

  setBrokerData(data) {
    Object.keys(data).forEach(model => {
      this.broker[model] = data[model]
    })
    this.updateLocalStorage()
  }

  updateBroker(payload) {
    api.updateBroker(payload)
    this.setBroker(payload)
  }

  setBroker(payload) {
    this.broker = { ...this.broker, ...payload }
    this.updateLocalStorage()
  }

  updateShopField(fieldName, value) {
    this.shopData[fieldName] = value
  }

  updateShopFields(payload) {
    Object.assign(this.shopData, payload)
  }

  featureActive(name) {
    return this.shopData.activeFeatures.includes(name)
  }

  planActive(...plans: Plan[]) {
    return plans.includes(this.shopData?.plan || "pro")
  }

  extensionEnabled(name) {
    return this.shopData.extensions
      .filter(o => o.enabled)
      .map(o => o.id)
      .includes(name)
  }

  getItemFieldTitle(fieldName, type) {
    if (!type) return
    if (!this.itemFields || this.itemFieldType != type) {
      if (type == "property") this.itemFields = this.broker.propertyDetailViewGroups
      if (type == "client") this.itemFields = this.shopData.clientDetailViewGroups
      this.itemFieldType = type
      this.itemFields = this.itemFields
        .map(g => g.detailViewFields)
        .flat()
        .reduce((acc, dvf) => {
          acc[dvf.fieldName] = dvf.title
          return acc
        }, {})
    }

    return this.itemFields[fieldName]
  }

  // custom options
  clientSmartViews() {
    return this.broker.searchFilters.filter(o => !o.workList && o.forEntity == "for_contacts")
  }

  propertySmartViews() {
    return this.broker.searchFilters.filter(o => !o.workList && o.forEntity == "for_properties")
  }

  dealSmartViews() {
    return this.broker.searchFilters.filter(o => !o.workList && o.forEntity == "for_deals")
  }

  activitySmartViews() {
    return this.broker.searchFilters.filter(o => !o.workList && o.forEntity == "for_activities")
  }

  brokerSmartFolders() {
    return this.broker.availableInboxes
      .flatMap(i => i.smartFolders)
      .concat({ id: "me", name: "Mit mir geteilt", color: "" })
  }

  activeBrokers() {
    return this.shopData.brokers.filter(b => b.active)
  }

  departmentsForUseInInquiryDistribution() {
    return this.shopData.departments.filter(department => department.useInInquiryDistribution)
  }

  unarchivedBrokers() {
    return this.shopData.brokers.filter(b => !b.archived)
  }

  formattedActivityGroups() {
    const lookup = _.keyBy(this.shopData.superGroups, g => g.id)
    return this.shopData.activityGroups.map(g => ({
      ...g,
      name: !!g.superGroupId && g.superGroupId in lookup ? `${lookup[g.superGroupId].name}: ${g.name}` : g.name,
    }))
  }

  documentTagsSelect() {
    return this.shopData.documentTags?.map(t => ({ id: t, name: t }))
  }

  commissionSplitNamesSelect() {
    return this.shopData.commissionSplitNames?.map(t => ({ id: t, name: t }))
  }

  propertyStatuses() {
    return this.shopData.propertyStatuses.map(o => ({
      ...o,
      disabled:
        Boolean(o.writeBrokerIds.length || o.writeDepartmentIds.length) &&
        !o.writeBrokerIds.includes(this.broker.id) &&
        !o.writeDepartmentIds.some(d => this.broker.departmentIds.includes(d)),
    }))
  }

  brokerCanSeeActivityOverview() {
    return this.broker.canSeeActivityOverview || this.broker.canSeeAllClients
  }

  async logout() {
    try {
      await axios.delete("/sessions")
    } catch (e) {
      console.error(e)
    } finally {
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/")
      })
      this.reset()
    }
  }

  reset() {
    removeStateFromIndexedDb("shop-data")
    if (this.broker) {
      removeState(`shop-data-${this.broker.id}`)
    }

    this.shopData = null as any
    this.broker = {} as any
    this.itemFields = null
    this.itemFieldType = null
  }

  refetchCachedData(key) {
    const partialQuery = findSubQuery(SHOP_QUERY, key)
    if (!partialQuery) return
    const query = `shop { ${partialQuery} }`

    return api.mutation("refetchCachedData", { key }, query).then(({ shop }) => {
      this.setPartialData(key, shop[key])
    })
  }
}

export default new ActiveRecord()
