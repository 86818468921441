<template>
  <div>
    <div class="flex items-center">
      <p class="whitespace-nowrap">{{ $t("dashboard.dayFilter.today") }}</p>
      <nice-radio-group
        v-model="plusMinus"
        class="flex-nowrap ml-3"
        @change="setValue"
        v-if="!operator"
        :options="[
          { id: 'plus', icon: 'plus' },
          { id: 'minus', icon: 'minus' },
        ]"
      />
      <fa-icon v-else :name="operator" class="whitespace-nowrap ml-2" />
      <nice-input
        size="small"
        type="number"
        v-model="days"
        placeholder="x"
        @update:model-value="setValue"
        min="0"
        class="flex-grow ml-3"
      />
      <p class="whitespace-nowrap ml-3">{{ $t("dashboard.dayFilter.days") }}</p>
    </div>
    <p class="text-gray-600 text-sm" v-if="nicelyFormatted">~› {{ nicelyFormatted }}</p>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {},
    operator: {},
  },
  data() {
    return {
      plusMinus: this.operator || (this.modelValue && this.modelValue < 0 ? "minus" : "plus"),
      days: Number.isInteger(this.modelValue) ? Math.abs(this.modelValue) : null,
    }
  },
  methods: {
    setValue() {
      const newValue = this.plusMinus == "minus" && Number.isInteger(this.days) ? -this.days : this.days
      this.$emit("update:modelValue", newValue)
    },
  },
  computed: {
    nicelyFormatted() {
      if (Number.isInteger(this.modelValue)) {
        return moment().add(this.modelValue, "days").format("LL")
      }
    },
  },
}
</script>
