<template>
  <ps-row-cell
    :data-column="props.fieldName"
    class="psTable-headerCell"
    :class="[{ 'js-resizable': props.resizable }]"
    :width="props.width"
  >
    <template v-if="props.sortable">
      <div @click="sort(props.fieldName)" style="display: flex; cursor: pointer">
        <slot>{{ props.title }}</slot>
        <tip class="ml-2" v-if="props.hint" :hint="props.hint"></tip>
        <div class="ml-2">
          <div>
            <fa-icon v-show="props.sorted === 'desc'" name="sort-up" class="text-primary" />
            <fa-icon v-show="props.sorted === 'asc'" name="sort-down" class="text-primary" />
            <fa-icon v-show="!props.sorted" name="sort" class="text-light" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <slot>{{ props.title }}</slot>
      <tip class="ml-2" v-if="props.hint" :hint="props.hint"></tip>
    </template>
    <div
      v-if="props.resizable"
      class="js-column-resize-grip"
      style="top: 0px; right: 0px; bottom: 0px; width: 5px; position: absolute; cursor: col-resize"
    >
      &nbsp;
    </div>
  </ps-row-cell>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue"

const props = defineProps<{
  title?: String
  fieldName?: String
  hint?: String
  sorted?: String
  resizable?: Boolean
  sortable?: Boolean
  width?: Number
  listeners?: unknown
}>()

const emits = defineEmits(["sort"])

const sort = _fieldName => {
  let direction
  if (!props.sorted) direction = "asc"
  else if (props.sorted === "asc") direction = "desc"
  else if (props.sorted === "desc") direction = undefined
  emits("sort", direction)
}
</script>
