<template>
  <span>
    <nice-popover trigger="hover" width="300px" transition="none" :visible-arrow="false" :open-delay="300" :offset="0">
      <template #reference>
        <router-link v-if="property && property.path" :to="property.path" class="msg-participant">
          {{ property.name }}
        </router-link>
      </template>
      <router-link :to="property.path" class="card-hover">
        <main class="flex text-black">
          <div
            v-if="property.titleImageUrl"
            class="property-img bg-contain m-3"
            :style="`background-image: url(${property.titleImageUrl})`"
          ></div>
          <section class="p-3 text-left">
            <p>
              <strong :title="property.name" class="header-underline">
                {{ property.marketingTypeLabel + " › " + property.rsTypeLabel }}
                <span v-if="property.categoryLabel">{{ " › " + property.categoryLabel }}</span>
              </strong>
              <br />
              <strong>
                {{ property.zipCode + " " + property.city }}
              </strong>
              <br />
              <span v-if="property.region || country" class="text-sm">
                {{ property.region ? `${property.region}, ` : "" }}{{ country }}
              </span>
            </p>
            <p class="mt-1 text-sm flex flex-col">
              <span v-if="property.numberOfRooms">
                {{ $t("inbox.propertyCard.numberOfRooms", { rooms: property.numberOfRooms }) }}
              </span>
              <span v-if="property.livingSpace">
                {{ $t("inbox.propertyCard.livingSpace", { space: property.livingSpace }) }}
              </span>
              <span v-if="property.plotArea">
                {{ $t("inbox.propertyCard.plotArea", { space: property.plotArea }) }}
              </span>
              <span v-if="property.industrialArea">
                {{ $t("inbox.propertyCard.industrialArea", { space: property.industrialArea }) }}
              </span>
              <span v-if="property.constructionYear">
                {{ $t("inbox.propertyCard.constructionYear", { year: property.constructionYear }) }}
              </span>
            </p>
            <p class="mt-1 text-sm">
              <span v-if="property.marketingPrice">
                {{ $t(property.marketingType == "BUY" ? "inbox.propertyCard.price" : "inbox.propertyCard.baseRent") }}
                {{ $customFilters.numberToCurrency(property.marketingPrice, currency) }}
              </span>
              <span v-else-if="property.valuationPrice">
                {{
                  $t("inbox.propertyCard.valuationPrice", {
                    price: $customFilters.numberToCurrency(property.valuationPrice, currency),
                  })
                }}
              </span>
              <span v-else-if="property.valuationPriceFrom || property.valuationPriceTo">
                {{
                  $t("inbox.propertyCard.valuationPrice", {
                    price:
                      $customFilters.numberToCurrency(property.valuationPriceFrom, currency) +
                      " - " +
                      $customFilters.numberToCurrency(property.valuationPriceTo, currency),
                  })
                }}
              </span>
            </p>
          </section>
        </main>
      </router-link>
    </nice-popover>
  </span>
</template>

<script>
import { getNames, alpha3ToAlpha2 } from "i18n-iso-countries"

export default {
  props: {
    property: {
      type: Object,
    },
  },
  computed: {
    currency() {
      if (this.property.currency) return this.property.currency
      return this.$db.shopData.currency
    },
    country() {
      const countrynames = getNames(this.$db.broker.locale)
      const a2 = alpha3ToAlpha2(this.property.country)
      return countrynames[a2]
    },
  },
}
</script>

<style scoped>
.msg-participant {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 3px;
  margin-right: 0.2rem;
}
a.msg-participant {
  color: #0c40b0;
}
.msg-participant:hover {
  background: #f1f1f1;
}
.property-img {
  margin: 1rem 0 1rem 1rem !important;
  background-size: contain !important;
  width: 90px;
  height: 70px;
}

.card-hover:hover .header-underline {
  text-decoration: underline;
}
</style>
