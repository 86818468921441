<template>
  <Print :open="open" :styles="styles" :title="task.title" v-on:close="close">
    <div class="page">
      <div id="message_headers">
        <dl v-if="task.broker">
          <dt>{{ $t("task.printView.assigned") }}</dt>
          <dd>{{ task.broker.name }}</dd>
        </dl>
        <dl v-if="task.creator">
          <dt>{{ $t("task.printView.createdBy") }}</dt>
          <dd>{{ task.creator.name }}</dd>
        </dl>

        <dl>
          <dt>{{ $t("task.printView.title") }}</dt>
          <dd>
            <strong>{{ task.title }}</strong>
          </dd>
        </dl>

        <dl v-if="task.category">
          <dt>{{ $t("task.printView.type") }}</dt>
          <dd>{{ task.category.name }}</dd>
        </dl>

        <dl v-if="task.isEvent">
          <dt>{{ $t("task.printView.event") }}</dt>
          <dd>{{ appointment }}</dd>
        </dl>

        <dl v-if="task.location">
          <dt>{{ $t("task.printView.location") }}</dt>
          <dd>
            {{ task.location }}
          </dd>
        </dl>

        <dl v-if="task.dueDate">
          <dt>{{ $t("task.printView.dueDate") }}</dt>
          <dd>{{ date(task.dueDate) }}</dd>
        </dl>
        <dl>
          <dt>{{ $t("task.printView.createdAt") }}</dt>
          <dd>{{ dateOnly(task.createdAt) }}</dd>
        </dl>

        <dl>
          <dt>{{ $t("task.printView.linkedContacts") }}</dt>
          <dd>{{ list(task.clients) }}</dd>
        </dl>

        <dl v-if="task.properties && task.properties.length">
          <dt>{{ $t("task.printView.linkedProperties") }}</dt>
          <dd>{{ list(task.properties) }}</dd>
        </dl>

        <dl v-if="task.projects && task.projects.length">
          <dt>{{ $t("task.printView.linkedProjects") }}</dt>
          <dd>{{ list(task.projects) }}</dd>
        </dl>

        <dl v-if="task.viewings && task.viewings.length">
          <dt>{{ $t("task.viewings.participants") }}</dt>
          <dd>
            <div v-for="viewing in task.viewings" :key="viewing.id">
              <span style="margin-right: 1rem; display: inline-block; width: 30px">
                {{ timeOnly(viewing.viewingDate) }}
              </span>
              {{ viewing.name }} ({{ viewing.phone }})
            </div>
          </dd>
        </dl>

        <dl v-if="task.todos && task.todos.length">
          <dt>{{ $t("task.printView.checklist") }}</dt>
          <ul style="margin: 0px; padding: 0px">
            <li v-for="todo in task.todos" :key="todo.id" style="width: 470px">
              <div style="display: flex; justify-content: space-between" :class="{ done: todo.doneAt }">
                <span>
                  {{ todo.title }}
                </span>
                <span style="font-size: 9px; color: gray">
                  {{ $customFilters.date(todo.dueDate) }}
                  <span v-if="todo.brokerId">
                    – {{ $db.shopData.brokers.filter(b => b.id == todo.brokerId)[0].name }}
                  </span>
                  <span v-if="todo.departmentId">
                    – {{ $db.shopData.departments.filter(d => d.id == todo.departmentId)[0].name }}
                  </span>
                </span>
              </div>
            </li>
          </ul>
        </dl>
      </div>
      <div v-html="task.body"></div>
    </div>
  </Print>
</template>

<script>
import Print from "@/components/Print"

export default {
  emits: ["update:open"],
  props: {
    task: { type: Object },
    open: { type: Boolean },
  },
  components: {
    Print,
  },
  computed: {
    appointment() {
      return this.task.allDay
        ? this.dateOnly(this.task.startsAt) + this.task.startsAt.date !== this.task.endsAt.date
          ? ` - ${this.dateOnly(this.task.endsAt)}`
          : ""
        : `${this.timeOnly(this.task.startsAt)} - ${this.timeOnly(this.task.endsAt)} Uhr, ${this.dateOnly(
            this.task.startsAt
          )}`
    },
  },
  methods: {
    timeOnly(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("LT")
    },
    dateOnly(date) {
      return moment(date).format("L")
    },
    date(date) {
      return moment(date).format("LLL")
    },
    close() {
      this.$emit("update:open", false)
    },
    list(array) {
      return array.map(e => e.name).join(", ")
    },
  },
  data() {
    return {
      styles: ["/print.css"],
    }
  },
}
</script>
