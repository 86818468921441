<template>
  <dropdown button-class="details-action-link">
    <dropdown-section>
      <dropdown-item :title="$t('automation.detailDropdown.transfer')" @click="visible = true" />
      <dropdown-item :title="$t('automation.detailDropdown.merge')" @click="mergeVisible = true" />
    </dropdown-section>
  </dropdown>
</template>

<script lang="ts">
import { DetailProject } from "@/interfaces"
import { PropType, defineComponent } from "vue"

export default defineComponent({
  props: {
    resource: Object as PropType<DetailProject>,
  },
  data() {
    return {
      mergeVisible: false,
      visible: false,
    }
  },
})
</script>
