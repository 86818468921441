<template>
  <div class="tableActions-search">
    <fa-icon name="search" class="tableActions-searchIcon" />
    <nice-input
      ref="searchInput"
      type="search"
      :model-value="modelValue"
      @update:model-value="emit('update:modelValue', $event)"
      :placeholder="placeholder ?? $t('searchInput.placeholder')"
      autocomplete="off"
      class="tableActions-searchInput"
    />
    <span v-if="modelValue?.length" @click.prevent="clearSearch" class="tableActions-searchClose">
      <fa-icon name="times" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import NiceInput from "./NiceInput.vue"
const searchInput = ref<typeof NiceInput | null>(null)

const props = withDefaults(
  defineProps<{
    modelValue: string
    autofocus?: boolean
    placeholder?: string
  }>(),
  {
    autofocus: false,
    modelValue: "",
  }
)

const emit = defineEmits(["update:modelValue"])

const clearSearch = () => {
  emit("update:modelValue", "")
}

onMounted(() => {
  searchInput.value?.input?.focus?.()
})
</script>
