import { MaybeRef, get } from "@vueuse/core"
import { computed } from "vue"
import { useMembershipsQuery } from "./queries/use-memberships-query"

// use in combination with useIntegrationAPI
export const useMembership = (membershipId: MaybeRef<string>) => {
  const { data, isPending } = useMembershipsQuery()

  // HINT: this ensures type safety, but not necessarily the api response. we should improve that.
  const membership = computed(() => (get(data) ?? {})[get(membershipId)])

  return {
    membership,
    isPending,
  }
}
