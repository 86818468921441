export let cachedClients: any[] = []
export let cachedProperties: any[] = []
export let cachedProjects: any[] = []

export let cachedClientsMap = new Map() // cache for ALL clients that have been queried and set
export let cachedPropertiesMap = new Map() // cache for ALL properties that have been queried and set
export let cachedProjectsMap = new Map() // cache for ALL projects that have been queried and set

export const cacheClients = (newData: any[]) => {
  cachedClients = cachedClients.filter(p => !newData.map(o => o.id).includes(p.id)).concat(newData)
  newData.forEach(c => {
    cachedClientsMap.set(c.id, c)
  })
}
export const cacheProperties = (newData: any[]) => {
  cachedProperties = cachedProperties.filter(p => !newData.map(o => o.id).includes(p.id)).concat(newData)
  newData.forEach(p => {
    cachedPropertiesMap.set(p.id, p)
  })
}

export const cacheProjects = (newData: any[]) => {
  cachedProjects = cachedProjects.filter(p => !newData.map(o => o.id).includes(p.id)).concat(newData)
  newData.forEach(p => {
    cachedProjectsMap.set(p.id, p)
  })
}
