<template>
  <widget-container
    :widget="widget"
    :config="config"
    :loading="page == 1 && loading"
    :infinite-scroll="() => fetchData()"
    :infinite-scroll-disabled="loading || noMore"
  >
    <template v-slot:title>
      <div class="flex justify-between">
        <span>{{ title }}</span>
        <router-link :to="`/contacts/feed?smartview=${widget.location}`" class="text-base">
          <tooltip :content="$t('widget.link')">
            <fa-icon name="arrow-right" style="font-size: 1.2rem" />
          </tooltip>
        </router-link>
      </div>
    </template>
    <div
      v-for="item in items"
      :key="item.id"
      class="flex items-center rounded p-2 mx-1 cursor-pointer hover:bg-black/5"
      @click="open(item)"
    >
      <aside class="mr-2">
        <activities-icon :resource="item" />
      </aside>
      <main class="flex-grow pl-1">
        <p class="leading-snug">{{ item.title }}</p>
        <footer @click.stop class="text-sm">
          <router-link v-if="item.client" :to="$customFilters.clientPath(item.client.id, $db.broker)" @click.stop>
            {{ item.client.name }}
          </router-link>
          <nice-divider direction="vertical" v-if="item.client && item.property" />
          <router-link v-if="item.property" :to="`/portfolio/properties/${item.property.id}`" @click.stop>
            {{ item.property.name }}
          </router-link>
        </footer>
      </main>
      <avatar v-if="item.broker_id != $db.broker.id" :broker="item.broker" class="ml-2 shrink-0" size="24px" />
      <time class="text-gray-600 text-sm ml-2 text-right" style="min-width: 62px">
        {{ $customFilters.calendar(item.created_at) }}
      </time>
    </div>
    <p v-if="!items.length" class="px-2 mx-1 text-sm text-gray-600 italic mt-2">
      {{ $t("widget.emptystates.activities") }}
    </p>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer.vue"
import ActivitiesIcon from "@/components/activities/Icon"
import eventBus from "@/config/event-bus"
import { formatPayloadToElastic as buildQuery } from "@/views/contacts/Activities.vue"

export default {
  components: { WidgetContainer, ActivitiesIcon },
  props: {
    widget: {},
    config: {},
  },
  watch: {
    widget: {
      handler() {
        this.fetchData()
      },
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      noMore: false,
      page: 1,
    }
  },
  methods: {
    async fetchData(withLoading = true) {
      if (this.loading) return
      if (withLoading) this.loading = true
      const { data } = await this.$axios.post(`/api/v1/activities/search`, this.filters)

      this.items = this.items.concat(
        data.data.map(item => ({
          ...item,
          broker: this.$db.shopData.brokers.find(b => b.id === item.broker_id),
          category: this.$db.shopData.todoCategories.find(b => b.id === item.category_id),
          client: item.client_names && item.client_names.map((n, i) => ({ id: item.client_ids[i], name: n }))[0],
          property: item.properties && item.properties.length ? item.properties[0] : undefined,
        }))
      )
      this.total = data.meta.total_count
      this.loading = false
      this.noMore = 10 * this.page >= this.total
      this.page++
    },
    open(item) {
      eventBus.$emit("quick-view", {
        type: item.conversation_type === "reminder" ? "todo" : item.conversation_type,
        mode: "view",
        id: item.activatable_id,
      })
    },
  },
  computed: {
    filters() {
      const filter = this.$db.broker.searchFilters.find(sf => sf.id == this.widget.location)

      return {
        per: 10,
        page: this.page,
        sort_by: "created_at",
        order: "desc",
        filter_set: buildQuery(
          {
            ...filter.esFilters,
            selectableCategories: filter.esFilters.categories,
          },
          this.$db
        ).filter_set,
      }
    },
    title() {
      return this.widget.title || this.config[this.widget.category].label
    },
  },
  mounted() {
    this.fetchData()

    this.$pusher.on(`task:${this.$db.broker.id}:saved`, t => this.fetchData(false))
    this.$pusher.on(`task:${this.$db.broker.id}:deleted`, ({ id }) => {
      this.items = this.items.filter(o => o.id != id)
    })
  },
}
</script>
