<template>
  <div class="w-100">
    <form-row :title="$t('dashboard.charts.entity.label')">
      <nice-select
        v-model="internalValue.entity"
        :options="{
          property: $t('dashboard.charts.entity.options.property'),
          activity: $t('dashboard.charts.entity.options.activity'),
        }"
      />
    </form-row>
    <form-row :title="$t('dashboard.charts.chartType.label')">
      <nice-select
        v-model="internalValue.chartType"
        :options="{
          line: $t('dashboard.charts.chartType.options.line'),
          bar: $t('dashboard.charts.chartType.options.bar'),
          pie: $t('dashboard.charts.chartType.options.pie'),
          // tab: $t('dashboard.charts.chartType.options.tab'),
        }"
      />
    </form-row>
    <form-row :title="$t('dashboard.charts.aggregation.label')" direction="column" v-if="!!internalValue.entity">
      <form-row :title="$t('dashboard.charts.axis0Field.label')">
        <nice-select v-model="internalValue.axis0Field" :options="selectedableFieldsOptionsForAxis0" />
      </form-row>
      <form-row :title="$t('dashboard.charts.axis0Type.label')">
        <nice-select
          v-model="internalValue.axis0Type"
          :options="{
            sum: $t('dashboard.charts.axis0Type.options.sum'),
            min: $t('dashboard.charts.axis0Type.options.min'),
            max: $t('dashboard.charts.axis0Type.options.max'),
            avg: $t('dashboard.charts.axis0Type.options.avg'),
          }"
        />
      </form-row>

      <form-row :title="$t('dashboard.charts.axis1Field.label')">
        <nice-select v-model="internalValue.axis1Field" :options="selectedableFieldsOptionsForAxis1" />
      </form-row>
      <form-row :title="$t('dashboard.charts.axis1Interval.label')" v-if="selectedAxis1FieldType === 'Date'">
        <nice-select
          v-model="internalValue.axis1Interval"
          :options="{
            month: $t('dashboard.charts.axis1Interval.options.month'),
            quarter: $t('dashboard.charts.axis1Interval.options.quarter'),
            year: $t('dashboard.charts.axis1Interval.options.year'),
          }"
        />
      </form-row>
    </form-row>
    <form-row :title="$t('dashboard.charts.filter.label')" direction="column" v-if="!!internalValue.entity">
      <dynamic-filters :entity="internalValue.entity" v-model="internalValue.filter" />
    </form-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { getPropertyColumns } from "@/utils/get-property-columns"
import { getActivityColumns } from "@/utils/get-activity-columns"
import { getClientColumns } from "@/utils/get-client-columns"
import { getPluralizedName } from "@/utils/get-pluralized-name"
import DynamicFilters from "@/components/dashboard/DynamicFilters.vue"

const COLUMNS_MAP = {
  client: getClientColumns,
  property: getPropertyColumns,
  activity: getActivityColumns,
}

const ALLOWED_FIELDS_FOR_AXIS_0 = {
  property: ["price", "sold_price", "total_commission"],
  activity: [],
}

const ALLOWED_FIELDS_FOR_AXIS_1 = {
  property: ["created_at", "sold_date", "broker_id", "rs_type", "marketing_type"],
  activity: [
    "created_at",
    "broker_id",
    "client_broker_ids",
    // "property_broker_ids",
    // "property_statuses",
    "source_id",
    "reason_id",
    "conversation_type",
  ],
}

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
    },
  },
  data(props: any): any {
    const internalValue = this.formatInput(props.modelValue) || {}
    return {
      internalValue,
    }
  },
  watch: {
    internalValue: {
      handler(newValue: any) {
        const formatted = this.formatOutput(newValue)
        this.$emit("update:modelValue", formatted)
      },
      deep: true,
    },
  },
  components: { DynamicFilters },
  computed: {
    columns(): any {
      return COLUMNS_MAP[this.internalValue?.entity]?.(this.$db, this.$t, this.$tm) || []
    },
    pluralizedEntity(): string {
      return getPluralizedName(this.internalValue?.entity)
    },
    allowedFieldsForAxis0(): string[] {
      return ALLOWED_FIELDS_FOR_AXIS_0[this.internalValue?.entity] || []
    },
    allowedFieldsForAxis1(): string[] {
      return ALLOWED_FIELDS_FOR_AXIS_1[this.internalValue?.entity] || []
    },
    columnsOptions(): any {
      return this.columns.reduce((agg, c) => {
        agg[c.value] = c.isCustomField
          ? c.label
          : this.$t(`${this.pluralizedEntity}.formFields.${c.value.replace(/\.raw|\.keyword|\.id/, "")}`)
        return agg
      }, {})
    },
    selectedableFieldsOptionsForAxis1(): any {
      return Object.fromEntries(
        Object.entries(this.columnsOptions).filter(([key]) => this.allowedFieldsForAxis1.includes(key))
      )
    },
    selectedableFieldsOptionsForAxis0(): any {
      return Object.fromEntries(
        Object.entries(this.columnsOptions).filter(([key]) => this.allowedFieldsForAxis0.includes(key))
      )
    },
    selectedAxis1FieldType(): any {
      if (!this.internalValue?.axis1Field) return
      return this.columns.find(c => c.value === this.internalValue?.axis1Field)?.type
    },
  },
  methods: {
    formatInput(value: any): any {
      if (!value) return
      const aggSet = value.agg_set[0]
      if (["histogram", "terms"].includes(aggSet.type)) {
        const field = aggSet.fields?.[0]
        return {
          entity: value.entity,
          chartType: value.type,
          chartLabel: value.options.data_labels[0],
          axis1Field: aggSet.name,
          axis1Type: aggSet.type,
          axis1Interval: aggSet.interval,
          axis1Label: value.options.dim_labels[0],
          axis0Field: field?.name,
          axis0Type: field?.type,
          axis0Label: value.options.dim_labels[1],
          filter: { filterSet: value.options.filter, filters: value.filter_set },
        }
      }
      return {
        entity: value.entity,
        chartType: value.type,
        chartLabel: value.options.data_labels[0],
        axis0Field: aggSet.name,
        axis0Type: aggSet.type,
        axis0Label: value.options.dim_labels[0],
        filter: { filterSet: value.options.filter, filters: value.filter_set },
      }
    },
    formatOutput(value: any): any {
      let aggSet, dimLabels
      if (!!value.axis1Field) {
        if (this.selectedAxis1FieldType === "Date") {
          aggSet = [
            {
              type: "histogram",
              name: value.axis1Field,
              interval: value.axis1Interval,
              fields: value.axis0Field
                ? [
                    {
                      type: value.axis0Type,
                      name: value.axis0Field,
                    },
                  ]
                : undefined,
            },
          ]
          dimLabels = [value.axis1Label, value.axis0Label]
        } else {
          aggSet = [
            {
              type: "terms",
              name: value.axis1Field,
              fields: value.axis0Field
                ? [
                    {
                      type: value.axis0Type,
                      name: value.axis0Field,
                    },
                  ]
                : undefined,
            },
          ]
          dimLabels = [value.axis1Label, value.axis0Label]
        }
      } else if (value.axis0Type || value.axis0Field || value.axis0Label) {
        aggSet = [
          {
            type: value.axis0Type,
            name: value.axis0Field,
          },
        ]
        dimLabels = [value.axis0Label]
      }
      return {
        type: value.chartType,
        options: {
          dim_labels: dimLabels?.filter(Boolean),
          data_labels: [value.chartLabel]?.filter(Boolean),
          filter: value.filter?.filterSet,
        },
        entity: value.entity,
        filter_set: value.filter?.filters,
        agg_set: aggSet,
      }
    },
  },
})
</script>
