<template>
  <import-csv
    v-bind="$attrs"
    :modalVisible="modalVisible"
    @update:modalVisible="$emit('update:modalVisible', $event)"
    :schema="schema"
    :validate-async="validateAsync"
    :submit-async="submitAsync"
    type="broker"
    :store-key="`brokers-csv-${$db.broker.id}`"
    example-url="https://pro-propstack-public.s3.eu-west-1.amazonaws.com/helpcenter/Nutzer.csv"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue"
import ImportCsv from "@/components/ImportCsv.vue"
import * as _ from "lodash"

const ALLOWED_HEADERS = [
  "Datensatznummer",
  "Anrede",
  "Titel",
  "Vorname",
  "Name",
  "E-Mail",
  "Telefon",
  "Mobil",
  "Position",
  "Teams",
  "Rollen-Rechte",
  "Passwort",
  "Avatar",
  "Immobilientreuhänder ID",
  "Öffentliche E-Mail-Adresse",
  "Öffentliche Telefonnummer",
  "Öffentliche Handynummer",
  "Anschrift im Muster-Widerrufsformular",
]
const SCHEMA = ALLOWED_HEADERS
const customFieldKey = "customFieldGroupsForBrokers"

export default defineComponent({
  props: {
    modalVisible: Boolean,
  },
  components: {
    ImportCsv,
  },
  computed: {
    schema(): { id: string; name: string }[] {
      return [
        ...SCHEMA.map(r => ({ id: r, name: this.$t(`clients.csv.modal.columns.broker.${r}`) as string })),
        ...this.$db.shopData[customFieldKey]
          .flatMap(g => g.customFields)
          .map(r => ({ id: `CF ${r.name}`, name: r.prettyName })),
      ]
    },
  },
  methods: {
    validateAsync(_1, importer) {
      return {
        jsonImportValidation: {
          unknownHeaders: _.reject(
            Object.values(importer.map),
            (o: any) => ALLOWED_HEADERS.includes(o) || o.match(/CF /)
          ),
        },
      }
    },
    submitAsync(rows, importer) {
      return this.$axios.post("/api/v1/brokers/import", {
        rows: rows,
      })
    },
  },
})
</script>
