<template>
  <div class="grid-container">
    <grid-select-item
      v-for="item in currentGridTemplate"
      :item="item"
      :value="modelValue"
      :key="item.value"
      @position="selectPosition"
    />
  </div>
</template>

<script>
import GridSelectItem from "@/components/GridSelectItem.vue"

const defaultGrid = [
  { value: "NorthWest", label: "Oben Links" },
  { value: "North", label: "Oben Mitte" },
  { value: "NorthEast", label: "Oben Rechts" },
  { value: "West", label: "Links" },
  { value: "Center", label: "Mitte" },
  { value: "East", label: "Rechts" },
  { value: "SouthWest", label: "Unten Links" },
  { value: "South", label: "Unten Mitte" },
  { value: "SouthEast", label: "Unten Rechts" },
]

export default {
  components: { GridSelectItem },
  props: {
    modelValue: {},
    customGrid: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      defaultGrid,
    }
  },
  methods: {
    selectPosition(pos) {
      this.$emit("update:modelValue", pos)
    },
  },
  computed: {
    currentGridTemplate() {
      return this.customGrid || defaultGrid
    },
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1px;
  background-color: #e5e7eb;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
}
</style>
