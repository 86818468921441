<script lang="ts">
export enum AddConnectionType {
  immoScout = "immoScout",
  immoScoutAt = "immoScoutAt",
  openimmo = "openimmo",
  idx = "idx",
  christies = "christies",
  openimmoFeed = "openimmoFeed",
  kyeroFeed = "kyeroFeed",
  christiesFeed = "christiesFeed",
  meta = "meta",
  idealista = "idealista",
  urbanhome = "urbanhome",
}
</script>

<script setup lang="ts">
import { computed } from "vue"
import useCore from "@/plugins/use-core"
import { Connection } from "@/interfaces"
import { keys } from "lodash"

defineProps<{ item?: Connection }>()

const emit = defineEmits<{
  click: [type: AddConnectionType]
}>()

const { db } = useCore()
const internationCountry = computed(() => db.shopData.internationalCountry)

const options = computed(() =>
  keys(AddConnectionType).filter(key => {
    if (key === AddConnectionType.immoScoutAt) return internationCountry.value === "austria"
    if (key === AddConnectionType.christies || key === AddConnectionType.christiesFeed)
      return db.featureActive("christies_export")
    if (key === AddConnectionType.urbanhome) return internationCountry.value === "swiss"

    return true
  })
)
</script>

<template>
  <dropdown>
    <template #reference>
      <ps-button :title="$t('admin.connections.addPortal')" active icon="plus" class="mt-3" />
    </template>
    <dropdown-section>
      <dropdown-item
        v-for="type of options"
        :key="type"
        @click="$emit('click', type)"
        :title="$t(`admin.connections.connect.${type}`)"
      >
        <div class="px-2 flex items-center justify-betweexn w-100" v-if="type === AddConnectionType.meta">
          <span class="ml-2">
            {{ $t(`admin.connections.connect.${type}`) }}
          </span>
          <img src="@/assets/images/meta_logo.png" style="height: 18px; bottom: 1px" class="ml-2 pr-3 relative" />
        </div>
      </dropdown-item>
    </dropdown-section>
  </dropdown>
</template>
