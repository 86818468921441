<script lang="ts" setup>
import { CustomFieldEntity, CustomFieldGroup } from "@/interfaces/db"
import useCore from "@/plugins/use-core"
import { get, useVModel } from "@vueuse/core"
import { camelCase } from "lodash"
import { computed, ref } from "vue"

type Props = {
  multiple?: boolean
  clearable?: boolean
  value?: string | string[]
  entity: CustomFieldEntity
  fieldType?: string
}

const props = withDefaults(defineProps<Props>(), {
  multiple: false,
  clearable: false,
})
const emit = defineEmits(["update:modelValue"])

const model = useVModel(props, "value", emit)
const entity = ref(props.entity)

const { db } = useCore()

const options = computed(() => {
  const collection = `customFieldGroups_${get(entity)}`
  const groups = db.get<CustomFieldGroup>(camelCase(collection))

  return groups?.flatMap(g =>
    g.customFields
      .filter(f => props.fieldType == null || f.fieldType === props.fieldType)
      .map(f => ({
        id: f.name,
        name: f.prettyName,
      }))
  )
})
</script>

<template>
  <nice-select
    :multiple="multiple"
    :clearable="clearable"
    :options="options"
    :modelValue="model"
    @update:modelValue="emit('update:modelValue', $event)"
    v-bind="$attrs"
  />
</template>
