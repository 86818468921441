<template>
  <div>
    <slot>
      <small>
        <a class="light" href="#" @click.prevent="visible = true">
          <fa-icon name="magic" class="mr-1" />
          {{ $t("signatureAnalyzer.linkText") }}
        </a>
      </small>
    </slot>

    <form-dialog
      :title="$t('signatureAnalyzer.title')"
      v-model:visible="visible"
      :saving="saving"
      @submit="analyzeText"
      :disabled="text.length <= 0"
      append-to-body
    >
      <form-section>
        <textarea
          ref="input"
          v-model="text"
          class="form-control"
          :placeholder="$t('signatureAnalyzer.placeholder')"
          style="height: 400px"
        ></textarea>
      </form-section>
    </form-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
export default defineComponent({
  props: ["record"],
  emits: ["update:record"],
  data() {
    return {
      visible: false,
      saving: false,
      text: "",
    }
  },
  methods: {
    openModal() {
      this.visible = true
      this.$nextTick(() => {
        ;(this.$refs.input as any).focus()
      })
    },
    analyzeText() {
      this.saving = true

      this.$axios.post("/services/contacts/parse_address", { text: this.text }).then(({ data }) => {
        let record: any = {}
        if (data.salutation) record.salutation = data.salutation
        if (data.email) record.emails = [data.email]
        if (data.first_name) record.first_name = data.first_name
        if (data.last_name) record.last_name = data.last_name
        if (data.phone) record.home_phone = data.phone
        if (data.cell) record.home_cell = data.cell
        if (data.fax) record.office_fax = data.fax
        if (data.company) record.company = data.company
        if (data.street) record.home_street = data.street
        if (data.house_number) record.home_house_number = data.house_number
        if (data.zip) record.home_zip_code = data.zip
        if (data.city) record.home_city = data.city
        if (data.country) record.home_country = data.country
        if (data.web) record.home_url = data.web
        if (data.street) record.home_address = `${data.street}, ${data.zip || ""} ${data.city || ""}`

        this.$emit("update:record", { ...this.record, ...record })

        this.saving = false
        this.visible = false
      })
    },
  },
})
</script>
