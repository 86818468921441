<template>
  <div class="psTable-wrap">
    <div class="psTable">
      <header class="psTable-header">
        <slot name="header"></slot>
        <div class="psTable-cell psTable-headerCell psTable-cell--last"></div>
      </header>
      <main class="psTable-rows" ref="tableRowsWrapper">
        <slot></slot>
      </main>
    </div>
  </div>
</template>
