<template>
  <p v-if="attachment.name == 'smime.p7m'">
    {{ $t("inbox.attachments.titleFirst") }}
    <a :href="`https://outlook.office365.com/mail/id/${message.uid}`" target="_blank" class="text-underline">
      {{ $t("inbox.attachments.titleMidLink") }}
    </a>
    {{ $t("inbox.attachments.titleEnd") }}
  </p>
  <div v-else :class="messageForm ? 'formItem' : 'item'">
    <a
      v-redacted.keepText
      :href="attachment.url"
      target="_blank"
      :class="{ 'with-options': messageForm }"
      class="item-main relative"
    >
      <fa-icon :name="icon" :style="{ color: iconColor }" class="item-icon" />
      <span class="item-title" :title="attachment.name">{{ attachment.name }}</span>
      <span v-if="messageForm && attachment.fileSize" class="text-gray-500 text-sm">
        {{ $customFilters.humanFileSize(attachment.fileSize) }}
      </span>
      <hover-actions v-if="messageForm">
        <hover-action @click="$emit('delete')" icon="trash-alt" class="text-danger" />
      </hover-actions>
    </a>

    <dropdown v-if="!messageForm" class="item-last">
      <template v-slot:reference>
        <nice-button @click.prevent size="small" :title="$t('inbox.attachments.desc')">
          <fa-icon name="angle-down" />
        </nice-button>
      </template>
      <dropdown-section>
        <dropdown-item
          v-for="item in message.clients"
          :key="item.id"
          v-loading="attachingId == item.id"
          :title="$t('inbox.attachments.dropdown', { name: item.name })"
          @click="openModal({ clientId: item.id })"
        />
        <dropdown-item
          v-for="item in message.properties"
          :key="item.id"
          v-loading="attachingId == item.id"
          :title="$t('inbox.attachments.dropdown', { name: item.name })"
          @click="openModal({ propertyId: item.id })"
        />
        <dropdown-item
          v-for="item in message.projects"
          :key="item.id"
          v-loading="attachingId == item.id"
          :title="$t('inbox.attachments.dropdown', { name: item.name })"
          @click="openModal({ projectId: item.id })"
        />
        <dropdown-item v-loading="odUploading" @click="launchOneDrivePicker" class="px-3">
          <img src="@/assets/images/sharepoint.png" style="height: 24px" class="mr-2" />
          {{ $t("inbox.attachments.sharepoint") }}
        </dropdown-item>
        <dropdown-item v-loading="dbUploading" @click="launchDropboxPicker" class="px-3">
          <img src="@/assets/images/dropbox.png" style="height: 24px" class="mr-2" />
          {{ $t("inbox.attachments.dropbox") }}
        </dropdown-item>
        <dropdown-item @click="downloadFile" class="px-3">
          <fa-icon name="arrow-to-bottom" class="mr-2 text-center" style="width: 24px" />
          {{ $t("inbox.attachments.download") }}
        </dropdown-item>
      </dropdown-section>
    </dropdown>

    <form-dialog
      :title="`Anhang '${attachment.name}' bearbeiten`"
      v-model:visible="modalVisible"
      @submit="assignAttachment(savedProps)"
      :saving="loading"
      append-to-body
    >
      <form-row title="Name">
        <nice-input size="small" v-model="newName" />
      </form-row>
      <form-row title="Ordner">
        <nice-select v-model="folderId" :options="folders" />
      </form-row>
    </form-dialog>
  </div>
</template>

<script>
const iconMap = {
  pdf: "file-pdf",
  jpg: "file-image",
  jpeg: "file-image",
  png: "file-image",
  gif: "file-image",
  mp3: "file-audio",
  mp4: "file-video",
  zip: "file-archive",
  rar: "file-archive",
  ics: "calendar-alt",
  csv: "file-spreadsheet",
  xlsx: "file-spreadsheet",
  axlsx: "file-spreadsheet",
  doc: "file-word",
  docx: "file-word",
}
const iconColorMap = {
  "file-pdf": "#FF3500",
  "file-image": "#0f55eb",
  "file-audio": "yellow",
  "file-video": "pink",
  "file-archive": "black",
  "calendar-alt": "#0f55eb",
  "file-spreadsheet": "#217346",
  "file-word": "#2b579a",
}
const imgFileTypes = [".jpg", ".jpeg", ".png", ".gif"]

export default {
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      // required: false,
    },
    messageForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      attachingId: null,
      odUploading: false,
      dbUploading: false,
      loading: false,
      modalVisible: false,
      newName: this.attachment.name,
      folderId: undefined,
      folders: undefined,
      savedProps: undefined,
    }
  },

  methods: {
    openModal(props) {
      const attachmentName = this.attachment.name?.toLowerCase()
      if (attachmentName && imgFileTypes.some(t => attachmentName.includes(t))) {
        this.assignAttachment(props)
      } else {
        this.savedProps = props
        this.modalVisible = true
        this.fetchFolders(props)
      }
    },
    async fetchFolders(props) {
      const { resource } = await this.$graphql(`query media {
        ${
          props.propertyId || props.projectId || props.clientId
            ? `resource: ${props.clientId ? "client" : props.propertyId ? "property" : "project"}(id: ${
                props.propertyId || props.projectId || props.clientId
              }) {
          folders { id, name sendable lpLiveDuration password ignoreLpBlocked parentId }
        }
        `
            : ""
        }
      }`)
      this.folders = resource.folders.map(f => ({ id: f.id, name: f.name }))
    },
    assignAttachment({ clientId, propertyId, projectId }) {
      this.loading = true
      this.attachingId = clientId || propertyId || projectId
      this.$axios
        .post(`/mailbox/messages/${this.message.id}/message_attachments/${this.attachment.id}/copy`, {
          project_id: projectId,
          property_id: propertyId,
          client_id: clientId,
          title: this.newName != this.attachment.name ? this.newName : undefined,
          folder_id: this.folderId,
        })
        .then(_ => {
          this.loading = false
          App.flashy(this.$t("inbox.attachments.success"))
        })
        .catch(err => {
          this.$axios.handleError(err)
        })
        .finally(_ => {
          this.attachingId = null
          this.modalVisible = false
        })
    },
    launchOneDrivePicker() {
      const self = this
      self.odUploading = true
      OneDrive.save({
        clientId: "e7a2dba4-a5d4-4dbd-8add-57812bae5737",
        action: "save",
        sourceInputElementId: this.attachment.url,
        fileName: this.attachment.name,
        advanced: { redirectUri: `${window.location.origin}/dashboard/msft` },
        nameConflictBehavior: "rename",
        success: function (result) {
          self.odUploading = false
          App.flashy(self.$t("inbox.attachments.uploadSuccess"))
        },
        cancel: function (result) {
          self.odUploading = false
        },
        error: function (error) {
          console.log("error", error)
          App.alert(self.$t("inbox.attachments.uploadError"))
          self.odUploading = false
        },
      })
    },
    launchDropboxPicker() {
      const self = this
      self.dbUploading = true
      Dropbox.save({
        files: [{ url: self.attachment.url, filename: self.attachment.name }],
        success: function (result) {
          self.dbUploading = false
          App.flashy(self.$t("inbox.attachments.uploadSuccess"))
        },
        cancel: function (result) {
          self.dbUploading = false
        },
        error: function (error) {
          console.log("error", error)
          App.alert(self.$t("inbox.attachments.uploadError"))
          self.dbUploading = false
        },
      })
    },
    downloadFile() {
      window.open(`/api/v1/message_attachments/${this.attachment.id}/download`)
    },
  },

  computed: {
    suffix() {
      const splitted = this.attachment.name.toLowerCase().split(".")
      return splitted[splitted.length - 1]
    },

    icon() {
      return iconMap[this.suffix] || "file-alt"
    },
    iconColor() {
      return iconColorMap[this.icon] || "black"
    },
  },
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  margin-bottom: 0.5rem;
  width: 225px;
  margin-right: 0.5rem;
}

.formItem {
  display: flex;
  margin-bottom: 0.5rem;
  width: 338px;
}

.item-main {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid #d8d9da;
  border-right: none;
  color: black;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  overflow: hidden;

  &.active,
  &:hover {
    background-color: rgba(0, 0, 0, 0.07);
    color: inherit;
  }
}

.with-options {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-right: 1px solid #d8d9da;
}
.item-main:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-right: 1px solid #d8d9da;
}
.item-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
}
.item-icon {
  font-size: 1.25rem;
  margin-right: 0.75rem;
  opacity: 0.7;
}
</style>
