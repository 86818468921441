type QuickviewProperties = {
  clientId: number | null
  propertyId: number | null
  projectId: number | null
}

export default {
  clientId: null,
  propertyId: null,
  projectId: null,
} as QuickviewProperties
