<template>
  <div class="searchFilter-item">
    <div class="searchFilter-operator">
      <p>
        {{ title }}
        <tip v-if="hint" :content="hint" class="ml-1" />
      </p>
    </div>
    <div class="searchFilter-value">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    hint: { type: String, required: false },
  },
}
</script>
