<template>
  <div>
    <tooltip v-if="!item.xmlFeedUrl && !item.preconfigured" :content="$t('admin.connections.checkConnection')">
      <hover-action
        @click="$emit('testPortal', item)"
        icon="plug"
        :class="{ 'opacity-50 pointer-events-none': beingTested }"
      />
    </tooltip>
    <tooltip v-if="item.xmlFeedUrl" :content="$t('admin.connections.urlOpen')">
      <hover-action @click="$emit('visit', item.xmlFeedUrl)" icon="link" />
    </tooltip>
    <tooltip
      v-if="item.format == 'IS24' && $db.featureActive('scout_webhooks') && isDevBackdoor"
      :content="$t('admin.connections.reconnectScoutWebhook')"
    >
      <hover-action @click="$emit('reconnectScoutWebhook', item)" icon="sync" />
    </tooltip>
    <tooltip v-if="item.format == 'IS24'" :content="$t('admin.connections.objectsImport')">
      <hover-action @click="$emit('importFromIS24', item)" icon="arrow-to-bottom" />
    </tooltip>
    <tooltip v-if="item.format == 'MLS'" :content="$t('admin.connections.objectsImport')">
      <hover-action @click="$emit('importFromMls', item)" icon="arrow-to-bottom" />
    </tooltip>
    <tooltip v-if="item.rotationCount" :content="$t('admin.connections.rotationStart')">
      <hover-action @click="$emit('startRotation', item)" icon="sync" />
    </tooltip>
  </div>
</template>

<script>
import useEnvironment from "@/plugins/use-environment"

export default {
  props: {
    item: {
      type: Object,
    },
    beingTested: {
      type: Boolean,
    },
  },
  setup() {
    const { isDevBackdoor } = useEnvironment()
    return { isDevBackdoor }
  },
}
</script>
