<template>
  <div class="box">
    <header v-if="!hideSectionHeadlines" class="box-header">
      {{ group.name }}
      <a
        href="#"
        @click="runAction"
        v-if="group.action"
        class="mt-1 text-base font-normal pull-right"
        :disabled="isActionLoading"
      >
        {{ group.action.label }}
      </a>
    </header>
    <a
      v-else-if="group.action"
      href="#"
      @click="runAction"
      class="mt-1 text-base font-normal pull-right"
      :disabled="isActionLoading"
    >
      {{ group.action.label }}
    </a>
    <template v-for="(chunk, idx) in chunks" :key="idx">
      <div :class="{ fieldsChunk: splitIntoTwoColumns }">
        <base-field
          v-for="field in chunk"
          :key="field.fieldName == 'container' ? `${field.fieldName}-${field.detailViewField.id}` : field.fieldName"
          :field="field"
          :record="record"
          :formFields="formFields"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import { Field, FormField, Group } from "../../interfaces"
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"

export default defineComponent({
  props: {
    record: Object,
    group: Object as PropType<Group>,
    formFields: Object as PropType<Record<string, FormField>>,
  },
  data() {
    return {
      actionLoading: false,
    }
  },
  computed: {
    isActionLoading() {
      return coerceBooleanAttribute(this.actionLoading)
    },
    splitIntoTwoColumns(): boolean {
      return !!this.group.splitIntoTwoColumns
    },
    hideSectionHeadlines(): boolean {
      return this.$db.shopData.hideSectionHeadlines
    },
    chunks(): Array<Field[]> {
      return _.chunk(this.group.fields, 2)
    },
  },
  methods: {
    async runAction() {
      if (this.actionLoading) return
      try {
        this.actionLoading = true
        await this.group.action?.fn()
      } finally {
        this.actionLoading = false
      }
    },
  },
})
</script>

<style scoped>
.box {
  margin-bottom: 1.5rem;
}
.box-header {
  padding: 0.6rem 0.1rem;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}
@media screen and (min-width: 1600px) {
  .fieldsChunk {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
