<template>
  <form-dialog
    :visible="modalVisible"
    @update:visible="handleUpdateVisible"
    :title="$t('properties.listing.title')"
    width="400px"
    footerType="bigButton"
    :disabled="!listingId || listingId.toString().length < 8 || listingId.toString().length > 10"
    :submitButtonTitle="$t('properties.listing.submit')"
    @submit="importListing"
  >
    <div class="-mt-3">
      <label>{{ $t('properties.listing.input') }}</label>
    </div>
    <div>
      <nice-input size="small" ref="numpad" type="number" v-model="listingId" :placeholder="$t('properties.listing.placeholder')" class="mt-2 -mb-10" />
    </div>
  </form-dialog>
</template>

<script>
export default {
  props: ["modalVisible"],
  data() {
    return {
      listingId: null,
    }
  },
  methods: {
    handleUpdateVisible(event) {
      this.$emit('update:modalVisible', event)
      this.listingId = null
    },
    importListing() {
      return this.$axios
        .post("/portfolio/import/listing", {
          listing_id: this.listingId,
        })
        .then(() => {
          this.$emit("update:modalVisible", false)
          this.listingId = null
          App.flashy(this.$t('properties.listing.success'))
        })
    },
  },
}
</script>