<script lang="ts" setup>
import useCore from "@/plugins/use-core"
import { onMounted } from "vue"
import { computed } from "vue"
import { ref } from "vue"

type SeekersMap = {
  seekers: number
  homeownerSeekers: number
  financiallyApprovedHomeownerSeekers: number
}

const visible = defineModel<boolean>({
  required: true,
  default: false,
})

const props = withDefaults(
  defineProps<{
    propertyId: number
    lpShareUrl: string
  }>(),
  {
    propertyId: 0,
    lpShareUrl: "",
  }
)

const submited = ref(false)
const loading = ref(false)
const error = ref(false)
const seekersMap = ref<SeekersMap | undefined>()

const { api } = useCore()

const emit = defineEmits(["submited", "error", "close", "update:modelValue", "trackOffmarket"])

const submit = async () => {
  loading.value = true

  try {
    seekersMap.value = await api.mutation(
      "dispatchOffmarketPropertyData",
      { propertyId: props.propertyId },
      "seekers homeownerSeekers financiallyApprovedHomeownerSeekers"
    )

    submited.value = true
    emit("submited", seekersMap.value)
  } catch (e) {
    error.value = true
    emit("error")
    console.error("dispatchOffmarketPropertyData:", e)
  } finally {
    loading.value = false
  }
}

const close = () => {
  if (loading.value) return

  emit("close", seekersMap.value)
  emit("update:modelValue", false)
}

const hasActiveSeekers = computed(() => {
  return !!(
    seekersMap.value?.seekers ||
    seekersMap.value?.homeownerSeekers ||
    seekersMap.value?.financiallyApprovedHomeownerSeekers
  )
})

onMounted(() => {
  window.addEventListener("visibilitychange", _event => {
    // on the navigation type checking refresh or close tab/browser for logout
    const navTiming = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming
    if (navTiming.type != "reload" && visible) {
      emit("trackOffmarket", seekersMap.value)
    }
    return false
  })
})
</script>

<template>
  <nice-dialog class="custom-dialog" :model-value="visible" @close="close" width="625px" append-to-body>
    <main class="text-center">
      <div style="margin-top: -30px" class="px-3 flex" :class="{ invisible: loading || error || submited }">
        <div style="padding: 2px; width: 18px; height: 18px" class="bg-green-500 flex justify-center rounded-full mr-2">
          <fa-icon class="text-white" name="check" />
        </div>
        <span class="text-sm font-semibold text-green-600">
          {{ $t("panes.messageForm.offmarket.success") }}
        </span>
      </div>
      <div class="flex flex-col px-3" style="margin: 10px 35px 40px">
        <span v-if="loading" class="mt-8 text-2xl text-left font-bold break-normal">
          {{ $t("panes.messageForm.offmarket.loadingTitle") }}
        </span>
        <span v-else-if="!submited" class="mt-8 text-2xl text-left font-bold">
          {{ $t("panes.messageForm.offmarket.titleFirstHalf") }}
          <br />
          {{ $t("panes.messageForm.offmarket.titleSecondHalf") }}
        </span>
        <span v-else-if="hasActiveSeekers && submited" class="mt-8 text-2xl text-left font-bold break-normal">
          {{ $t("panes.messageForm.offmarket.successTitle", { seeker: seekersMap?.seekers }) }}
        </span>
        <span v-else class="mt-8 text-2xl text-left font-bold break-normal">
          {{ $t("panes.messageForm.offmarket.failTitle") }}
        </span>
        <p
          v-if="!submited || loading"
          :class="{ invisible: loading }"
          class="text-left mt-2"
          v-html="
            $t('panes.messageForm.offmarket.desc', {
              link: `<a href=\'${lpShareUrl}\' target=\'_blank\'>${$t(
                'panes.messageForm.offmarket.shortSynopsis'
              )}</a>`,
            })
          "
        ></p>
        <p v-else-if="hasActiveSeekers && submited" class="text-left mt-2">
          {{ $t("panes.messageForm.offmarket.successDesc") }}
        </p>
        <p v-else class="text-left mt-2">{{ $t("panes.messageForm.offmarket.failDesc") }}</p>
      </div>
      <div v-if="loading" class="w-100 flex flex-col" style="padding: 0 100px">
        <div class="inline-flex items-center" style="margin-bottom: 8px">
          <spinner class="mr-2" />
          <p v-html="$t('panes.messageForm.offmarket.loadingAspect1')" />
        </div>
        <div class="inline-flex items-center">
          <spinner class="mr-2" />
          <p v-html="$t('panes.messageForm.offmarket.loadingAspect2')" />
        </div>
      </div>
      <div v-else-if="!submited" class="w-100 flex flex-col" style="padding: 0 100px">
        <div class="inline-flex items-center" style="margin-bottom: 8px">
          <fa-icon name="check" class="text-xl mr-2" />
          <p v-html="$t('panes.messageForm.offmarket.benefit1')" />
        </div>
        <div class="inline-flex items-center">
          <fa-icon name="check" class="text-xl mr-2" />
          <p v-html="$t('panes.messageForm.offmarket.benefit2')" />
        </div>
      </div>
      <div v-else-if="hasActiveSeekers && submited" class="w-100 flex flex-col" style="padding: 0 100px">
        <div v-if="seekersMap?.seekers" class="inline-flex items-center" style="margin-bottom: 8px">
          <fa-icon name="check" class="text-xl mr-2" />
          <p v-html="$t('panes.messageForm.offmarket.successAchievement1', { seeker: seekersMap?.seekers })" />
        </div>
        <div v-if="seekersMap?.homeownerSeekers" class="inline-flex items-center">
          <fa-icon name="check" class="text-xl mr-2" />
          <p v-html="$t('panes.messageForm.offmarket.successAchievement2', { seeker: seekersMap?.homeownerSeekers })" />
        </div>
      </div>
      <div v-else class="w-100 flex flex-col invisible" style="padding: 0 100px">
        <div class="inline-flex items-center" style="margin-bottom: 8px">
          <fa-icon name="check" class="text-xl mr-2" />
          <p v-html="$t('panes.messageForm.offmarket.benefit1')" />
        </div>
        <div class="inline-flex items-center">
          <fa-icon name="check" class="text-xl mr-2" />
          <p v-html="$t('panes.messageForm.offmarket.benefit2')" />
        </div>
      </div>

      <div
        class="w-100 flex justify-center"
        :class="{ invisible: loading || error || submited }"
        style="margin-top: 35px; margin-bottom: 30px"
      >
        <p v-if="error" class="text-red-600 text-center" style="width: 430px">
          {{ $t("panes.messageForm.offmarket.sendError") }}
        </p>
        <nice-button v-else style="width: 250px; height: 40px" @click="submit" :loading="loading" type="primary">
          {{ $t("panes.messageForm.offmarket.submit") }}
        </nice-button>
      </div>
      <div
        :class="{ invisible: loading || error || (!hasActiveSeekers && submited) }"
        class="w-100 flex justify-center"
      >
        <a
          href="https://propstack.zendesk.com/hc/de/articles/18360705350685-Qualifizierte-Suchende-f%C3%BCr-Kaufobjekte"
          target="_blank"
          style="font-size: 0.85rem"
        >
          {{ $t("panes.messageForm.offmarket.footer") }}
        </a>
      </div>
    </main>
  </nice-dialog>
</template>

<style>
.custom-dialog > .el-dialog {
  background-color: #fff;
}
</style>
