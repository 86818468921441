<template>
  <div>
    <div class="mb-2">
      <nice-select
        filterable
        fixed-position
        v-model="ta.payload.todoTypeId"
        placeholder="Kategorie auswählen"
        :options="options"
      ></nice-select>
    </div>
    <div class="mb-2">
      <nice-select
        filterable
        fixed-position
        v-model="ta.payload.dueDate"
        placeholder="Fälligkeit"
        :options="dueDates"
      ></nice-select>
    </div>
  </div>
</template>

<script>
import { MOMENTS } from "@/config/followup-activity"

export default {
  props: {
    shopData: {
      type: Object,
      required: true,
    },
    ta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    options() {
      return this.shopData.todoCategories
    },
    dueDates() {
      const mom = MOMENTS.map(m => ({
        id: m.value,
        name: this.$t(`activityTypes.followupActivity.moments.${m.label}`, { days: m.days }),
      }))
      return mom
    },
  },
}
</script>
