import _ from "lodash"
import DynamicFilter from "../components/DynamicFilter"

const groupByKey = function (acc, esFilter) {
  const { key } = esFilter
  if (!key) {
    return acc
  }
  acc[key] = acc[key] || []
  acc[key].push(...(Array.isArray(esFilter.value) ? esFilter.value : [esFilter.value]))
  return acc
}

const formatToESRange = (esFilter, nested) => {
  const result = formatToESDynamicPartRange(esFilter, nested)
  if (!esFilter.params || !(result.length || result.key)) return result
  if (!Array.isArray(result)) {
    return { ...result, value: _.flatten([result.value, esFilter.params]) }
  }
  return result.map(res => ({ ...res, value: _.flatten([res.value, esFilter.params]) }))
}

const formatToESDynamicPartRange = function (esFilter, nested) {
  const lower = esFilter.find(s => s.fieldName === s.range)
  const upper = esFilter.find(s => s.fieldName !== s.range)
  const lowerFieldName = esFilter.map(s => s.range).filter(Boolean)[0]
  const upperFieldName = `${lowerFieldName}_to`
  if ((!lower || lower.operator === "gt") && (!upper || upper.operator === "lt")) {
    // (startA === null || endB === null || startA <= endB) && (endA === null || startB === null || endA >= startB)
    return [
      upper && typeof upper.value === "number"
        ? {
            key: "must",
            value: {
              bool: {
                should: [
                  {
                    bool: {
                      must: { range: { [lowerFieldName]: { ["lt"]: upper.value } } },
                    },
                  },
                  {
                    bool: {
                      must_not: { exists: { field: lowerFieldName } },
                    },
                  },
                ],
              },
            },
          }
        : undefined,
      lower && typeof lower.value === "number"
        ? {
            key: "must",
            value: {
              bool: {
                should: [
                  {
                    bool: {
                      must: { range: { [upperFieldName]: { ["gt"]: lower.value } } },
                    },
                  },
                  {
                    bool: {
                      must_not: { exists: { field: upperFieldName } },
                    },
                  },
                ],
              },
            },
          }
        : undefined,
      {
        key: "must",
        value: {
          bool: {
            should: [
              {
                bool: {
                  must: { exists: { field: upperFieldName } },
                },
              },
              {
                bool: {
                  must: { exists: { field: lowerFieldName } },
                },
              },
            ],
          },
        },
      },
    ].filter(Boolean)
  }
  return esFilter.map(v => formatToESDynamicPart(v, nested))
}

const formatToES = (esFilter, nested) => {
  const result = formatToESDynamicPart(esFilter, nested)
  if (!esFilter.params || !(result.length || result.key)) return result
  if (!Array.isArray(result)) {
    return { ...result, value: _.flatten([result.value, esFilter.params]) }
  }
  return result.map(res => ({ ...res, value: _.flatten([res.value, esFilter.params]) }))
}

const formatToESDynamicPart = function (esFilter, nested) {
  if (nested == null) {
    nested = false
  }
  if (
    !["exists", "missing", "today", "thisMonth", "lastMonth", "thisYear", "lastYear"].includes(esFilter.operator) &&
    esFilter.type !== "Boolean" &&
    esFilter.value !== 0 &&
    ([undefined, NaN].includes(esFilter.value) ||
      (esFilter.type !== "Array" && esFilter.value === null) ||
      esFilter.value?.length <= 0)
  ) {
    return {}
  }
  const { fieldName } = esFilter

  if (esFilter.renderedQuery) return esFilter.renderedQuery
  switch (esFilter.operator) {
    case "contains":
      return {
        key: "must",
        value: {
          bool: {
            should: _.flatten([esFilter.value])
              .map(function (v) {
                if (v === "nil" || v === "null") {
                  return { bool: { must_not: { exists: { field: fieldName } } } }
                } else {
                  return { term: { [fieldName]: v } }
                }
              })
              .concat(esFilter.null ? { bool: { must_not: { exists: { field: fieldName } } } } : undefined)
              .filter(Boolean),
          },
        },
      }
    case "doesNotContain":
      if (nested) {
        return {
          key: "must",
          value: {
            bool: {
              should: _.flatten([esFilter.value]).map(function (v) {
                if (v === "nil" || v === "null") {
                  return {
                    bool: { must_not: { exists: { field: fieldName } } },
                  }
                } else {
                  return { term: { [fieldName]: v } }
                }
              }),
            },
          },
        }
      } else {
        return {
          key: "must_not",
          value: {
            bool: {
              should: _.flatten([esFilter.value]).map(function (v) {
                if (v === "nil" || v === "null") {
                  return {
                    bool: { must_not: { exists: { field: fieldName } } },
                  }
                } else {
                  return { term: { [fieldName]: v } }
                }
              }),
            },
          },
        }
      }
    case "is":
      if (esFilter.type === "String") {
        if (fieldName == "zip_code" && esFilter.value?.includes(",")) {
          return {
            key: "must",
            value: {
              terms: { [fieldName]: esFilter.value.split(",").map(o => o.trim()) },
            },
          }
        } else {
          return {
            key: "must",
            value: {
              multi_match: {
                query: esFilter.value,
                fields: [fieldName],
                type: "phrase_prefix",
              },
            },
          }
        }
      } else {
        if (esFilter.value === false) {
          if (esFilter.type === "Array") {
            return {
              key: "must",
              value: {
                bool: {
                  should: [{ term: { [fieldName]: false } }],
                },
              },
            }
          }
          return {
            key: "must",
            value: {
              bool: {
                should: [{ term: { [fieldName]: false } }, { bool: { must_not: { exists: { field: fieldName } } } }],
              },
            },
          }
        } else {
          if (esFilter.value === "nil" || (esFilter.type === "Array" && esFilter.value === null)) {
            return { key: "must_not", value: { exists: { field: fieldName } } }
          } else if (esFilter.null) {
            return {
              key: "must",
              value: {
                bool: {
                  should: _.flatten([esFilter.value])
                    .map(v => ({ term: { [fieldName]: v } }))
                    .concat({ bool: { must_not: { exists: { field: fieldName } } } })
                    .filter(Boolean),
                },
              },
            }
          } else {
            return _.flatten([esFilter.value]).map(v => ({
              key: "must",
              value: { term: { [fieldName]: v } },
            }))
          }
        }
      }
    case "isnt":
      if (esFilter.type === "String") {
        if (fieldName == "zip_code" && esFilter.value?.includes(",")) {
          return esFilter.value.split(",").map(v => ({
            key: "must_not",
            value: { term: { [fieldName]: v.trim() } },
          }))
        } else {
          return {
            key: "must_not",
            value: {
              multi_match: {
                query: esFilter.value,
                fields: [fieldName],
                type: "phrase_prefix",
              },
            },
          }
        }
      } else {
        if (esFilter.value === "nil") {
          return { key: "must", value: { exists: { field: fieldName } } }
        } else {
          return _.flatten([esFilter.value]).map(v => ({
            key: "must_not",
            value: { term: { [fieldName]: v } },
          }))
        }
      }
    case "exists":
      if (esFilter.type === "String") {
        return [
          { key: "must", value: { exists: { field: fieldName } } },
          { key: "must_not", value: { term: { [fieldName]: "" } } },
        ]
      } else {
        return { key: "must", value: { exists: { field: fieldName } } }
      }
    case "missing":
      if (nested) {
        return { key: "must", value: { exists: { field: fieldName } } }
      } else {
        return {
          key: "must",
          value: {
            bool: {
              should: [
                esFilter.type === "String" ? { term: { [fieldName]: "" } } : undefined,
                { bool: { must_not: { exists: { field: fieldName } } } },
              ],
            },
          },
        }
      }
    case "lt":
    case "gt":
      const momentValue =
        esFilter.operator === "lt"
          ? moment(esFilter.value).endOf("day").format()
          : moment(esFilter.value).startOf("day").format()
      return {
        key: "must",
        value: {
          range: {
            [fieldName]: {
              [esFilter.operator]: esFilter.type === "Date" ? momentValue : esFilter.value,
            },
          },
        },
      }
    case "dlte":
    case "dgte":
      if (fieldName === "dob") {
        const value = `1972-${moment()
          .add(esFilter.value * (esFilter.operator === "dgte" ? -1 : 1), "days")
          .format("MM-DD")}`
        return [
          {
            key: "must",
            value: {
              range: {
                birthday: {
                  [esFilter.operator.replace("d", "")]: value,
                },
              },
            },
          },
          {
            key: "must",
            value: {
              range: {
                birthday: {
                  [esFilter.operator.includes("gte") ? "lte" : "gte"]: `1972-${moment().format("MM-DD")}`,
                },
              },
            },
          },
        ]
      } else {
        const value = `now${esFilter.value < 0 ? esFilter.value : "+" + esFilter.value}d/d`
        return {
          key: "must",
          value: {
            range: {
              [fieldName]: { [esFilter.operator.replace("d", "")]: value },
            },
          },
        }
      }
    case "today":
      const value = `1972-${moment().format("MM-DD")}`
      return { key: "must", value: { term: { birthday: value } } }
    case "thisMonth":
      return { key: "must", value: { range: { [fieldName]: { gte: "now/M" } } } }
    case "lastMonth":
      return { key: "must", value: { range: { [fieldName]: { gte: "now+1m-1M/M", lt: "now/M" } } } }
    case "thisYear":
      return { key: "must", value: { range: { [fieldName]: { gte: "now/y" } } } }
    case "lastYear":
      return { key: "must", value: { range: { [fieldName]: { gte: "now+1m-1y/y", lt: "now/y" } } } }
    case "lastXDays":
      return {
        key: "must",
        value: {
          range: {
            [fieldName]: {
              gte: `now+1m-${esFilter.value < 0 ? -esFilter.value : esFilter.value}d/d`,
              lt: "now/d",
            },
          },
        },
      }
    case "lastXMonths":
      return {
        key: "must",
        value: {
          range: {
            [fieldName]: {
              gte: `now+1m-${esFilter.value < 0 ? -esFilter.value : esFilter.value}M/M`,
              lt: "now/M",
            },
          },
        },
      }
    default:
      return {}
  }
}

export const buildQuery = (filterSet, db) => {
  const nestedGroupedByPath = _.groupBy(
    filterSet.filter(o => o.nested),
    "nested"
  )
  const nestedESFilters = _.flatten(
    Object.keys(nestedGroupedByPath).map(path => {
      let esFilterQueries
      const filters = []
      const negativeFilters = nestedGroupedByPath[path].filter(filter =>
        ["doesNotContain", "missing"].includes(filter.operator)
      )
      if (negativeFilters.length > 0) {
        _.flatten(negativeFilters.filter(o => !o.distinct).map(o => formatToES(o, true))).forEach(esFilterQueries => {
          if (Object.keys(esFilterQueries).length > 0) {
            filters.push({
              key: "must_not",
              value: {
                nested: {
                  path,
                  query: {
                    bool: _.flatten([esFilterQueries]).reduce(groupByKey, {}),
                  },
                },
              },
            })
          }
        })
        esFilterQueries = _.flatten(negativeFilters.filter(o => o.distinct).map(o => formatToES(o, true))).reduce(
          groupByKey,
          {}
        )
        if (Object.keys(esFilterQueries).length > 0) {
          filters.push({
            key: "must_not",
            value: {
              nested: {
                path,
                query: {
                  bool: esFilterQueries,
                },
              },
            },
          })
        }
      }

      const remainingFilters = nestedGroupedByPath[path].filter(
        filter => !["doesNotContain", "missing"].includes(filter.operator)
      )
      if (remainingFilters.length > 0) {
        _.flatten(remainingFilters.filter(o => o.distinct).map(o => formatToES(o, true))).forEach(esFilterQueries => {
          if (Object.keys(esFilterQueries).length > 0) {
            filters.push({
              key: "must",
              value: {
                nested: {
                  path,
                  query: {
                    bool: _.flatten([esFilterQueries]).reduce(groupByKey, {}),
                  },
                },
              },
            })
          }
        })
        if (db.featureActive("elastic_range_filter")) {
          esFilterQueries = _.flatten(
            _.values(
              _.groupBy(
                remainingFilters.filter(o => !o.distinct && o.range),
                o => o.range
              )
            ).map(o => formatToESRange(o, true))
          ).reduce(groupByKey, {})
          if (Object.keys(esFilterQueries).length > 0) {
            filters.push({
              key: "must",
              value: {
                nested: {
                  path,
                  query: {
                    bool: esFilterQueries,
                  },
                },
              },
            })
          }
          esFilterQueries = _.flatten(
            remainingFilters.filter(o => !o.distinct && !o.range).map(o => formatToES(o, true))
          ).reduce(groupByKey, {})
          if (Object.keys(esFilterQueries).length > 0) {
            filters.push({
              key: "must",
              value: {
                nested: {
                  path,
                  query: {
                    bool: esFilterQueries,
                  },
                },
              },
            })
          }
        } else {
          esFilterQueries = _.flatten(remainingFilters.filter(o => !o.distinct).map(o => formatToES(o, true))).reduce(
            groupByKey,
            {}
          )
          if (Object.keys(esFilterQueries).length > 0) {
            filters.push({
              key: "must",
              value: {
                nested: {
                  path,
                  query: {
                    bool: esFilterQueries,
                  },
                },
              },
            })
          }
        }
      }

      return filters
    })
  )

  return _.flatten(filterSet.filter(o => !o.nested).map(o => formatToES(o, false)))
    .concat(nestedESFilters)
    .reduce(groupByKey, {})
}

export default entity => ({
  data() {
    const searchFilters = this.$db.broker.searchFilters.filter(s => !s.workList && s.forEntity === entity)
    const defaultSmartView =
      entity == "for_contacts" ? this.$db.broker.defaultClientsFilter : this.$db.broker.defaultPropertiesFilter
    return {
      defaultSmartView,
      searchFilters: searchFilters,
      filterSet: [],
      smartViewFormVisible: false,
      filterSettingsVisible: false,
      selectedSmartView: defaultSmartView ? parseInt(defaultSmartView) : undefined,
      selectedFilter: {},
    }
  },

  components: {
    DynamicFilter,
  },

  watch: {
    validFilters: {
      handler(newVal, oldVal) {
        if (_.isEqual(newVal, oldVal)) return
        if (this.currentPage) this.currentPage = 1
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    setDataFromFilter(filter) {
      if (filter.esFilters && filter.storageType != "layout") {
        this.filterSet = filter.esFilters
      }
      const params = JSON.parse(filter.params)
      // if (params.per) this.per = params.per
      if (params.sort_by) this.sortBy = params.sort_by
      if (params.order) this.order = params.order
      this.customColumnsFromFilter = params.columns

      this.selectedFilter = {
        filters: filter.esFilters,
        sortBy: params.sort_by,
        order: params.order,
        columns: params.columns,
      }
    },

    addFilter() {
      this.filterSet = this.filterSet.concat([{ key: Date.now() }])
    },

    removeFilter(key) {
      this.filterSet = this.filterSet.filter(f => key !== f.key)
    },

    updateFilters(esData) {
      this.filterSet = _.orderBy(this.filterSet.filter(f => f.key !== esData.key).concat([esData]), "key")
      if (this.$route.query.smartview) this.$router.push({ query: { smartview: undefined } })
    },

    openFilterForm() {
      this.smartViewFormVisible = true
      this.$nextTick(() => this.$refs.smartViewForm?.focus?.())
    },

    selectSmartView(filter) {
      if (filter) {
        this.selectedSmartView = filter.id
        this.setDataFromFilter(filter)
      } else {
        this.selectedSmartView = null
        this.selectedFilter = {}
        if (!this.$route.params.id) this.addFilter()
      }
    },

    deleteSearchFilter(id) {
      this.$warn(
        {
          title: "Smartview löschen",
          desc: "Soll die Smartview wirklich gelöscht werden?",
          confirmButtonText: "Ja, Smartview löschen",
          redButton: true,
        },
        async () => {
          await this.$axios.delete(`/services/search_filters/${id}`)
          this.searchFilters = this.searchFilters.filter(o => o.id !== id)
          this.$db.setBroker({ searchFilters: this.$db.broker.searchFilters.filter(f => f.id != id) })
          App.flashy("Smartview erfolgreich gelöscht!")
        }
      )
    },

    smartViewSaved(obj) {
      this.smartViewFormVisible = false
      this.searchFilters = this.searchFilters.filter(o => o.id !== obj.id).concat([obj])
      this.$db.setBroker({ searchFilters: this.$db.broker.searchFilters.filter(o => o.id != obj.id).concat([obj]) })
      this.selectedSmartView = obj.id
    },

    prepareFilterSet(set) {
      if (!set) return
      let str = JSON.stringify(set)
      str = str.replace("{{ broker_id }}", `${this.$db.broker.id}`)
      return JSON.parse(str)
    },
  },

  computed: {
    smartViews() {
      return this.searchFilters.filter(o => !o.work_list)
    },

    defaultSmartViewFilter() {
      return this.smartViews.find(o => o.id === parseInt(this.defaultSmartView))
    },

    selectedSmartViewObject() {
      if (!this.selectedSmartView) return
      return this.smartViews.find(o => o.id == this.selectedSmartView)
    },

    validFilters() {
      return this.filterSet.map(o => formatToES(o, false)).filter(o => _.isArray(o) || o.key)
    },

    filterFieldNames() {
      return this.filterSet.map(o => o.fieldName)
    },

    filters() {
      if (this.selectedWorkList) {
        return JSON.parse(this.selectedWorkList.params).filter_set
      }

      return buildQuery(this.filterSet, this.$db)
    },
  },

  mounted() {
    if (this.selectedSmartViewObject && !this.filterSet.length) {
      this.setDataFromFilter(this.selectedSmartViewObject)
    } else if (this.filterSet.length === 0) {
      this.addFilter()
    }
  },
})
