<template>
  <spinner v-if="loading" class="w-100 h-100" />
  <div class="app-panel" v-else-if="leftGroups.length > 0">
    <slot
      name="header"
      :left-groups="leftGroups"
      :right-groups="rightGroups"
      :massCreateGroups="massCreateGroups"
      :massDeleteGroups="massDeleteGroups"
    />
    <form class="d-flex w-100 h-100">
      <section class="detailViewColumn">
        <h2 v-if="!singleColumnUse" class="detailViewColumn-title">
          {{ $t("detailViewGroups.groupsForm.leftGroup") }}
        </h2>
        <draggable class="boxes" :list="leftGroups" item-key="id" group="groups" @end="updateGroupPositions">
          <template #item="{ element: group }">
            <group
              :key="group.id"
              :form-fields="formFields"
              :available-form-fields="availableFormFields"
              :group="group"
              @update="updateLeftGroup"
              @removeGroup="removeGroup(group)"
            />
          </template>
        </draggable>
        <div class="slot-form" @submit.prevent>
          <nice-input
            v-model="leftGroupName"
            name="new_group"
            :placeholder="$t('detailViewGroups.groupsForm.newGroup')"
            @enter="addLeftGroup"
          />
        </div>
      </section>
      <section v-if="!singleColumnUse" class="detailViewColumn">
        <h2 class="detailViewColumn-title">{{ $t("detailViewGroups.groupsForm.rightGroup") }}</h2>
        <draggable class="boxes" :list="rightGroups" item-key="id" group="groups" @end="updateGroupPositions">
          <template #item="{ element: group }">
            <group
              :key="group.id"
              :form-fields="formFields"
              :available-form-fields="availableFormFields"
              :group="group"
              @update="updateRightGroup"
              @removeGroup="removeGroup(group)"
            />
          </template>
        </draggable>
        <div class="slot-form" @submit.prevent>
          <nice-input
            v-model="rightGroupName"
            name="new_group"
            :placeholder="$t('detailViewGroups.groupsForm.newGroup')"
            @enter="addRightGroup"
          />
        </div>
      </section>
    </form>
  </div>
  <div v-else class="w-100 h-100">
    <empty-state
      :title="emptyScreenTitle"
      :description="emptyScreenDescription"
      :button="emptyScreenTitle.replace(/ .*/, '') + $t('detailViewGroups.groupsForm.mask')"
      @click="massCreateGroups"
      :loading="massCreating"
    />
  </div>
</template>

<script lang="ts">
import { PropType } from "vue"
import Group from "@/components/detail-view-groups/Group.vue"
import EmptyState from "@/components/EmptyState.vue"
import { CustomFieldGroup, CustomField } from "@/interfaces/db"
import { DetailViewEntity, DetailViewGroup, DetailViewSlot } from "@/interfaces"
import { formatFields, resolveDetailViewField } from "@/config/detail-view-form"
import * as _ from "lodash"

interface Field {
  label: string
  type?: string
}

export default {
  props: {
    entity: String as PropType<DetailViewEntity>,
    parentFormFields: Object as PropType<Record<string, Field>>,
    defaultFields: Array as PropType<DetailViewGroup[]>,
    customFieldGroups: Array as PropType<CustomFieldGroup[]>,
    departmentId: {
      type: Number,
      required: false,
    },
    emptyScreenTitle: {
      default: "title",
      required: true,
    },
    emptyScreenDescription: {
      default: "",
      required: false,
    },
  },

  data() {
    return {
      leftGroups: [] as DetailViewGroup[],
      rightGroups: [] as DetailViewGroup[],
      rightGroupName: "",
      leftGroupName: "",
      loading: true,
      massCreating: false,
    }
  },

  components: {
    Group,
    EmptyState,
  },

  methods: {
    async fetchData() {
      const { shop } = await this.$graphql(`{
        shop {
          detailViewGroups(entity: ${this.entity}, department: ${this.departmentId || "null"}) {
            id name entity slot position splitIntoTwoColumns
            readBrokerIds writeBrokerIds readDepartmentIds writeDepartmentIds
            allowedMarketingType allowedRsTypes propertyStatusIds exposeType
            dealPipelineIds dealStageIds countries clientGroupIds
            translations { locale name }
            detailViewFields {
              id fieldName title hint position color mandatory exposeType
              readBrokerIds writeBrokerIds readDepartmentIds writeDepartmentIds
              allowedMarketingType allowedRsTypes containerFieldNames propertyStatusIds
              dealPipelineIds dealStageIds countries clientGroupIds
            }
          }
        }
      }`)

      this.leftGroups = shop.detailViewGroups.filter(g => g.slot == "left")
      this.rightGroups = shop.detailViewGroups.filter(g => g.slot == "right")
      this.loading = false
    },

    updateGroupPositions() {
      this.$api.mutation("sortDetailViewGroup", {
        leftGroups: this.leftGroups.map(g => g.id),
        rightGroups: this.rightGroups.map(g => g.id),
      })
    },

    addLeftGroup(e) {
      e.preventDefault()
      this.addGroup({
        slot: "left",
        entity: this.entity,
        name: this.leftGroupName,
        position: this.leftGroups.length,
      }).then(data => {
        this.leftGroups.push({
          slot: "left",
          id: data.id,
          entity: this.entity,
          name: this.leftGroupName,
          detailViewFields: [],
        })
        this.leftGroupName = ""
      })
    },

    addRightGroup(e) {
      e.preventDefault()
      this.addGroup({
        slot: "right",
        entity: this.entity,
        name: this.rightGroupName,
        position: this.rightGroups.length,
      }).then(data => {
        this.rightGroups.push({
          slot: "right",
          id: data.id,
          entity: this.entity,
          name: this.rightGroupName,
          detailViewFields: [],
        })
        this.rightGroupName = ""
      })
    },

    addGroup(payload: Partial<DetailViewGroup>): Promise<DetailViewGroup> {
      return this.$api.create("DetailViewGroup", { ...payload, departmentId: this.departmentId })
    },
    removeGroup(group) {
      if (group.detailViewFields.length > 0) {
        this.$confirm(
          this.$t("detailViewGroups.groupsForm.deleteModalBody") as string,
          this.$t("detailViewGroups.groupsForm.confirmDelete") as string
        )
          .then(_ => this.deleteGroup(group))
          .catch()
      } else {
        this.deleteGroup(group)
      }
    },
    deleteGroup(group: DetailViewGroup) {
      this.$api.destroy("DetailViewGroup", group.id!)
      if (group.slot == "left") {
        this.leftGroups = this.leftGroups.filter(g => g.id != group.id)
      } else {
        this.rightGroups = this.rightGroups.filter(g => g.id != group.id)
      }
    },
    updateRightGroup(group: DetailViewGroup) {
      const idx = this.rightGroups.indexOf(this.rightGroups.find(c => c.id === group.id)!)
      if (idx < 0) return
      Object.assign(this.rightGroups[idx], group)
    },
    updateLeftGroup(group: DetailViewGroup) {
      const idx = this.leftGroups.indexOf(this.leftGroups.find(c => c.id === group.id)!)
      if (idx < 0) return
      Object.assign(this.leftGroups[idx], group)
    },
    massCreateGroups() {
      this.massCreating = true
      const customFieldGroups: DetailViewGroup[] =
        this.entity == "for_exposee"
          ? []
          : this.customFieldGroups.map(g => ({
              name: g.name,
              slot: (this.entity == "for_saved_queries" ? "left" : "right") as DetailViewSlot,
              entity: this.entity,
              detailViewFields: formatFields(g.customFields.map(cf => `cf_${cf.name}`)),
            }))
      const defaultDetailViewGroups = this.defaultFields
        .map(o => ({ ...o, entity: this.entity } as DetailViewGroup))
        .concat(customFieldGroups)

      this.$api
        .mutation("massCreateDetailViewGroup", {
          attributes: defaultDetailViewGroups.map((o, idx) => ({
            ...o,
            position: idx + 1,
            detailViewFields: o.detailViewFields
              .filter(dvf => dvf.filter?.(this.$db.shopData) ?? true)
              .map((dvf, idx2) => ({
                ..._.omit(resolveDetailViewField(dvf), ["filter"]),
                position: idx2 + 1,
              })),
          })),
        })
        .then(_ => this.fetchData())
        .catch(this.$axios.handleError)
    },
    async massDeleteGroups() {
      try {
        await this.$confirm(
          this.$t("detailViewGroups.groupsForm.massDeleteModalBody") as string,
          this.$t("detailViewGroups.groupsForm.massDeleteModalTitle") as string,
          {
            confirmButtonText: this.$t("detailViewGroups.groupsForm.confirmMassDelete") as string,
            type: "warning",
          }
        ).then(() => (this.loading = true))
      } catch {
        return
      }
      this.massCreating = false
      this.$api
        .mutation("massDeleteDetailViewGroup", { entity: this.entity })
        .then(_ => this.fetchData())
        .catch(this.$axios.handleError)
    },
  },

  computed: {
    singleColumnUse(): boolean {
      return (["for_exposee", "for_saved_queries"] as DetailViewEntity[]).includes(this.entity)
    },
    customFields(): CustomField[] {
      return _.flatten(this.customFieldGroups.map(g => g.customFields))
    },
    fieldsAlreadyInUse(): string[] {
      const leftGroupsFieldNames = _.flatten(this.leftGroups.map(g => g.detailViewFields.map(f => f.fieldName))),
        rightGroupsFieldNames = _.flatten(this.rightGroups.map(g => g.detailViewFields.map(f => f.fieldName))),
        fieldNames = leftGroupsFieldNames.concat(rightGroupsFieldNames)

      return fieldNames
    },
    customFormFields(): Record<string, Field> {
      return this.customFields.reduce((acc, field) => {
        acc[`cf_${field.name}`] = {
          label: field.prettyName,
          type: field.fieldType,
        }
        return acc
      }, {})
    },
    formFields(): Record<string, Field> {
      if (this.singleColumnUse) {
        return _.merge(this.parentFormFields, this.customFormFields)
      } else {
        return _.merge(this.parentFormFields, this.customFormFields, { container: { label: "Container" } })
      }
    },
    availableFormFields(): Record<string, Field> {
      return _.omit(
        this.formFields,
        this.fieldsAlreadyInUse.filter(o => o != "container")
      )
    },
  },

  mounted() {
    this.fetchData()
  },
}
</script>

<style scoped>
.detailViewColumn {
  margin: 1rem;
  padding: 1rem;
  background: #f3f3f3;
  border-radius: 4px;
  width: 100%;
  overflow: auto;
}
.detailViewColumn-title {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-variant: small-caps;
}
</style>
