<template>
  <div class="pageHeader-button">
    <a :href="href" @click="$emit('click', $event)">
      <slot>
        <fa-icon v-if="icon" :name="icon" class="mr-1" />
        {{ title }}
      </slot>
    </a>
  </div>
</template>

<script>
export default {
  emits: ["click"],
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    href: {
      type: String,
      default: "#",
    },
  },
}
</script>

<style scoped>
.pageHeader-button {
  margin-left: 0.75rem;
}

.pageHeader-button > a {
  display: block;
  font-size: 14px;
  line-height: 1;
  color: #222;
  border-radius: 3px;
  padding: 7px 8px;
  transition: color 100ms linear;
}

.pageHeader-button > a.active,
.pageHeader-button > a:hover {
  background: rgba(0, 0, 0, 0.04);
}
</style>
