<template>
  <nice-dialog
    :title="title"
    :model-value="visible"
    @update:model-value="$emit('update:visible')"
    width="500px"
    append-to-body
  >
    <form-row>
      <span v-if="text">{{ text }}</span>
    </form-row>
    <form-row :title="label">
      <nice-select v-model="selected" :options="options" :placeholder="label" />
    </form-row>
    <template #footer>
      <nice-button type="primary" class="" @click="submit">
        {{ button || $t("selectPopover.title") }}
      </nice-button>
    </template>
  </nice-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    text: {
      type: String,
    },
    button: {
      type: String,
    },
  },
  data() {
    return { selected: null }
  },
  methods: {
    submit() {
      this.$emit("update:visible", false)
      this.$emit("submit", this.selected)
    },
  },
}
</script>
