<template>
  <form-dialog
    :visible="visible"
    :title="$t('project.projectReportSettings.title')"
    @close="$emit('update:visible', false)"
    :saving="submitting"
    @submit="submit"
    :autofocus="false"
    append-to-body
  >
    <form-section>
      <form-row
        :title="$t('project.projectReportSettings.showSelected')"
        :hint="$t('project.projectReportSettings.showSelectedHint')"
      >
        <nice-select v-model="data.activeCategories" :options="stats" :multiple="true" />
      </form-row>
      <form-row
        :title="$t('project.projectReportSettings.dateRange')"
        :hint="$t('project.projectReportSettings.dateRangeHint')"
      >
        <nice-date-picker fixed-position v-model="data.dateRange" format="dd.MM.yyyy" type="daterange" />
      </form-row>
      <form-row :title="$t('project.projectReportSettings.more')" :hint="$t('project.projectReportSettings.moreHint')">
        <nice-textarea v-model="data.additionalText" />
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script>
import { snakeCaseify, camelCaseify } from "@/utils/with-case"
import DayFilter from "@/components/dashboard/DayFilter"

export default {
  emits: ["update:visible"],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      submitting: false,
      data: camelCaseify(this.resource.reportSettings || {}),
      stats: [],
    }
  },
  components: {
    DayFilter,
  },
  methods: {
    async fetchConfig() {
      const {
        shop: { propertyReportStats },
      } = await this.$graphql(
        `query propertyReportStatsKvp { shop { propertyReportStats(entity: "for_projects") { id, name } } }`,
        undefined,
        true
      )
      this.stats = propertyReportStats
    },
    async submit() {
      this.submitting = true
      try {
        await this.$api.mutation("updateProject", {
          id: this.resource.id,
          attributes: {
            reportSettings: snakeCaseify(this.data),
          },
        })
        this.$emit("update:visible", false)
      } finally {
        this.submitting = false
      }
    },
  },
  mounted() {
    this.fetchConfig()
  },
}
</script>
