<template>
  <span class="pill"><slot /></span>
</template>

<style scoped>
.pill {
  display: inline-block;
  padding: 3px 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-bottom;
  background-color: transparent;
  border-radius: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid #bbbbbb;
  color: #888888;
}
</style>