<template>
  <div>
    <div class="mb-2">
      <nice-select
        filterable
        fixed-position
        v-model="ta.payload.snippetId"
        placeholder="Textbaustein auswählen"
        :options="shopData.snippets"
      ></nice-select>
    </div>
    <div class="mb-2">
      <input type="checkbox" v-model="ta.openForm" class="mr-1" :id="'ta_open_form_' + ta.id" />
      <label :for="'ta_open_form_' + ta.id">E-Mail-Formular im Anschluss öffnen</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shopData: {
      type: Object,
      required: true,
    },
    ta: {
      type: Object,
      required: true,
    },
  },
}
</script>
