<template>
  <main class="w-100 h-100 flex py-4 overflow-x-scroll" style="padding-left: 20px; padding-right: 20px">
    <kanban-column
      v-for="(column, idx) in columns"
      :key="`column-${idx}`"
      :column="column"
      :width="columnWidth"
      :initial-items="items.filter(o => o[columnKey] == column.id)"
      :infinite-scroll="ordering => infiniteScroll(column, ordering)"
      @item-moved="itemMoved($event, column)"
      @end="$emit('end', $event)"
    >
      <template v-slot:default="{ resource }">
        <slot :resource="resource" />
      </template>
      <template v-slot:header="{ column }">
        <slot name="columnHeader" :column="column" />
      </template>
    </kanban-column>
  </main>
</template>

<script>
import KanbanColumn from "./KanbanColumn"

export default {
  components: {
    KanbanColumn,
  },
  props: {
    initialColumns: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    columnKey: {
      type: String,
      required: true,
    },
    columnWidth: {
      type: String,
      default: "300px",
    },
    infiniteScroll: {
      type: Function,
      required: true,
      default: () => _ => {},
    },
  },
  watch: {
    initialColumns: {
      handler() {
        this.columns = this.$util.clone(this.initialColumns)
      },
      deep: true,
    },
  },
  data() {
    return {
      columns: this.$util.clone(this.initialColumns),
    }
  },
  methods: {
    itemMoved({ event, ordering }, column) {
      if (event.added) {
        this.$emit("item-moved", {
          item: event.added.element,
          newIndex: event.added.newIndex,
          newColumn: column,
          ordering,
        })
      } else if (event.moved) {
        this.$emit("item-moved", { item: event.moved.element, newIndex: event.moved.newIndex, ordering })
      }
    },
  },
}
</script>
