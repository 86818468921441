<template>
  <el-radio-group
    v-bind="$attrs"
    :model-value="model"
    :size="size"
    :disable="disabled"
    @update:model-value="$emit('update:modelValue', $event)"
    @change="$emit('change', $event)"
  >
    <slot>
      <nice-radio
        :button="button"
        :border="border"
        v-for="item in filteredOptions"
        :key="item[idKey]"
        :label="item[idKey]"
        :disabled="disabledButtons"
        :value="item[idKey]"
        :class="radioCssClass"
      >
        <fa-icon v-if="item.icon" :name="item.icon" />
        <span v-else-if="translateFor">{{ $t(`${translateFor}.${item[labelKey]}`) }}</span>
        <span v-else>{{ item[labelKey] }}</span>
      </nice-radio>
    </slot>
  </el-radio-group>
</template>

<script setup lang="ts">
import { computed } from "vue"

interface RadioGroupOption {
  id: string | number | null
  [key: string]: any
}

interface RadioGroupProps {
  multiple?: boolean
  disabled?: boolean
  disabledButtons?: boolean
  size?: "large" | "default" | "small"
  translateFor?: string
  labelKey?: string
  idKey?: string
  options?: Record<string, any> | RadioGroupOption[]
  button?: boolean
  border?: boolean
  radioCssClass?: string
}

const props = withDefaults(defineProps<RadioGroupProps>(), {
  multiple: false,
  disabled: false,
  disabledButtons: false,
  size: "default",
  translateFor: "",
  labelKey: "name",
  idKey: "id",
  button: true,
  border: false,
})

const emits = defineEmits(["change", "input", "update:modelValue"])

const model = defineModel<string | number | boolean | undefined>()

const filteredOptions = computed(() => {
  if (!props.options) return []
  if (!Array.isArray(props.options)) {
    return _.map(props.options, (label, key) => ({ id: key, [props.labelKey]: label }))
  }
  return props.options as RadioGroupOption[]
})
</script>

