<template>
  <div v-if="undraggable">
    <div v-for="(element, index) in actualList" :key="index">
      <slot name="item" v-bind="{ element, index }"></slot>
    </div>
  </div>
  <vuedraggable
    v-else
    v-bind="$attrs"
    @change="modelValue ? null : $emit('change', $event)"
    @end="$emit('end', $event)"
    :forceFallback="isSafari"
    :list="modelValue ? null : list"
    @update:modelValue="$emit('update:modelValue', $event)"
    :modelValue="modelValue"
    draggable=".draggable"
  >
    <template #item="{ element, index }">
      <div :data-id="element.id" class="draggable">
        <slot name="item" v-bind="{ element, index }"></slot>
      </div>
    </template>
  </vuedraggable>
</template>

<script>
import vuedraggable from "vuedraggable-es"
vuedraggable.compatConfig = { MODE: 3 } // works only with this line with vue/compat

const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") == -1 &&
  navigator.userAgent.indexOf("FxiOS") == -1

export default {
  components: { vuedraggable },
  props: {
    list: {
      default: null,
    },
    modelValue: {
      default: null,
    },
    undraggable: {
      default: false,
    },
  },
  computed: {
    actualList() {
      return this.modelValue || this.list
    },
    isSafari() {
      return isSafari
    },
  },
}
</script>
