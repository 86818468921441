<template>
  <div class="subnav d-flex justify-content-between">
    <nav class="d-flex">
      <router-link
        v-for="tab in tabs"
        :key="tab.name"
        :to="tab.url"
        class="header-navigationItem"
        :exact="tab.title == 'Details'"
        :disabled="tab.disabled"
        :data-tagid="tab.datatagid"
      >
        {{ tab.title }}
        <pill v-if="tab.badge" class="ml-2">{{ tab.badge }}</pill>
      </router-link>
    </nav>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import { NavigationTab } from "../interfaces"
import shortcuts from "@/utils/shortcuts"

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<NavigationTab[]>,
      required: true,
    },
  },
  mounted() {
    this.tabs.forEach((tab, index) => {
      shortcuts.bind("alt + " + (index + 1).toString(), "all", () => {
        if (tab.url !== this.$route.path) this.$router.push(tab.url)
      })
    })
  },
  beforeUnmount() {
    shortcuts.unbind(this.tabs.map((n, index) => `alt + ${index + 1}`).join(", "), "all")
  },
})
</script>

<style lang="scss" scoped>
.subnav {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #edf2f7;
  padding: 0 30px;
}
.subnav nav {
  margin: 0 -12px;
}
.subnav a {
  color: hsl(240, 6%, 68%);
  padding: 12px 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  margin: 0 2px;
  margin-bottom: -1px;
  border-bottom: 2px solid transparent;
  &:hover {
    color: var(--broker-color);
  }
  &.active,
  &.router-link-exact-active {
    // &.router-link-active {
    color: var(--broker-color);
    background-color: white;
    border-bottom-color: var(--broker-color);
  }
}

.subnav a:hover .pill,
.subnav a.active .pill {
  border-color: var(--broker-color);
  color: var(--broker-color);
}
</style>
