<template>
  <el-switch
    :model-value="modelValue"
    :size="size"
    :style="colorStyles[color]"
    @update:model-value="$emit('update:modelValue', $event)"
    @change="$emit('change', $event)"
    :width="width"
    :disabled="disabled || undefined"
    :active-text="activeText"
    :inactive-text="inactiveText"
  />
</template>
<script setup lang="ts">
export interface NiceSwitchProps {
  modelValue?: any
  disabled?: boolean
  width?: number
  size?: "small" | "default" | "large"
  activeText?: string
  inactiveText?: string
  color?: "success" | "default" | "warning" | "danger"
}

withDefaults(defineProps<NiceSwitchProps>(), {
  modelValue: undefined,
  disabled: false,
  size: "default",
  activeText: "",
  inactiveText: "",
  color: "default",
})

const emits = defineEmits(["change", "update:modelValue"])

const colorStyles = {
  success: "--el-switch-on-color: var(--ps-color-success)",
  warning: "--el-switch-on-color: var(--ps-color-warning)",
  danger: "--el-switch-on-color: var(--ps-color-danger)",
  default: "",
}
</script>
