<template>
  <div
    v-if="notification"
    class="topBar relative"
    :class="{
      'bg-red-700': notification.shop,
      'bg-green-600': notification.color == 'green',
      'bg-yellow-600': !notification.shop && notification.color !== 'green',
    }"
  >
    <fa-icon name="bolt" class="mr-1" />
    <span v-html="notification.text"></span>
    <fa-icon
      name="times"
      class="absolute right-0 top-0 bottom-0 px-2 pt-1 text-lg cursor-pointer leading-none"
      @click="close"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      notification: this.$db.announcement,
    }
  },
  computed: {
    closable() {
      return this.notification && this.notification.color == "green"
    },
  },
  methods: {
    close() {
      this.notification = null
      this.$api.mutation("hideAnnouncement")
    },
  },
}
</script>

<style>
.topBar {
  height: 20px;
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 20px;
}
.topBar ~ .main-container {
  height: calc(100% - 44px - 20px);
}
.topBar ~ .main-container .page-sidebar {
  margin-top: 20px;
}
.topBar ~ .quickview-wrapper {
  top: 64px;
}

.topBar a {
  color: inherit;
  text-decoration: underline;
}
</style>
