<template>
  <div>
    <p>Filter</p>
    <div class="flex">
      <button class="p-1 bg-gray-50" @click="toggleFilter(data => data.age > 30 || data.age < 25)">
        Filter Age > 30
      </button>
      <button class="p-1 bg-gray-50" @click="toggleFilter(data => data.name.first.includes('e'))">
        Has "e" in Name
      </button>
    </div>
    <ul>
      <li v-for="(filter, i) in filters" :key="i">
        <span>{{ filter }}</span>
        <button @click="toggleFilter(filter)">Remove</button>
      </li>
    </ul>
    <ul>
      <li v-for="(item, i) in filtered" :key="i">
        <pre>{{ item }}</pre>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

type Filter = (data: Record<string, any>) => boolean

export default defineComponent({
  data() {
    return {
      data: DATA,
      filters: [] as any[],
    }
  },
  methods: {
    toggleFilter(filterFunc: Filter) {
      const len = this.filters.length
      this.filters = this.filters.filter(f => f.toString() != filterFunc.toString())
      if (this.filters.length === len) this.filters.push(filterFunc)
    },
  },
  computed: {
    filtered(): any[] {
      return this.filters.reduce((data, filter) => {
        return data.filter(filter)
      }, this.data)
    },
  },
})

const CONFIG = [
  {
    label: "Aktiv",
    key: "active",
    type: Boolean,
  },
  {
    label: "Augenfarbe",
    key: "eyeColor",
    type: "Select",
    items: [
      {
        key: "green",
        label: "Grün",
      },
      {
        key: "brown",
        label: "Braun",
      },
      {
        key: "blue",
        label: "Blau",
      },
    ],
  },
  {
    label: "Alter",
    key: "age",
    type: Number,
  },
  {
    label: "Vorname",
    key: "name.first",
    type: String,
  },
]

const DATA = [
  {
    _id: "5fbba7d28ca6979e2e0500a7",
    index: 0,
    guid: "878fd1c0-6aa0-4f0b-b2f3-58c65e903267",
    isActive: true,
    balance: "$2,670.24",
    picture: "http://placehold.it/32x32",
    age: 31,
    eyeColor: "green",
    name: {
      first: "Iris",
      last: "Flores",
    },
  },
  {
    _id: "5fbba7d2b2e6fa5fd5da406b",
    index: 1,
    guid: "7e3cd6cf-aade-42e9-b212-b4f1333164d3",
    isActive: true,
    balance: "$1,302.54",
    picture: "http://placehold.it/32x32",
    age: 29,
    eyeColor: "brown",
    name: {
      first: "Laverne",
      last: "Barrett",
    },
  },
  {
    _id: "5fbba7d2c93817cb8ac048c6",
    index: 2,
    guid: "3395fe2c-6c86-4ecc-acf1-98e0042fdc9d",
    isActive: true,
    balance: "$1,590.23",
    picture: "http://placehold.it/32x32",
    age: 28,
    eyeColor: "green",
    name: {
      first: "Jackson",
      last: "Estes",
    },
  },
  {
    _id: "5fbba7d2d5cd5de5dff6093f",
    index: 3,
    guid: "8daec14d-0476-4c8c-acbb-e97e66d27e9b",
    isActive: true,
    balance: "$1,734.72",
    picture: "http://placehold.it/32x32",
    age: 24,
    eyeColor: "blue",
    name: {
      first: "Sloan",
      last: "Greer",
    },
  },
  {
    _id: "5fbba7d2b9ae9b4f289e3e78",
    index: 4,
    guid: "381a42e5-6d60-4718-a41c-b9a29e343e43",
    isActive: true,
    balance: "$3,895.30",
    picture: "http://placehold.it/32x32",
    age: 29,
    eyeColor: "green",
    name: {
      first: "Karla",
      last: "Dorsey",
    },
  },
  {
    _id: "5fbba7d2d2354bf750263388",
    index: 5,
    guid: "c912f081-66c2-4803-bfb6-2c9493ac9951",
    isActive: true,
    balance: "$2,136.49",
    picture: "http://placehold.it/32x32",
    age: 35,
    eyeColor: "green",
    name: {
      first: "Brewer",
      last: "Booker",
    },
  },
  {
    _id: "5fbba7d2c2fc56c35172a06f",
    index: 6,
    guid: "bd266539-42b5-490f-a452-c289267abb4c",
    isActive: true,
    balance: "$3,334.35",
    picture: "http://placehold.it/32x32",
    age: 37,
    eyeColor: "green",
    name: {
      first: "Sherrie",
      last: "Dotson",
    },
  },
  {
    _id: "5fbba7d2e9d9ac365bf35dea",
    index: 7,
    guid: "24286548-4de0-4d42-a2d1-317249321d75",
    isActive: true,
    balance: "$2,992.44",
    picture: "http://placehold.it/32x32",
    age: 26,
    eyeColor: "blue",
    name: {
      first: "Mathis",
      last: "Stout",
    },
  },
  {
    _id: "5fbba7d2b73aad967bd8bf5a",
    index: 8,
    guid: "4e3e3f1f-3bc4-4419-b270-89f2680a9305",
    isActive: false,
    balance: "$2,702.48",
    picture: "http://placehold.it/32x32",
    age: 34,
    eyeColor: "brown",
    name: {
      first: "Ophelia",
      last: "Rocha",
    },
  },
  {
    _id: "5fbba7d217731d43cab6a6d4",
    index: 9,
    guid: "582df0e5-e64e-4766-b37f-2475d044180f",
    isActive: true,
    balance: "$1,151.82",
    picture: "http://placehold.it/32x32",
    age: 32,
    eyeColor: "green",
    name: {
      first: "Shepherd",
      last: "Sparks",
    },
  },
  {
    _id: "5fbba7d2a09815a70279b6a9",
    index: 10,
    guid: "5821bdae-ea1c-4b2e-8b18-d763ced1e0d8",
    isActive: true,
    balance: "$3,471.86",
    picture: "http://placehold.it/32x32",
    age: 35,
    eyeColor: "green",
    name: {
      first: "Sharron",
      last: "Best",
    },
  },
  {
    _id: "5fbba7d2ad0943116d1f8815",
    index: 11,
    guid: "59f6f3aa-64b8-4021-a1fb-97f6c049d410",
    isActive: true,
    balance: "$1,343.36",
    picture: "http://placehold.it/32x32",
    age: 40,
    eyeColor: "brown",
    name: {
      first: "Davidson",
      last: "Buck",
    },
  },
  {
    _id: "5fbba7d26115bf9192a5beeb",
    index: 12,
    guid: "e33f621c-f105-4a19-8834-d6c513c42994",
    isActive: false,
    balance: "$2,000.39",
    picture: "http://placehold.it/32x32",
    age: 27,
    eyeColor: "blue",
    name: {
      first: "Cobb",
      last: "Hamilton",
    },
  },
  {
    _id: "5fbba7d2461070e906c8e7d9",
    index: 13,
    guid: "14076d02-5331-4e1e-afbf-6734195b59ad",
    isActive: false,
    balance: "$3,005.93",
    picture: "http://placehold.it/32x32",
    age: 36,
    eyeColor: "brown",
    name: {
      first: "Dolly",
      last: "Moore",
    },
  },
  {
    _id: "5fbba7d27aa73551d8c49e07",
    index: 14,
    guid: "33377d43-2155-4fad-9355-b57c47543b95",
    isActive: true,
    balance: "$3,971.89",
    picture: "http://placehold.it/32x32",
    age: 25,
    eyeColor: "blue",
    name: {
      first: "Inez",
      last: "Chaney",
    },
  },
  {
    _id: "5fbba7d2f5419e5898755bec",
    index: 15,
    guid: "6429cf43-ecd9-4760-82c4-5c6c250ccaaa",
    isActive: false,
    balance: "$3,140.10",
    picture: "http://placehold.it/32x32",
    age: 39,
    eyeColor: "brown",
    name: {
      first: "Harrell",
      last: "Bowman",
    },
  },
  {
    _id: "5fbba7d278bcb3c94345f93b",
    index: 16,
    guid: "2bc7a47a-02d4-4d84-ac36-2d3f2ee92f07",
    isActive: true,
    balance: "$1,768.66",
    picture: "http://placehold.it/32x32",
    age: 25,
    eyeColor: "brown",
    name: {
      first: "Sue",
      last: "Tyler",
    },
  },
  {
    _id: "5fbba7d22311c322c776f836",
    index: 17,
    guid: "54d735f6-0fe0-4341-8653-701403f1eb9f",
    isActive: true,
    balance: "$1,438.81",
    picture: "http://placehold.it/32x32",
    age: 37,
    eyeColor: "brown",
    name: {
      first: "Herring",
      last: "Hale",
    },
  },
  {
    _id: "5fbba7d282dc23c2c5e4a5d3",
    index: 18,
    guid: "0a903e06-2565-482c-9399-fd77df32c7dc",
    isActive: false,
    balance: "$3,006.40",
    picture: "http://placehold.it/32x32",
    age: 22,
    eyeColor: "blue",
    name: {
      first: "Sybil",
      last: "Harmon",
    },
  },
  {
    _id: "5fbba7d236116878b5816d8a",
    index: 19,
    guid: "32217ebd-ff92-4eb3-8991-1c06694c6474",
    isActive: true,
    balance: "$3,653.28",
    picture: "http://placehold.it/32x32",
    age: 38,
    eyeColor: "green",
    name: {
      first: "Acosta",
      last: "Lindsay",
    },
  },
  {
    _id: "5fbba7d23c3c567ef9deb033",
    index: 20,
    guid: "2397c175-ae62-4d1a-9779-4818c29c8e07",
    isActive: true,
    balance: "$3,647.78",
    picture: "http://placehold.it/32x32",
    age: 25,
    eyeColor: "brown",
    name: {
      first: "Jami",
      last: "Green",
    },
  },
  {
    _id: "5fbba7d20eccc0060b4f435a",
    index: 21,
    guid: "c994d527-9d98-4ff8-bd6e-0cd3efa58e2e",
    isActive: true,
    balance: "$3,632.11",
    picture: "http://placehold.it/32x32",
    age: 25,
    eyeColor: "green",
    name: {
      first: "Flynn",
      last: "Sykes",
    },
  },
  {
    _id: "5fbba7d298164d7e2b01e6dd",
    index: 22,
    guid: "3ddaa359-307e-44f9-9030-e493454d6e57",
    isActive: true,
    balance: "$3,886.14",
    picture: "http://placehold.it/32x32",
    age: 33,
    eyeColor: "blue",
    name: {
      first: "Nichols",
      last: "Hayden",
    },
  },
  {
    _id: "5fbba7d22733b0de52e337c4",
    index: 23,
    guid: "99f23e33-eb6f-4e63-a806-344dfa454ffa",
    isActive: true,
    balance: "$2,867.84",
    picture: "http://placehold.it/32x32",
    age: 28,
    eyeColor: "blue",
    name: {
      first: "Wilkins",
      last: "Clemons",
    },
  },
  {
    _id: "5fbba7d25f3f93254a74b5b8",
    index: 24,
    guid: "22d0624e-2a68-4213-8a4c-0879f975c6ad",
    isActive: true,
    balance: "$1,478.54",
    picture: "http://placehold.it/32x32",
    age: 25,
    eyeColor: "blue",
    name: {
      first: "Lenore",
      last: "Smith",
    },
  },
  {
    _id: "5fbba7d25f6c32c094706b39",
    index: 25,
    guid: "72a9068b-a3c9-4d32-9da3-ceafd09b1790",
    isActive: false,
    balance: "$2,468.20",
    picture: "http://placehold.it/32x32",
    age: 33,
    eyeColor: "blue",
    name: {
      first: "Carey",
      last: "Suarez",
    },
  },
  {
    _id: "5fbba7d22635b0df604e4358",
    index: 26,
    guid: "b688a457-5ed8-4c78-b59d-3b8795dc4383",
    isActive: true,
    balance: "$3,271.10",
    picture: "http://placehold.it/32x32",
    age: 34,
    eyeColor: "brown",
    name: {
      first: "Lester",
      last: "Brown",
    },
  },
  {
    _id: "5fbba7d285aff64644c30299",
    index: 27,
    guid: "239fbe53-6042-4c79-910d-79b1acd4913c",
    isActive: true,
    balance: "$3,398.99",
    picture: "http://placehold.it/32x32",
    age: 39,
    eyeColor: "blue",
    name: {
      first: "Clements",
      last: "Walton",
    },
  },
  {
    _id: "5fbba7d23e9b495a409cbe6b",
    index: 28,
    guid: "c4cc579c-37f3-4b3b-afdb-a8845cfb38e7",
    isActive: true,
    balance: "$3,115.14",
    picture: "http://placehold.it/32x32",
    age: 21,
    eyeColor: "blue",
    name: {
      first: "Violet",
      last: "Robertson",
    },
  },
  {
    _id: "5fbba7d24b4f46bf4a1b0345",
    index: 29,
    guid: "76805fa3-51e1-4252-9c6d-7129d651df0e",
    isActive: true,
    balance: "$3,313.18",
    picture: "http://placehold.it/32x32",
    age: 23,
    eyeColor: "green",
    name: {
      first: "Salazar",
      last: "Quinn",
    },
  },
]
</script>