<template>
  <tooltip :content="tooltip" :disabled="$slots.tooltip ? false : !tooltip">
    <template v-slot:content>
      <slot name="tooltip"></slot>
    </template>
    <span @click="clicked" class="bubble" :style="{ 'background-color': background }" :class="{ pointer: url }">
      <slot></slot>
    </span>
  </tooltip>
</template>

<script>
const COLORS = {
  light: "#999999",
  success: "#22dd33",
  warning: "#cc7700",
  danger: "#ff0000",
  info: "#3388ff",
  dark: "#999999",
}

export default {
  emits: ["click"],
  props: {
    color: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    type: {
      type: String,
    },
    url: {
      type: String,
    },
  },
  methods: {
    clicked() {
      this.$emit("click")
      if (this.url) {
        Turbolinks.visit(this.url)
      }
    },
  },
  computed: {
    background() {
      if (this.color) {
        return `${this.color}44`
      } else if (COLORS[this.type]) {
        return `${COLORS[this.type]}44`
      } else {
        return `#55555544`
      }
    },
  },
}
</script>

<style scoped>
.bubble {
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  color: #222222;
  font-size: 0.85rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
