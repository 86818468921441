import { toPropRefs } from "@/utils/composables"
import { MaybeRef, get } from "@vueuse/core"
import { computed } from "vue"
import { useIntegrationOrderDeleteMutation } from "./mutations/use-integration-order-delete-mutation"
import { useIntegrationOrderDispatchMutation } from "./mutations/use-integration-order-dispatch-mutation"
import { useIntegrationCurrentOrderByEntityIdQuery } from "./queries/use-integration-current-order-by-entity-id-query"
import { IntegrationOrder, IntegrationOrderCreateData } from "./types/integration-order-service"
import { IntegrationId } from "./types/integration-service"
import { useIntegration } from "./use-integration"
import { useNotifications } from "./use-notifications"
import { useTracking } from "./use-tracking"
import { createIntegrationOrderStepDescriptors } from "./utils/create-integration-order-step-descriptors"
import { useI18n } from "vue-i18n"

// this is the main hook for the integration orders, it works with the integrationId and the entityId
export const useIntegrationOrdersForEntityAPI = (
  integrationId: MaybeRef<IntegrationId>,
  entityId: MaybeRef<string>
) => {
  const { data: currentOrder = null, isPending: isLoadingOrder } = useIntegrationCurrentOrderByEntityIdQuery(
    integrationId,
    entityId
  )
  const { orderSteps, isPending: isLoadingIntegration } = useIntegration(integrationId)
  const { t } = useI18n()

  const { allStepConfigs, maxVisibleSteps } = toPropRefs(
    () => createIntegrationOrderStepDescriptors(get(integrationId), get(orderSteps), t),
    ["allStepConfigs", "maxVisibleSteps"]
  )

  const { trackEvent } = useTracking()
  const { openSuccess, openError } = useNotifications()

  const dispatchIntegrationOrderMutation = useIntegrationOrderDispatchMutation(integrationId)
  const deleteIntegrationOrderMutation = useIntegrationOrderDeleteMutation(integrationId)

  const isLoading = computed(() => get(isLoadingOrder) || get(isLoadingIntegration))

  const isDispatching = dispatchIntegrationOrderMutation.isPending
  const isDeleting = deleteIntegrationOrderMutation.isPending

  const dispatchOrder = async (order: IntegrationOrderCreateData) => {
    trackEvent({
      feature: "integrations",
      action: "order_integration",
      metadata: { integration: integrationId, ...order },
    })

    return await dispatchIntegrationOrderMutation.mutateAsync(
      { order },
      {
        onSuccess: () => {
          openSuccess(t("integrations.orders.messages.dispatch.success"))
        },
        onError: () => {
          openError(t("integrations.orders.messages.dispatch.error"))
        },
      }
    )
  }

  const deleteOrder = async (order: IntegrationOrder) => {
    trackEvent({
      feature: "integrations",
      action: "delete_order",
      metadata: { integration: get(integrationId), order },
    })

    return await deleteIntegrationOrderMutation.mutateAsync(
      { order },
      {
        onSuccess: () => {
          openSuccess(t("integrations.orders.messages.delete.success"))
        },
        onError: () => {
          openError(t("integrations.orders.messages.delete.error"))
        },
      }
    )
  }

  const currentOrderStep = computed(() => {
    const _currentOrder = get(currentOrder)
    return _currentOrder?.step ? get(allStepConfigs)?.find(step => step.id === _currentOrder.step) : undefined
  })

  return {
    isLoading,
    isDispatching,
    isDeleting,
    currentOrder,
    dispatchOrder,
    deleteOrder,
    orderSteps,
    allStepConfigs,
    currentOrderStep,
    maxVisibleSteps,
  }
}
