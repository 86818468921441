import addHeader from "@/utils/add-header"

export let scripts: Record<string, HTMLElement> = {}

const useHeaderScripts = () => {
  const registerScript = (name: string, script: Record<string, any>) => {
    scripts[name] = addHeader("script", script)
  }

  const unregisterScript = (name: string) => {
    const script = scripts[name]
    if (!script) return
    script.remove()
    delete scripts[name]
  }

  const unregisterAllScripts = () => {
    Object.values(scripts).forEach(h => h.remove())
    scripts = {}
  }

  return {
    registerScript,
    unregisterScript,
    unregisterAllScripts,
    scripts,
  }
}

export default useHeaderScripts
