<template>
  <div>
    <div v-if="unit.floorplan_urls && unit.floorplan_urls.length > 0">
      <a :href="'/portfolio/properties/' + unit.id + '/floorplan'" target="_blank">Grundriss</a>
    </div>
    <nice-upload
      v-else
      :action="'/api/v1/floorplans?property_id=' + unit.id"
      :multiple="false"
      :with-credentials="true"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-success="onSuccess"
      accept="image/*,application/pdf"
    >
      <template #trigger>
        <a v-if="!loading" href="#" @click.prevent>
          <fa-icon name="plus" />
        </a>
        <span v-if="loading" class="spinner"></span>
      </template>
    </nice-upload>
  </div>
</template>

<script>
export default {
  props: ["unit"],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    beforeUpload(file) {
      this.loading = true
      return true
    },
    onSuccess(response, file, fileList) {
      this.loading = false
      return (this.unit.floorplan_urls = this.unit.floorplan_urls.concat([response.url]))
    },
  },
}
</script>
