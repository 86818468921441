<script setup lang="ts">
import { computed } from "vue"
import { Integration, IntegrationMcGrundriss } from "@/integrations/types/integration-service"
import { IntegrationMapping } from "@/integrations/types/integration-page"
import McGrundrissWidgetTile from "./McGrundrissWidgetTile.vue"
import IframeModal from "@/components/IframeModal.vue"
import { ref } from "vue"
import { get, set, toRef, toRefs } from "@vueuse/core"
import { useIntegrationOrdersForEntityAPI } from "@/integrations/use-integration-orders-for-entities-api"
import McGrundrissImage from "./McGrundrissImage.vue"
import useCore from "@/plugins/use-core"
import { SchemaNames } from "@/integrations/types/cloudios-api"
import { Property, Image } from "@/integrations/types/propstack"

const props = defineProps<{
  integration: Integration
  mapping: IntegrationMapping<IntegrationMcGrundriss>
  entity: Property
  images: Image[]
  locale: string
}>()

const { integration, mapping, entity, images, locale } = toRefs(props)
const entityId = toRef(() => props.entity.id.toString())
const integrationId = toRef(() => props.integration.id)
const { currentOrder, dispatchOrder, isDispatching, deleteOrder, currentOrderStep, maxVisibleSteps, isLoading } =
  useIntegrationOrdersForEntityAPI(integrationId, entityId)
const { graphql } = useCore()
const floorPlans = computed(() => props.images.filter(image => image.is_floorplan))
const configureVisible = ref(false)
const iframeVisible = ref(false)

const selectedFloorPlans = ref<number[]>([])

const isActiveOrder = computed(() => !!get(currentOrder))

const toggleSelectedFloorPlans = (id: number, value: boolean) => {
  const _selectedFloorPlans = get(selectedFloorPlans)
  const filtered = _selectedFloorPlans.filter(imageId => imageId !== id)
  if (value) filtered.push(id)
  set(selectedFloorPlans, filtered)
}

const mapFloorPlan = (image: Image) => ({
  url: image.photo_url,
  title: (image[`title_${props.locale}`] ||
    decodeURI(image.photo_url.split("/").slice(-1)[0]?.split(".")?.[0] ?? "")) as string,
})

const fetchOrderInformation = async () => {
  const { shop, broker } = await graphql(`
    query GetOrderData {
      shop {
        name
        street
        houseNumber
        zipCode
        city
        vatId
        logoUrl
        email
        ceo
      }
      broker {
        firstName
        lastName
        email
        phone
        salutation
      }
    }
  `)
  return { shop, broker }
}

const createOrder = async () => {
  const { shop, broker } = await fetchOrderInformation()
  const _selectedFloorPlans = get(selectedFloorPlans)
  await dispatchOrder({
    schemaName: SchemaNames.ESTATES,
    entityId: get(entityId),
    floorPlans: get(floorPlans)
      .filter(floorPlan => _selectedFloorPlans.includes(floorPlan.id))
      .map(mapFloorPlan),
    companyInformation: {
      companyName: shop.name,
      companyResponsiblePersons: shop.ceo || "",
      companyStreet: shop.street || "",
      companyPostcode: shop.houseNumber || "",
      companyCity: shop.city || "",
      companyUstId: shop.vat || "",
      companyLogoURL: shop.logoUrl,
      companyEmail: shop.email || "",
    },
    userInformation: {
      userFirstName: broker.firstName || "",
      userLastName: broker.lastName,
      userEmail: broker.email,
      userPhone: broker.phone || "",
      userSalutation: broker.salutation || "",
    },
    estateHeadline: props.entity.name || "",
  })

  setTimeout(() => {
    set(iframeVisible, true)
    set(configureVisible, false)
  }, 300)
}

const removeOrder = async () => {
  const _currentOrder = get(currentOrder)
  if (!_currentOrder) return
  await deleteOrder(_currentOrder)
}
</script>
<template>
  <div>
    <form-dialog
      :title="$t('integrations.integration-mcgrundriss.order.title')"
      :visible="configureVisible"
      @close="configureVisible = false"
      :submit-button-title="$t('integrations.actions.orderNow')"
      :disabled="!selectedFloorPlans.length"
      :saving="isDispatching"
      :submit-func="createOrder"
      :handle-error="false"
    >
      <div>
        <div class="flex">
          <div class="mr-4 font-bold w-56">
            {{ $t("integrations.integration-mcgrundriss.order.instructions.caption") }}
          </div>
          <div
            class="list-decimal break-normal whitespace-pre-wrap inline-block"
            v-html="$t('integrations.integration-mcgrundriss.order.instructions.steps')"
          />
        </div>
        <div class="font-bold text-lg">
          {{ $t("integrations.integration-mcgrundriss.order.instructions.category") }} ({{ selectedFloorPlans.length }})
        </div>
        <div class="w-100 min-h-48 h-fit border-dashed border-2 border-gray-200 rounded-md pl-3 pr-3 pb-3 inline-block">
          <mc-grundriss-image
            :value="selectedFloorPlans.includes(image.id)"
            @selected="value => toggleSelectedFloorPlans(image.id, value)"
            :image="image"
            :locale="props.locale"
            v-for="image in floorPlans"
            :key="image.id"
          />
          <span class="text-gray-400" v-if="!floorPlans.length">
            {{ $t("integrations.integration-mcgrundriss.order.tooltip.imageRequired") }}
          </span>
        </div>
      </div>
    </form-dialog>
    <mc-grundriss-widget-tile
      :integration="integration"
      :mapping="mapping"
      :is-enabled="true"
      :current-order="currentOrder"
      :current-order-step="currentOrderStep"
      :floor-plans="floorPlans"
      :max-visible-order-steps="maxVisibleSteps"
      @configure="configureVisible = true"
      @delete="removeOrder"
      @goto="iframeVisible = true"
      v-if="!isLoading"
    />
    <iframe-modal
      :title="$t('integrations.integration-mcgrundriss.name')"
      v-model:visible="iframeVisible"
      :link="currentOrder.context.linkOut"
      v-if="isActiveOrder"
    />
  </div>
</template>
