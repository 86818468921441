<template>
  <div
    class="px-3 py-2 rounded flex border"
    :class="{
      'bg-green-100 text-green-700 border-green-900/10': type == 'success',
      'bg-red-100 text-red-700 border-red-900/10': type == 'danger',
      'bg-yellow-100 text-yellow-700 border-yellow-900/10': type == 'warning',
      'bg-blue-100 text-blue-700 border-blue-900/10': type == 'info',
      'bg-gray-100 text-gray-700 border-gray-900/10': type == null,
    }"
  >
    <fa-icon v-if="iconName" :name="iconName" class="mr-3 text-xl" />
    <div class="self-center text-sm">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"

export default defineComponent({
  props: {
    type: {
      type: String as PropType<"success" | "danger" | "warning" | "info" | null>,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  computed: {
    iconName() {
      if (this.type == "danger") return "exclamation-triangle"
      // if (this.type == "warning") return "info"
      if (this.type == "success") return "check"
      if (this.type == "info") return "info-circle"
    },
  },
})
</script>
