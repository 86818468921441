<template>
  <nice-select v-bind="$attrs" :options="options" />
</template>

<script>
export default {
  computed: {
    options() {
      const array = [{ id: "me", name: "Mit mir geteilt" }]
      if (this.$db.broker.availableInboxes.length)
        array.push(...this.$db.broker.availableInboxes[0].smartFolders.map(sf => ({ ...sf, id: `sf-${sf.id}` })))
      return array
    },
  },
}
</script>
