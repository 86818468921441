<template>
  <div class="empty-wrapper">
    <div class="empty-content">
      <img v-if="imageName" :src="'/onboarding/' + imageName" class="empty-content-icon" />
      <h5 class="empty-content-header">
        {{ title }}
      </h5>

      <p v-html="description" v-if="description" class="empty-content-desc text-center"></p>
      <p v-if="moreInfoLink" class="empty-content-desc text-center">
        <a :href="moreInfoLink" target="_blank" class="text-gray-600 text-underline">
          {{ $t(moreInfoText) }}
        </a>
      </p>
      <nice-button v-if="button" type="primary" :loading="loading" class="mt-3 w-80 text-lg" @click="$emit('click')">
        {{ button }}
      </nice-button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["click"],
  props: {
    imageName: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    moreInfoLink: {
      type: String,
      required: false,
    },
    moreInfoText: {
      type: String,
      default: "admin.emptyState.moreInfo",
    },
    button: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
