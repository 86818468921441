<template>
  <nice-dialog
    :model-value="modalVisible"
    @update:model-value="$emit('update:modalVisible', $event)"
    :title="$t('properties.xml.title')"
    width="400px"
  >
    <div>
      <div class="fluid-item">
        <div class="fluid">
          <form ref="xmlForm" method="POST" action="/portfolio/import" enctype="multipart/form-data">
            <input
              ref="fileUploadField"
              type="file"
              name="file"
              accept=".xml"
              style="display: none"
              @change="$refs.xmlForm.submit()"
            />
            <input type="hidden" name="url" v-model="url" style="display: none" />
            <form-row :title="$t('properties.xml.source')" :hint="$t('properties.xml.hint')">
              <nice-radio-group
                v-model="type"
                class="whitespace-nowrap width-100"
                :options="[
                  { id: 'file', name: $t('properties.xml.file') },
                  { id: 'url', name: $t('properties.xml.url') },
                ]"
              />
            </form-row>
            <form-row :title="$t('properties.xml.url')" v-if="type === 'url'">
              <nice-input size="small" v-model="url" placeholder="https://example.com/feed.xml" />
            </form-row>
            <form-row>
              <button class="btn w-100 h-12 -mb-3" @click.prevent="uploadFile">
                {{ $t("properties.xml.submit") }}
              </button>
            </form-row>
          </form>
        </div>
      </div>
    </div>
  </nice-dialog>
</template>

<script>
export default {
  props: ["modalVisible"],
  emits: ["update:modalVisible"],
  data() {
    return {
      type: "file",
      url: "",
    }
  },
  methods: {
    uploadFile() {
      if (this.type === "file") {
        this.$refs.fileUploadField.click()
      } else if (this.type === "url" && this.url) {
        this.$refs.xmlForm.submit()
      }
    },
  },
}
</script>
