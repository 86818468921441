<template>
  <nice-select
    grouped
    ref="select"
    v-bind="$attrs"
    :allow-create="allowCreate"
    :clearable="clearable"
    :options="groupedOptions"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    @change="$emit('change', $event)"
    @blur="$emit('blur', $event)"
    @visible-change="$emit('visible-change', $event)"
  />
</template>

<script>
import { getVariables } from "@/utils/get-variables"

let cache

export default {
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    excludeButtons: {
      type: Boolean,
      default: true,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      options: [],
    }
  },
  emits: ["input", "change", "blur", "visible-change", "update:modelValue"],
  expose: ["focus"],
  methods: {
    async fetchData() {
      const { shop } = await this.$graphql(`query variables {
        shop {
          folders { id name sendable }
          bookingCalendars { id name }
        }
      }`)

      this.options = getVariables(this.$db.shopData, {
        folders: shop.folders.filter(f => f.sendable),
        bookingCalendars: shop.bookingCalendars,
      }).filter(group => {
        if (this.group) return group[0] == this.group
        if (this.excludeButtons) return !["Landing Page", "Funktionen", "Docusign"].includes(group[0])

        return true
      })
      cache = this.options
    },

    focus() {
      this.$refs.select.focus()
    },
  },
  computed: {
    groupedOptions() {
      return this.options.map(group => ({
        id: group[0],
        name: this.$t(`variableSelect.titles.${group[0]}`),
        children: group[1].map(option => ({
          id: option[1],
          name: option[2] == "custom" ? option[0] : this.$t(`variableSelect.names.${option[0]}`),
        })),
      }))
    },
  },
  mounted() {
    if (cache) {
      this.options = cache
    } else {
      this.fetchData()
    }
  },
}
</script>
