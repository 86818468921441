<template>
  <nice-popover placement="bottom" width="300" trigger="click" transition="none">
    <template #reference>
      <button :class="['tableActions-action', $attrs.class]" @click="$emit('click', $event)">
        <template v-if="showCurrentSorting">
          <fa-icon name="sort" class="tableActions-actionIcon" />
          {{ $t("sortButton") }}: {{ currentOption }}
          <template v-if="!noSorting">
            <fa-icon v-if="order === 'asc'" name="sort-alpha-down" />
            <fa-icon v-if="order === 'desc'" name="sort-alpha-up" />
          </template>
        </template>
        <template v-else>
          <fa-icon name="sort" class="tableActions-actionIcon" />
          {{ $t("sortButton") }}
        </template>
      </button>
    </template>
    <div class="el-popover-inner">
      <div class="searchFilter">
        <div class="fluid-item">
          <div class="fluid">
            <div class="fake-select">
              <select :value="sortBy" class="form-control" @change="$emit('update:sort-by', $event.target.value)">
                <option v-for="option in sortedOptions" :key="option.value" :value="option.value">
                  {{ $db.getItemFieldTitle(option.fieldName || option.value, optionsType) || option.label }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="!noSorting">
            <nice-radio-group
              :model-value="order"
              @update:model-value="$emit('update:order', $event)"
              :options="[
                { id: 'asc', icon: 'sort-alpha-down' },
                { id: 'desc', icon: 'sort-alpha-up' },
              ]"
            />
          </div>
        </div>
        <div class="text-yellow-700 text-xs ml-1 mt-1">{{ message }}</div>
      </div>
    </div>
  </nice-popover>
</template>

<script>
export default {
  emits: ["update:sort-by", "update:order", "click"],
  props: {
    sortBy: {
      type: String,
    },
    order: {
      type: String,
    },
    showCurrentSorting: {
      default: false,
      type: Boolean,
    },
    sortOptions: {
      type: Array,
      required: true,
    },
    optionsType: {
      type: String,
    },
    message: {
      default: "",
      type: String,
    },
  },
  computed: {
    sortedOptions() {
      return this.sortOptions.sort((a, b) => (a.label > b.label ? 1 : -1))
    },
    noSorting() {
      return this.sortBy === "*STANDARD*"
    },
    currentOption() {
      const option = this.sortedOptions.find(({ value }) => value === this.sortBy)
      return this.$db.getItemFieldTitle(option?.fieldName || option?.value, this.optionsType) || option?.label
    },
  },
}
</script>
