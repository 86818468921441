<template>
  <header class="content-header">
    <span class="app-pane-header-title left">
      <slot>{{ title }}</slot>
    </span>
    <a href="#" class="app-pane-header-button right" :data-submit="target" data-tagid="propertyCreate">
      {{ $t(submitTitle) }}
    </a>
  </header>
</template>

<script>
export default {
  props: {
    title: String,
    target: String,
    submitTitle: {
      type: String,
      default: "formDialog.submit",
    },
  },
}
</script>
