<template>
  <div>
    <bubble
      v-for="cp in unit.clients"
      :key="cp.id"
      :tooltip="cp.client_name + ': ' + cp.date_formatted + ' – ' + cp.dealStage.name + ' (' + cp.broker_name + ')'"
      :color="cp.dealStage.color"
      :url="'/contacts/clients/' + cp.client_id + '/conversation'"
      class="mr-1"
    >
      {{ $customFilters.initials(cp.client_name) }}
    </bubble>

    <bubble
      v-if="unit.reserved_client_properties_count > 3"
      :url="'/portfolio/properties/' + unit.id + '/active_clients'"
    >
      <small>+{{ unit.reserved_client_properties_count - 3 }}</small>
    </bubble>

    <bubble @click="$emit('open')" class="pointer" color="#aaaaaa">
      <fa-icon name="plus" />
    </bubble>
  </div>
</template>

<script>
export default {
  emits: ["open"],
  props: ["unit"],

  data() {
    return {}
  },

  methods: {},
}
</script>
