<template>
  <div>
    <slot v-bind="{ calculate }"></slot>
    <span class="text-sm">
      {{ $t("automations.chronicEvaluation", { calculation: calculated }) }}
    </span>
  </div>
</template>

<script>
export default {
  expose: ["calculate"],
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      calculated: null,
    }
  },
  methods: {
    async calculate() {
      if (!this.value) return (this.calculated = null)

      const { chronicDate } = await this.$graphql(`query chronicDate{
        chronicDate(prompt: "${this.value}")
      }`)

      this.calculated = this.$customFilters.datetime(chronicDate)
    },
  },
}
</script>
