<template>
  <component
    v-bind="$attrs"
    :is="componentTag"
    class="tableActions-action inline-flex items-center"
    :class="{ 'tableActions-action-active': active, disabled: isDisabled, 'tableActions--small': size == 'small' }"
    :disabled="isDisabled"
    @click="click"
  >
    <slot>
      <span v-if="loading" class="spinner tableActions-actionIcon"></span>
      <fa-icon v-else-if="icon" :name="icon" class="tableActions-actionIcon" />
      {{ title }}
    </slot>
  </component>
</template>

<script>
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"

export default {
  emits: ["click"],
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    active: {
      type: Boolean,
    },
    visit: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ajax: {
      type: Boolean,
      default: false,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "normal",
    },
    componentTag: {
      type: String,
      default: "button",
    },
  },

  computed: {
    isDisabled() {
      return coerceBooleanAttribute(this.disabled)
    },
  },

  methods: {
    click(e) {
      if (this.visit && this.newTab) {
        e.stopPropagation()
        window.open(this.visit)
      } else if (this.visit) {
        this.$router.push(this.visit)
      } else {
        this.$emit("click", e)
      }
    },
  },
}
</script>

<style>
.tableActions--small {
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: 22px;
  font-size: 0.85rem;
}
</style>
