<template>
  <span>
    <slot :open="open" />
    <form-dialog
      width="560px"
      footer-type="bigButton"
      :visible="visible"
      @close="visible = false"
      :title="$t('letterGenerator.title')"
      append-to-body
      :submit-func="submit"
      @complete="complete"
      :submitButtonTitle="$t('letterGenerator.form.submit')"
      class="hide-header"
      :closeOnClickModal="true"
      :disabled="!letterTemplateId || !clientId"
    >
      <div v-if="!clientFetched" class="py-8">
        <spinner />
      </div>
      <p v-else-if="!clientId" class="py-8 text-center text-red-600">
        {{ $t("letterGenerator.form.clientNotFound", { email: clientEmail }) }}
      </p>
      <div v-else>
        <form-section>
          <p v-html="$t('letterGenerator.form.intro')" class="mb-3"></p>
          <nice-radio-group
            v-model="type"
            size="small"
            class="mb-3"
            :options="[
              { id: 'word', name: 'Word' },
              { id: 'pdf', name: 'PDF' },
            ]"
          />
          <form-row direction="column" :title="$t('letterGenerator.form.letterTemplate.label')">
            <db-radio-list
              v-model="letterTemplateId"
              collection="letterTemplates"
              :filter-func="template => !template.pdfOutput || type === 'pdf'"
            />
          </form-row>
        </form-section>
      </div>
    </form-dialog>
  </span>
</template>

<script>
import DbRadioList from "@/components/DbRadioList.vue"
export default {
  components: { DbRadioList },
  props: {
    clientEmail: {
      type: String,
      required: true,
    },
    propertyId: {
      type: Number,
      required: false,
    },
    projectId: {
      type: Number,
      required: false,
    },
    teamId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      visible: false,
      letterTemplateId: null,
      type: "word",
      clientId: null,
      clientFetched: false,
    }
  },
  methods: {
    open() {
      this.visible = true
      this.clientFetched = false
      this.fetchClient()
    },
    fetchClient() {
      this.$graphql(`{ client(email: "${this.clientEmail}") { id } }`)
        .then(({ client }) => {
          this.clientId = client.id
        })
        .catch(error => {
          console.debug(error)
        })
        .finally(() => {
          this.clientFetched = true
        })
    },
    submit() {
      return this.$api.mutation(
        "generateLetter",
        {
          letterTemplateId: this.letterTemplateId,
          clientId: this.clientId,
          propertyId: this.propertyId,
          projectId: this.projectId,
          teamId: this.teamId,
          type: this.type,
        },
        "url"
      )
    },
    complete({ url }) {
      this.visible = false
      App.flashy(this.$t("letterGenerator.form.success"))
      this.$emit("letter-generated", url)
    },
  },
}
</script>
