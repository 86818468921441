import { useQuery } from "@tanstack/vue-query"
import QueryKeys from "../query-keys"
import ServiceProvider from "../services/service-provider"

export const fetchMemberships = async () => {
  const response = await ServiceProvider.is24CustomerService.getMemberships()

  if (response.isSuccessful2xx) {
    return response.data ?? null
  }

  return null
}

export const useMembershipsQuery = () => {
  return useQuery({
    queryKey: QueryKeys.integrations.all(),
    queryFn: () => fetchMemberships(),
    // refetchOnMount: false,
  })
}
