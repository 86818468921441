<template>
  <aside v-if="brokerIds.length" class="relative">
    <div class="absolute right-5 -top-2 border border-yellow-300 bg-yellow-50 rounded-md flex items-center py-1 px-3">
      <span class="text-sm mr-2 text-yellow-700">schaut sich ebenfalls an:</span>
      <broker-avatars :brokerIds="brokerIds" size="20px" />
    </div>
  </aside>
</template>

<script>
import useEnvironment from "@/plugins/use-environment"

export default {
  props: {
    resourceId: {
      type: Number,
      required: true,
    },
    resourceType: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { isBackdoor } = useEnvironment()
    return { isBackdoor }
  },
  data() {
    return {
      presentBrokers: [],
    }
  },
  computed: {
    brokerIds() {
      return this.presentBrokers.filter(id => id !== this.$db.broker.id)
    },
  },
  mounted() {
    if (this.isBackdoor) return

    const channel = this.$pusher.subscribe(`presence-${this.resourceType}-${this.resourceId}`)
    channel.bind("pusher:subscription_succeeded", () => {
      console.log("pusher:subscription_succeeded", channel.members.members)
      this.presentBrokers = Object.keys(channel.members.members).map(Number)
    })
    channel.bind("pusher:member_added", () => {
      console.log("pusher:member_added", channel.members.members)
      this.presentBrokers = Object.keys(channel.members.members).map(Number)
    })
    channel.bind("pusher:member_removed", () => {
      console.log("pusher:member_removed", channel.members.members)
      this.presentBrokers = Object.keys(channel.members.members).map(Number)
    })
  },
  beforeUnmount() {
    if (this.isBackdoor) return

    this.$pusher.unsubscribe(`presence-${this.resourceType}-${this.resourceId}`)
  },
}
</script>
