<template>
  <div class="ps-card">
    <slot />
  </div>
</template>

<style scoped>
.ps-card {
  border-radius: 3px;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
  background: white;
}
</style>