<script setup lang="ts">
import { computed, defineProps, withDefaults } from "vue"
// import { ElProgress } from "element-plus"

interface NiceInputProps {
  percentage: number
  showText: boolean
  type: string
  subtype: string
  status: string
  excessBar: boolean
}

const props = withDefaults(defineProps<NiceInputProps>(), {
  showText: true,
  type: "line",
  excessBar: false,
})

const computePercentage = computed(() => {
  if (props.percentage < 0) {
    return 0
  }
  if (props.percentage > 100) {
    return 100
  }
  return props.percentage
})

const computedStyleClass = computed(() => {
  switch (props.subtype) {
    case "contractDuration": {
      if (props.percentage >= 31) {
        return "green-bar"
      } else if (props.percentage <= 15) {
        return "red-bar"
      } else {
        return "orange-bar"
      }
    }
    case "commissionBar": {
      if (props.excessBar) return "darkred-bar excess-progress-bar"
      if (props.percentage < 100 || props.percentage > 100.00001) {
        return "red-bar"
      } else {
        return "green-bar"
      }
    }
  }
  return ""
})
</script>

<template>
  <el-progress
    :percentage="computePercentage"
    :show-text="showText"
    :class="computedStyleClass"
    :type="type"
    :status="status"
  >
    <slot></slot>
  </el-progress>
</template>

<style>
.green-bar > .el-progress-bar > .el-progress-bar__outer > .el-progress-bar__inner {
  background-color: #5cb87a;
}
.orange-bar > .el-progress-bar > .el-progress-bar__outer > .el-progress-bar__inner {
  background-color: #e6a23c;
}
.red-bar > .el-progress-bar > .el-progress-bar__outer > .el-progress-bar__inner {
  background-color: #f56c6c;
}

.darkred-bar > .el-progress-bar > .el-progress-bar__outer > .el-progress-bar__inner {
  background-color: #7f1d1d;
}

.excess-progress-bar {
  transform: rotate(180deg);
}
.excess-progress-bar .el-progress-bar__outer {
  background: transparent;
}
</style>