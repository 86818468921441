<template>
  <div
    class="cursor-pointer p-2 rounded border relative transition-all"
    @click="visit"
    style="flex: 0 0 auto; min-height: 58px"
    :class="{
      archived: resource.done,
      'bg-gray-50 border-gray-100 hover:border-gray-200 hover:bg-gray-100': resource.priority === 0,
      'bg-red-50 hover:bg-red-100 border-red-200 hover:border-red-300': resource.priority > 0 && !resource.done,
      'bg-blue-50 hover:bg-blue-100 border-blue-200 hover:border-blue-300': resource.priority < 0 && !resource.done,
    }"
  >
    <div>
      <p class="font-medium">
        {{ resource.title }}
      </p>
      <p class="text-sm text-gray-600">
        <span v-for="(c, idx) in resource.clients" :key="c.id">
          <nice-divider direction="vertical" v-if="idx > 0" class="mx-1" />
          <router-link :to="$customFilters.clientPath(c.id)" class="link" @click.stop>
            {{ c.name }}
          </router-link>
        </span>
        <nice-divider direction="vertical" v-if="resource.clients.length && resource.properties.length" class="mx-1" />
        <router-link
          v-for="p in resource.properties"
          :key="p.id"
          :to="`/portfolio/properties/${p.id}`"
          class="link"
          @click.stop
        >
          {{ p.name }}
        </router-link>
      </p>
      <footer class="flex items-center text-sm mt-1 justify-between">
        <aside class="flex items-center">
          <avatar :broker-id="resource.brokerId" size="20px" class="mr-2" />
          <span
            v-if="resource.dueDate"
            :class="{
              'text-red-600': $customFilters.isInPast(resource.dueDate),
              'text-yellow-600': $customFilters.isToday(resource.dueDate),
            }"
          >
            {{ $customFilters.date(resource.dueDate) }}
          </span>
        </aside>
        <comments-indicator resource-type="Task" :resource-id="resource.id" v-model:counter="resource.commentsCount">
          <template v-slot:default="{ counter }">
            <span v-if="counter" class="text-xs text-gray-700">
              {{ counter }}
              <fa-icon name="comment" />
            </span>
          </template>
        </comments-indicator>
      </footer>
    </div>
  </div>
</template>

<script>
import CommentsIndicator from "@/components/CommentsIndicator"
import eventBus from "@/config/event-bus"

export default {
  components: {
    CommentsIndicator,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  methods: {
    visit() {
      eventBus.$emit("quick-view", {
        type: "todo",
        mode: "view",
        id: this.resource.id,
      })
    },
  },
}
</script>
