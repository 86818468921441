<template>
  <form-section title="Filter">
    <form-row v-for="field in activeFields" :key="field.id" :title="field.name">
      <nice-select
        :placeholder="field.name"
        v-model="data[field.id]"
        :options="field.options"
        :multiple="field.multiple"
        @change="resetDependentFields(field.id)"
        v-if="field.type === 'dropdown'"
      />
      <nice-date-picker
        v-model="data[field.id]"
        type="daterange"
        format="dd.MM.yyyy"
        :placeholder="field.name"
        v-else-if="field.type === 'daterange'"
      />
      <nice-checkbox v-model="data[field.id]" v-else-if="field.type === 'checkbox'" />
    </form-row>
  </form-section>
</template>

<script>
export default {
  props: {
    fields: {},
    modelValue: {},
  },
  data(props) {
    return {
      data: props.modelValue || {},
    }
  },
  watch: {
    data: {
      handler: function (val) {
        this.$emit("update:modelValue", val)
      },
      deep: true,
    },
  },
  computed: {
    activeFields() {
      return this.fields
        .map(f => {
          if (f.depends && (!this.data[f.depends] || (f.cases && !f.cases[this.data[f.depends]]))) return
          if (f.type === "dropdown") {
            let field = { ...f, ...(f.depends ? f.cases[this.data[f.depends]] : undefined) }
            const getOptions = f => f && ((f.dboptions && this.$db.shopData[f.dboptions]) || f.options)
            return { ...field, options: getOptions(field) }
          } else {
            return { ...f }
          }
        })
        .filter(Boolean)
    },
  },
  methods: {
    resetDependentFields(item) {
      this.fields
        .filter(f => f.depends === item)
        .forEach(f => {
          delete this.data[f.name]
        })
    },
  },
}
</script>

<style></style>
