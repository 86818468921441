<template>
  <nice-select
    v-if="!loading"
    filterable
    grouped
    clearable
    :modelValue="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @change="$emit('change', $event)"
    placeholder="Textbaustein auswählen"
    children-key="snippets"
    :options="options"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      options: [],
      loading: false,
    }
  },

  methods: {
    async fetchData() {
      this.loading = true
      const { shop } = await this.$graphql(
        `query snippetCategories {
        shop {
          snippetCategories {
            id name
            snippets(forCurrentBroker: true) {
              id name
            }
          }
        }
      }`,
        undefined,
        true
      )
      this.options = shop.snippetCategories
      this.loading = false
    },
  },

  mounted() {
    this.fetchData()
  },
}
</script>
