<template>
  <nice-popover :width="width" transition="none" :visible="visible" placement="top" trigger="click" :teleported="false">
    <div class="flex flex-col mt-1" v-click-outside="hide">
      <span class="text-xl px-4 py-2" v-if="title">{{ title }}</span>
      <ps-data-table
        :columns="columns"
        :data="tableData"
        :per="per"
        :page="page"
        @update:page="updatePage"
        :total="tableTotalCount"
        :loading="loading"
        v-if="columns"
      />
    </div>
  </nice-popover>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 750,
    },
    title: {
      type: String,
    },
    data: {
      type: Object,
    },
    fetch: {
      type: Function,
    },
    per: {
      type: Number,
      default: 10,
    },
    columns: {
      type: Array,
    },
    onStateChange: {
      type: Function,
    },
  },
  watch: {
    data() {
      if (this.visible) return
      this.loading = false
      this.visible = true
      this.page = 1
      this.onStateChange?.(true)
    },
  },
  data() {
    return {
      page: 1,
      visible: false,
      loading: true,
    }
  },
  // per: 25,
  computed: {
    tableData() {
      return this.data?.data || []
    },
    tableTotalCount() {
      return this.data?.meta?.total_count || 0
    },
  },
  methods: {
    hide() {
      if (!this.visible) return
      this.visible = false
      this.loading = true
      this.onStateChange?.(false)
    },
    updatePage(page) {
      this.page = page
      this.fetch?.({ page: this.page, per: this.per })
    },
  },
}
</script>
