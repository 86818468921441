<script lang="ts" setup>
import { templateRef, useToggle, useVModel } from "@vueuse/core"
import { nextTick } from "vue"
import NiceVariableSelect from "./NiceVariableSelect.vue"

const props = defineProps<{
  modelValue?: string
  variableGroup?: string
}>()

const emit = defineEmits(["update:modelValue"])

const formula = useVModel(props, "modelValue", emit, { eventName: "update:modelValue", defaultValue: "" })

const [showVariables, toggleVariables] = useToggle(false)
const variableSelect = templateRef<typeof NiceVariableSelect>("variableSelect")

const onShowVariables = () => {
  toggleVariables(true)

  if (showVariables.value) {
    nextTick(() => {
      variableSelect.value?.focus()
    })
  }
}

const onVariableSelected = ($event: string) => {
  formula.value += $event
}
</script>

<template>
  <div :class="$style.container">
    <nice-input autosize clearable v-model="formula" type="textarea" :class="$style.input" />
    <nice-variable-select
      v-if="showVariables"
      ref="variableSelect"
      size="large"
      :class="$style.append"
      :group="props.variableGroup"
      :placeholder="$t('niceFormulaInput.variableSelect.placeholder')"
      @update:modelValue="onVariableSelected"
      @visible-change="toggleVariables"
    />
    <nice-button
      v-else
      :class="$style.append"
      :title="$t('niceFormulaInput.variableSelect.add')"
      @click="onShowVariables"
    >
      <fa-icon name="plus" />
    </nice-button>
  </div>
</template>

<style module lang="postcss">
.container {
  @apply flex items-stretch transition-all;
}

.input {
  @apply h-full;

  :global textarea {
    @apply font-mono text-nowrap rounded-e-none !min-h-full;
  }
}

.append {
  @apply rounded-s-none -ml-px;

  :global &.el-button {
    @apply bg-gray-50;
  }

  :global &.el-select .el-input__inner {
    @apply rounded-s-none;
  }
}
</style>
