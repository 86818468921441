<template>
  <nice-select
    ref="select"
    :modelValue="modelValue"
    @update:modelValue="handleUpdateValue"
    @change="handleUpdateValue"
    multiple
    filterable
    remote
    allow-create
    :placeholder="placeholder"
    default-first-option
    :id-key="email"
    :remote-method="fetch"
    :loading="loading"
    :size="size"
  >
    <template #tags="{ $onRemoveValue, values }">
      <span v-for="value in values" :key="value">
        <el-tag>
          <span class="text-black">{{ value }}</span>
          <i
            class="fal fa-times clear-tag text-slate-500 hover:text-slate-800 ml-2 cursor-pointer"
            @click.prevent="$onRemoveValue(value)"
          />
        </el-tag>
      </span>
    </template>
    <template #default>
      <el-option
        v-for="item in searchResults"
        :key="item.email"
        :label="item.name + ' (' + (isBackdoor ? 'Redacted' : item.email) + ')'"
        :disabled="!item.email"
        :value="item.email"
      >
        <p class="pull-left">
          {{ item.name }}
          <tooltip
            v-if="item.warnAboutContactAcceptance"
            :content="$t('emailSelect.missingContactPermission')"
            class="ml-1"
          >
            <fa-icon name="exclamation-triangle" class="text-red-400" />
          </tooltip>
        </p>
        <p v-redacted class="pull-right light m-r-30">
          {{ item.email ? item.email : $t("emailSelect.missing") }}
        </p>
      </el-option>
    </template>
  </nice-select>
</template>

<script>
// FIXME: not sure what is going on here, why the inner selectedItems is needed. the whole handling of data seems very confusing, so i will stop here
// also not sure when message.to is actually an array of objects and when it is an array of strings
// also there is zero typing so hard to know what data is passed around to the parent MessageForm

import useEnvironment from "@/plugins/use-environment"
import Fuse from "fuse.js"
import * as _ from "lodash"

let brokerSearch = null

export default {
  props: {
    modelValue: {},
    emails: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      default: "medium",
    },
  },
  setup() {
    const { isBackdoor } = useEnvironment()
    return { isBackdoor }
  },
  data() {
    return {
      searchResults: [],
      selectedItems: [],
      loading: false,
    }
  },
  methods: {
    handleUpdateValue(value) {
      if (this.$refs.select) {
        this.$refs.select.blur()
      }
      const selected = this.searchResults.filter(i => value.includes(i.email))
      this.selectedItems = _.uniqBy(
        this.selectedItems.filter(i => value.includes(i.email)).concat(selected),
        i => i.email
      )

      this.$emit("update:modelValue", value) // this is the only place where the modelValue is updated and working, but the selected model seems not to be expected in the parent component
      this.$emit("update-recipients", selected)
    },
    fetch(q) {
      if (!q) return

      this.loading = true

      return this.$axios.getWithCancel(`/services/contacts?q=${q}`).then(({ data }) => {
        const emails = this.emails
          .filter(email => _.includes(email, q))
          .map(email => ({ email, name: email, type: "email" }))
        const brokers = brokerSearch
          .search(q)
          .map(result => ({ email: result.item.email, name: result.item.name, type: "broker", id: result.item.id }))

        const departments = this.departments
          .filter(d => _.includes(d.name.toLowerCase(), q.toLowerCase()))
          .map(d => ({
            label: this.$t("emailSelect.label", { name: d.name, emails: d.emails.length }),
            emails: d.emails,
            email: d.id,
            name: d.name,
            type: "department",
            id: d.id,
          }))

        this.loading = false
        data = _.flatten(
          data.map(client =>
            client.emails.map(email => ({
              name: client.name,
              email,
              type: "client",
              id: client.id,
              warnAboutContactAcceptance: this.$db.shopData.gdprCompliantMailSending && !client.accept_contact,
            }))
          )
        )
        this.searchResults = _.uniqBy(brokers.concat(data).concat(emails).concat(departments), "email")
      })
    },
  },
  mounted() {
    if (!brokerSearch)
      brokerSearch = new Fuse(
        this.$db.shopData.brokers.filter(b => !b.archived),
        {
          keys: ["name", "email"],
          threshold: 0.1,
        }
      )

    if (this.autofocus) {
      this.$refs.select.visible = true
    }

    // prefill options, in order to select other email adresses from client
    if (this.modelValue.length > 0) {
      this.fetch(this.modelValue[0]).then(() => {
        const selected = this.searchResults.filter(i => this.modelValue.includes(i.email))
        this.$emit("update-recipients", selected)
      })
    }
  },
}
</script>

<style>
.red-label .el-select__tags span .el-tag {
  background-color: rgba(255, 0, 0, 0.267);
}
</style>
