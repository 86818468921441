<template>
  <div class="app-panel px-4 pt-4 pb-5 relative">
    <router-link v-if="backTo" :to="backTo">
      <fa-icon name="long-arrow-left" />
      {{ $t("settings.back") }}
    </router-link>
    <header v-if="showHeader" :style="{ width: headerFixed ? '720px' : null }" class="mb-3">
      <slot name="title">
        <h1 v-if="title" class="text-xl">{{ title }}</h1>
      </slot>
      <slot name="description">
        <article v-if="description" class="mt-2 text-gray-700 section-article" v-html="description"></article>
      </slot>
    </header>
    <main :style="{ width: fixed ? '720px' : null }">
      <slot v-if="!loading"></slot>
      <spinner v-else />
    </main>
  </div>
</template>

<script>
export default {
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    backTo: {
      required: false,
    },
    headerFixed: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showHeader() {
      return this.title || this.$slots.title
    },
  },
}
</script>
