<template>
  <label
    class="nice-checkbox"
    :class="[`type-${props.type}`, props.size, { 'is-checked': isChecked }]"
    :aria-checked="isChecked"
  >
    <input
      type="checkbox"
      class="nice-checkbox__input mr-2"
      :value="value"
      :checked="coerceBooleanAttribute(isChecked)"
      :disabled="coerceBooleanAttribute(disabled)"
      :indeterminate="indeterminate"
      @change="handleUpdate"
    />
    <span v-if="label" class="nice-checkbox__label">
      {{ label }}
    </span>
    <slot></slot>
  </label>
</template>

<script setup lang="ts">
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"
import { computed, nextTick } from "vue"

type CheckboxValue = string | number | boolean

interface CheckboxProps {
  modelValue?: CheckboxValue[] | CheckboxValue
  value?: string | boolean | number
  type?: "default" | "danger"
  size?: "small" | "medium" | "large"
  label?: string
  disabled?: boolean
  indeterminate?: boolean
  trueValue?: CheckboxValue
  falseValue?: CheckboxValue
}

const emit = defineEmits(["change", "update:modelValue"])

const props = withDefaults(defineProps<CheckboxProps>(), {
  modelValue: "",
  type: "default",
  value: "",
  size: "medium",
  label: undefined,
  disabled: false,
  trueValue: true,
  falseValue: false,
})

const isChecked = computed(() =>
  Array.isArray(props.modelValue) ? props.modelValue.includes(props.value) : props.modelValue === props.trueValue
)

const handleUpdate = async event => {
  const isChecked = event.target.checked

  if (props.modelValue instanceof Array) {
    let newValue = [...props.modelValue]

    if (isChecked) {
      newValue.push(props.value)
    } else {
      newValue.splice(newValue.indexOf(props.value), 1)
    }

    emit("update:modelValue", newValue)
    await nextTick()
    emit("change", newValue)
  } else {
    emit("update:modelValue", isChecked ? props.trueValue : props.falseValue)
    await nextTick()
    emit("change", isChecked ? props.trueValue : props.falseValue)
  }
}
</script>

<style lang="postcss">
.nice-checkbox {
  @apply relative inline-flex items-center cursor-pointer;
}

.type-danger.nice-checkbox input {
  @apply text-red-600;
}

.nice-checkbox input {
  @apply form-checkbox h-[14px] w-[14px] rounded text-primary focus:ring-0 focus:ring-offset-0;
}

.nice-checkbox.small input {
  @apply h-[12px] w-[12px];
}

.nice-checkbox.medium input {
  @apply h-[14px] w-[14px];
}

.nice-checkbox.large input {
  @apply h-[16px] w-[16px];
}

.nice-checkbox__label {
  @apply cursor-pointer ml-2;
}
</style>
