<template>
  <el-popover v-bind="{ ...$attrs, ...$props}" ref="popoverRef">
    <template #reference>
      <slot name="reference"></slot>
    </template>
    <slot />
  </el-popover>
</template>

<script>
export default {
  methods: {
    hide() {
      this.$refs.popoverRef.hide()
    },
  },
  expose: ["hide", "popoverRef"],
}
</script>
