<template>
  <section v-if="column.collapsable && collapsed" class="flex text-center mr-2" style="width: 50px">
    <header class="flex flex-col relative flex-grow pt-2" style="padding-left: 10px; padding-right: 10px">
      <span class="cursor-pointer text-gray-400" title="collapse" @click="collapsed = false">
        <fa-icon name="chevron-right" />
      </span>
      <h3 class="text-lg">
        <span class="text-gray-400">{{ column.count || "" }}</span>
      </h3>
    </header>
    <div
      class="rounded bg-gray-400 mt-2 mb-3 shrink-0"
      style="height: 300px; width: 5px"
      :style="{ 'background-color': column.color }"
    />
  </section>
  <section v-else class="rounded h-100 flex flex-col mr-2" style="flex: 0 0 auto" :style="{ width: width }">
    <header style="padding-left: 10px; padding-right: 10px">
      <h3 class="text-lg m-0 flex justify-between">
        <span class="txt-overflow">{{ column.title }}</span>
        <aside>
          <span class="text-gray-400">{{ column.count || "" }}</span>
          <span
            class="cursor-pointer ml-2 text-gray-400"
            title="collapse"
            v-if="column.collapsable"
            @click="collapsed = true"
          >
            <fa-icon name="chevron-left" />
          </span>
        </aside>
      </h3>
      <slot name="header" :column="column"></slot>
    </header>
    <div
      class="rounded bg-gray-400 mt-2 mb-3 shrink-0"
      style="height: 5px; margin-left: 10px; margin-right: 10px"
      :style="{ 'background-color': column.color }"
    />
    <ul
      v-infinite-scroll="scroll"
      :infinite-scroll-disabled="endReached || !column.count"
      class="flex flex-col h-100 overflow-y-scroll"
      style="padding-left: 10px; padding-right: 10px"
    >
      <draggable
        :list="items"
        group="people"
        @end="$emit('end', $event)"
        @change="$emit('item-moved', { event: $event, ordering: items.map(i => i.id) })"
        style="min-height: 300px"
        ghost-class="ghost-kanban-item"
      >
        <template #item="{ element: resource, index: idx }">
          <li :key="`deal-${idx}`" class="mb-2">
            <slot :resource="resource" />
          </li>
        </template>
      </draggable>
      <footer class="flex justify-center py-2" v-if="loading">
        <spinner />
      </footer>
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    column: {
      type: Object,
      required: true,
    },
    initialItems: {
      type: Array,
      required: true,
    },
    infiniteScroll: {
      type: Function,
      required: true,
    },
    width: {
      type: String,
      default: "300px",
    },
  },
  watch: {
    initialItems: {
      handler() {
        this.items = this.$util.clone(this.initialItems)
        if (this.items.length < 15 && this.column.count > 15)
          this.infiniteScroll(this.items.map(i => i.id)).then(_ => {
            this.loading = false
          })
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      collapsed: this.column.initialCollapsedState,
      items: this.$util.clone(this.initialItems),
    }
  },
  methods: {
    scroll() {
      if (this.loading) return
      this.loading = true
      setTimeout(() => this.infiniteScroll(this.items.map(i => i.id)).then(_ => {
        this.loading = false
      }), 200)
    },
  },
  computed: {
    endReached() {
      return this.items.length >= this.column.count
    },
  },
}
</script>

<style>
.ghost-kanban-item {
  opacity: 0.5;
}
</style>
