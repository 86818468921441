<script lang="ts" setup>
import { ElInput } from "element-plus"
import { ref } from "vue"
import { useI18n } from "vue-i18n"

const { t } = useI18n()

const input = ref<typeof ElInput | null>(null)

const visible = defineModel<boolean>({
  required: true,
  default: false,
})

const emit = defineEmits(["update:modelValue"])

const props = withDefaults(
  defineProps<{
    title: string
    url: string
  }>(),
  {
    title: "",
    url: "",
  }
)

const copy = () => {
  input.value?.$el.querySelector("input")?.select()
  document.execCommand("copy")
  App.flashy(t("linkCopyModal.success"))
}

const closeCallback = () => emit("update:modelValue", false)
</script>

<template>
  <nice-dialog width="500px" append-to-body :model-value="visible" :title="title" @closed="closeCallback">
    <p class="mb-2 text-lg text-center font-medium">{{ $t("linkCopyModal.share") }}</p>
    <nice-input ref="input" :model-value="props.url" class="w-full" />
    <nice-button type="primary" @click="copy" class="mt-2 w-full">
      <fa-icon name="clipboard" class="mr-1" />
      {{ $t("linkCopyModal.copy") }}
    </nice-button>
  </nice-dialog>
</template>
