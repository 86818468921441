<script setup lang="ts">
import { useMembership } from "@/integrations/use-membership"
import { get, toRef } from "@vueuse/core"
import { computed, watch } from "vue"
import { useI18n } from "vue-i18n"

const props = defineProps<{
  membershipId: string
}>()

const membershipId = toRef(() => props.membershipId)
const { membership, isPending } = useMembership(membershipId)

const { t } = useI18n()

const translatedValue = computed(() => {
  const _membership = get(membership)
  if (!_membership || _membership === "UNKNOWN") return
  return t(`integrations.is24-memberships.status.${_membership}`)
})
</script>
<template>
  <nice-badge :value="translatedValue" custom-color="#00d9b1" v-if="!isPending && translatedValue" />
</template>
