<template>
  <div :class="{ archived: property.archived }">
    <p class="d-flex justify-content-between align-items-center">
      <strong>{{ property.name }}</strong>
      <nice-label v-if="status" :label="status.name" :color="status.color" mini class="ml-2" />
    </p>
    <p v-if="property.unit_id && !property.project_id" class="light">
      {{ property.street_number }}
    </p>
    <p v-if="property.project" class="light">{{ property.project }}</p>
  </div>
</template>

<script>
export default {
  props: ["property"],
  computed: {
    status() {
      return this.$db.get("propertyStatuses").find(s => s.id == this.property.property_status_id)
    },
  },
}
</script>
