<template>
  <nice-upload
    drag
    :action="action"
    :multiple="multiple"
    :with-credentials="true"
    :show-file-list="false"
    :before-upload="beforeUpload"
    :on-success="onSuccess"
    :on-error="onError"
    :accept="accept"
    :disabled="uploading"
    :style="{ width: width }"
  >
    <div :class="styleRow ? 'flex flex-row items-center py-1 px-2' : 'p-3'">
      <fa-icon name="cloud-upload" class="text-gray-400" :class="styleRow ? 'mr-2' : 'text-3xl'" />
      <p>
        <spinner v-if="uploading" />
        <small v-else>{{ $t(title) }}</small>
      </p>
    </div>
  </nice-upload>
</template>

<script>
export default {
  emits: ["success", "error"],
  props: {
    action: String,
    accept: {
      type: String,
      default: "image/jpeg,image/png",
    },
    title: {
      type: String,
      default: "uploadArea.uploadPhoto",
    },
    width: {
      type: String,
      default: "100%",
    },
    styleRow: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploading: false,
    }
  },
  methods: {
    beforeUpload() {
      this.uploading = true
    },
    onSuccess(response) {
      this.uploading = false
      this.$emit("success", response)
    },
    onError(response) {
      this.uploading = false
      this.$emit("error", response)
      try {
        this.$axios.handleError({ response: { data: JSON.parse(response.message) } })
      } catch {
        this.$axios.handleError(response)
      }
    },
  },
}
</script>

<style>
.el-upload {
  width: 100%;
  --el-upload-dragger-padding-horizontal: 0px;
  --el-upload-dragger-padding-vertical: 0px;
}
.el-upload-dragger {
  width: 100%;
  height: initial;
}
</style>
