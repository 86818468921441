<template>
  <section class="dropdown-section">
    <slot />
  </section>
</template>

<style scoped>
.dropdown-section {
  padding: 6px 0;
}
.dropdown-section:not(:last-child) {
  box-shadow: rgba(55, 53, 47, 0.09) 0px 1px 0px;
}
</style>