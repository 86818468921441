<template>
  <div class="w-full h-full flex flex-col" v-if="isInit">
    <announcement />
    <div id="main-header" class="mainHeader" :style="{ background: color }">
      <header-app
        :initial-tracking-unread-counter="$db.broker.unreadTrackingsCount"
        :initial-notification-unread-counter="$db.broker.unreadNotificationsCount"
        @logout="logout"
      />
    </div>
    <pusher-beams v-if="!isDevBackdoor" class="pusher-warning" :user-id="$db.broker.glid" :broker="$db.broker" />
    <router-view class="main-container" />
    <quick-view />
    <help-button v-if="$db.planActive('pro', 'reshare')" :unread-messages="unreadMessages" />
    <csat @submitted="notify" />
  </div>

  <initital-loading-screen v-else id="page-loader" />
</template>

<script>
import HeaderApp from "@/components/layouts/Header.vue"
import Csat from "@/components/Csat.vue"
import QuickView from "@/views/QuickView.vue"
import Announcement from "@/components/layouts/Announcement.vue"
import HelpButton from "@/components/layouts/HelpButton.vue"
import shortcuts from "@/utils/shortcuts"
import config from "@/config/config"
import addHeader from "@/utils/add-header"
import eventBus from "@/config/event-bus"
import InititalLoadingScreen from "./InititalLoadingScreen.vue"
import PusherBeams from "@/components/PusherBeams"
import { initializeRoute, validateRoute } from "@/router"
import { datadogRum } from "@datadog/browser-rum"
import useEnvironment from "@/plugins/use-environment"
import useHeaderScripts from "@/plugins/use-header-scripts"

export default {
  components: { HeaderApp, QuickView, Announcement, HelpButton, InititalLoadingScreen, PusherBeams, Csat },
  setup() {
    const { isDevBackdoor, isBackdoor, isIntercomActive, isTranslation } = useEnvironment()
    const { registerScript, unregisterAllScripts } = useHeaderScripts()
    return { isIntercomActive, isBackdoor, isDevBackdoor, isTranslation, registerScript, unregisterAllScripts }
  },
  data() {
    return {
      isInit: false,
      shouldRefresh: false,
      headers: {},
      unreadMessages: 0,
    }
  },
  watch: {
    $route() {
      if (!this.shouldRefresh) return
      this.retrieveData()
    },
  },
  computed: {
    color() {
      return this.$db.broker.headerColor || "#0f55eb"
    },
  },
  methods: {
    notify(msg) {
      App.flashy(msg)
    },
    $refresh() {
      this.isInit = false
      this.$nextTick(_ => (this.isInit = true))
    },
    async retrieveData() {
      try {
        await this.$db.retrieve()
        eventBus.$emit("db:loaded")
        this.shouldRefresh = false
        if (!validateRoute(this.$router, this.$route.path)) return false

        initializeRoute(this.$router, this.$route.path)
        return true
      } catch (e) {
        if (e.response?.status == 401) {
          this.$db.logout()
          datadogRum.clearUser()
          this.$router.replace("/login")
          return false
        }
        throw e
      }
    },
    async initialize() {
      const success = await this.retrieveData()
      if (!success) return

      this.$db.fetchStats().then(() => {
        this.$store.commit("lastVisited/setItems", this.$db.broker.lastVisited)
      })
      this.$store.commit("searchFilter/setItems", this.$db.broker.searchFilters)
      this.$store.commit(
        "inbox/setInboxBrokerId",
        this.$route.query.inboxBrokerId ? Number(this.$route.query.inboxBrokerId) : this.$db.brokerId
      )

      this.bootIntercom()
      this.setupDDSession()
      this.authenticateZendesk()

      if (!this.isTranslation && ["de", "en", "hu", "es"].includes(this.$db.broker.locale)) {
        // FIXME: does this work?
        this.$root.$i18n.locale = this.$db.broker.locale
      }

      const broker = this.$db.broker.name
      const shortcut = /Mac/.test(navigator.platform) ? "command+d" : "ctrl+d"
      shortcuts.bind(shortcut, "all", e => {
        e.preventDefault()
        const date = moment().format("L HH:mm")
        const stamp = `${date} – ${broker}: `
        document.execCommand("insertHTML", false, stamp)
      })
      // moment.tz.setDefault(moment.tz.guess() || "Europe/Berlin")
      moment.updateLocale(this.$db.broker.locale, {
        week: { dow: 1 }, // Monday is the first day of the week
        calendar: {
          lastDay: this.$t("momentjs.lastDay"),
          sameDay: this.$t("momentjs.sameDay"),
          nextDay: this.$t("momentjs.nextDay"),
          lastWeek: "L",
          nextWeek: "dddd",
          sameElse: "L",
        },
      })

      // this.addGmaps()
      this.$pusher.init(this.$db.shopData.realtimeSubscriptionNamespace)
      this.$pusher.on(`new-version`, v => {
        console.debug("new-version", v)
        this.shouldRefresh = true
      })
      this.$pusher.on("shop:update", ({ model, data }) => {
        if (data) {
          this.$db.setPartialData(model, data)
        } else {
          this.$db.fetchPartialData(model, false)
        }
      })
      this.$pusher.on(`broker:${this.$db.broker.id}:update`, ({ model, data }) => {
        if (data && model) {
          this.$db.setPartialDataBroker(model, data)
        } else if (model) {
          this.$db.fetchPartialDataBroker(model, false)
        } else if (data) {
          this.$db.setBrokerData(data)
        } else {
          this.$db.fetchBrokerData(false)
        }
      })

      this.isInit = true
    },
    setupDDSession() {
      if (this.isDevBackdoor) return

      datadogRum.setUser({
        shopId: this.$db.shopData.id,
        id: this.$db.broker.id,
        name: this.$db.broker.name,
        email: this.$db.broker.email,
        languange: {
          profile: this.$db.broker.locale,
        },
        build: {
          version: this.$db.version,
        },
      })
    },
    authenticateZendesk() {
      if (this.isDevBackdoor) return

      const self = this

      this.createUpdateZendeskUser().then(() => {
        this.registerScript("zendesk", {
          async: true,
          id: "ze-snippet",
          src: `https://static.zdassets.com/ekr/snippet.js?key=${config.ZENDESK_APP_ID}`,
          onload: () => {
            self.$axios
              .post("/zendesks/authenticate", {})
              .then(response => {
                const jwtToken = response.data.token
                window.zE?.("messenger", "loginUser", callback => {
                  callback(jwtToken)
                  window.zE?.("messenger:on", "unreadMessages", count => {
                    this.unreadMessages = Number(count) || 0
                  })
                })
              })
              .catch(error => {
                console.log(error)
              })
          },
        })
      })
    },
    async createUpdateZendeskUser() {
      this.$axios.post("/zendesks/users", {}).catch(error => {
        console.log(error)
      })
    },
    async logout() {
      if (window.zE) {
        zE("messenger", "close")
        zE("messenger", "logoutUser")
      }
      datadogRum.clearUser()
      App.flashy(this.$t("header.logoutSuccess"))

      await this.$db.logout()

      this.$router.push("/login")
      window.location.reload()
    },
    bootIntercom() {
      if (!this.isIntercomActive) return

      const intercom = {
        app_id: config.INTERCOM_APP_ID,
        alignment: "left",
        name: this.$db.broker.name,
        email: this.$db.broker.email,
        phone: this.$db.broker.phone,
        user_id: this.$db.broker.intercomId,
        user_hash: this.$db.broker.intercomHash,
        created_at: this.$db.broker.createdAt,
        avatar: this.$db.broker.avatarUrl
          ? {
              type: "avatar",
              image_url: this.$db.broker.avatarUrl,
            }
          : undefined,
        company: {
          id: this.$db.shopData.id,
          name: this.$db.shopData.name,
          created_at: this.$db.shopData.createdAt,
          website: this.$db.shopData.homepage,
          upgrade: this.$db.shopData.upgrade,
        },
        "Login Link": this.$db.broker.apolloUrl,
        admin: this.$db.broker.admin,
      }

      this.registerScript("intercom", {
        async: true,
        src: `https://widget.intercom.io/widget/${config.INTERCOM_APP_ID}`,
        onload: () => {
          Intercom("reattach_activator")
          Intercom("boot", intercom)
        },
      })
    },
  },
  mounted() {
    this.initialize()
  },

  beforeUnmount() {
    if (google && google.maps) delete google.maps
    this.$pusher.off(`new-version`)
    shortcuts.unbind("command+d,ctrl+d")
    this.unregisterAllScripts()
  },
}
</script>

<style>
.main-container {
  height: calc(100% - 44px);
}

.pusher-warning {
  margin-bottom: 20px;
  padding: 20px;
}

iframe#launcher {
  left: 10px !important;
  display: none;
}

iframe[name="Messaging-Fenster"] {
  left: 10px !important;
}
</style>
