<template>
  <nice-label
    v-if="status"
    :label="status.name"
    :color="status.color"
    :clickable="clickable"
    @click="clickable ? $emit('click') : null"
  />
</template>

<script>
export default {
  emits: ["click"],
  props: {
    clickable: {
      type: Boolean,
      default: true,
    },
    clientStatusId: {},
  },
  computed: {
    status() {
      return this.$db.get("clientStatuses").find(o => o.id == this.clientStatusId)
    },
  },
}
</script>
