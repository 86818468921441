<template>
  <div>
    <div class="form-group">
      <nice-select
        filterable
        fixed-position
        v-model="ta.payload.propertyStatusId"
        :placeholder="'Status auswählen (aktueller Status: ' + currentStatusName + ')'"
        :options="shopData.propertyStatuses"
      ></nice-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shopData: {
      type: Object,
      required: true,
    },
    ta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentStatusName() {
      return (
        this.shopData.properties[0] &&
        this.shopData.properties[0].propertyStatus &&
        this.shopData.properties[0].propertyStatus.name
      )
    },
  },
}
</script>
