<template>
  <form-dialog
    title="Dokumente anhängen"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    :disabled="!selected.length"
    @submit="handleSubmit"
    @close="$emit('close')"
    append-to-body
    :submit-button-title="`${selected.length} Dokument(e) anhängen`"
  >
    <spinner v-if="loading" />
    <div v-else-if="resource">
      <p v-if="!resource.documents.length">{{ title }} hat keine Dokumente</p>
      <header v-else class="mb-3">
        <p class="text-lg font-semibold">{{ title }} hat {{ resource.documents.length }} Dokumente</p>
        <p>Wähle die gewünschten Dokumente zum Anhängen aus.</p>
      </header>
      <section class="w-100">
        <card v-for="item in resource.documents" :key="item.id" class="mb-2 w-100">
          <nice-checkbox
            v-model="selected"
            :value="item.id"
            class="documents-checkbox flex items-center w-100 py-1 px-3"
          >
            <main class="w-100 flex items-center">
              <file-icon :contentType="item.contentType" class="text-2xl ml-1 mr-3 relative" />
              <p class="w-100">{{ item.title }}</p>
              <p class="ml-2 text-sm text-gray-600 whitespace-nowrap relative" style="top: 1px">
                {{ $customFilters.humanFileSize(item.fileSize) }}
              </p>
            </main>
          </nice-checkbox>
        </card>
      </section>
    </div>
  </form-dialog>
</template>

<script setup lang="ts">
import useCore from "@/plugins/use-core"
import { ref, watch, computed } from "vue"

const props = withDefaults(
  defineProps<{
    visible?: boolean
    ids: number[]
    type: string
  }>(),
  {
    visible: false,
  }
)

const { graphql } = useCore()

const selected = ref<string[]>([])
const loading = ref(true)
const resource = ref<{ name: string; documents: any[] } | null>(null)

const emit = defineEmits(["submit", "close", "update:visible"])

watch(
  () => props.visible,
  async isVisible => {
    if (isVisible) {
      await fetchData()
    }
  }
)

const fetchData = async () => {
  const { type, ids } = props
  if (!type || !ids.length) {
    return
  }

  loading.value = true
  resource.value = null

  const data = await graphql(`query ${type}DocsModal {
    ${type}(id: ${ids[0]}) {
      name
      documents {
        id url title tags contentType fileSize createdAt folderId
        isPrivate onLandingPage isExposee isFloorplan
      }
    }
  }`)

  resource.value = data[type]
  loading.value = false
}

const title = computed(
  () =>
    resource.value?.name || (props.type === "property" ? "Objekt" : props.type === "project" ? "Projekt" : "Kontakt")
)

const handleSubmit = () => {
  if (!resource.value) return
  emit(
    "submit",
    resource.value.documents.filter(d => selected.value.includes(d.id))
  )
}
</script>

<style>
.documents-checkbox .el-checkbox__input {
  position: relative;
  top: 1px;
}
.documents-checkbox .el-checkbox__label {
  width: 100%;
}
</style>
