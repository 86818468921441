<template>
  <div>
    <div class="mb-2" v-if="!ta.prefilledReservationReasonId">
      <nice-select
        filterable
        fixed-position
        v-model="ta.payload.reservationReasonId"
        placeholder="Absagegrund auswählen"
        :options="shopData.cancelationReasons"
      ></nice-select>
    </div>
    <div class="mb-2">
      <input type="text" v-model="ta.payload.title" class="form-control" placeholder="Titel der Absage (optional)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shopData: {
      type: Object,
      required: true,
    },
    ta: {
      type: Object,
      required: true,
    },
  },
}
</script>
