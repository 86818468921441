<template>
  <div>
    <nice-popover placement="bottom" width="400" trigger="click" transition="none">
      <template #reference>
        <slot name="reference"></slot>
      </template>
      <template #default>
        <div class="el-popover-inner">
          <div class="standardList-wrap">
            <worklist-item
              v-for="item in workLists"
              :key="item.id"
              :item="item"
              :selected="selectedWorkList && item.id == selectedWorkList.id"
              @click="$emit('select-worklist', item)"
              @edit="editWorklist(item)"
              @delete="$emit('delete-worklist', item)"
            />
          </div>
        </div>
      </template>
    </nice-popover>
    <form-dialog
      :name="$t('worklists.editForm.title')"
      :id="currentWorklist && currentWorklist.id"
      :visible="dialogVisible"
      @close="dialogVisible = false"
      @submit="submitEdit"
      append-to-body
    >
      <form-section>
        <form-row :title="$t('worklists.editForm.name')">
          <nice-input size="small" v-model="name" />
        </form-row>
        <form-row :title="$t('worklists.editForm.public')">
          <nice-switch v-model="isPublic" />
        </form-row>
        <form-row v-if="!isPublic" :title="$t('worklists.editForm.specificBrokers')">
          <db-select fixed-position collection="activeBrokers" multiple v-model="brokerIds" />
        </form-row>
        <form-row v-if="!isPublic" :title="$t('worklists.editForm.specificDepartments')">
          <db-select fixed-position collection="departments" multiple v-model="departmentIds" />
        </form-row>
      </form-section>
    </form-dialog>
  </div>
</template>

<script>
import WorklistItem from "./WorklistItem.vue"

export default {
  components: { WorklistItem },
  props: ["selectedWorkList", "workLists"],
  data() {
    return {
      dialogVisible: false,
      currentWorklist: undefined,
      name: "",
      brokerIds: [],
      departmentIds: [],
      isPublic: false,
    }
  },
  methods: {
    editWorklist(item) {
      this.currentWorklist = item
      this.name = item.name
      this.isPublic = item.isPublic
      this.brokerIds = item.brokerIds
      this.departmentIds = item.departmentIds
      this.dialogVisible = true
    },
    submitEdit() {
      this.$axios
        .patch(`/services/search_filters/${this.currentWorklist.id}`, {
          name: this.name,
          is_public: this.isPublic,
          broker_ids: this.isPublic ? [] : this.brokerIds,
          department_ids: this.isPublic ? [] : this.departmentIds,
        })
        .then(() => {
          this.$store.commit("searchFilter/updateItem", [
            {
              id: this.currentWorklist.id,
              name: this.name,
              brokerId: this.currentWorklist.brokerId,
              isPublic: this.isPublic,
              brokerIds: this.isPublic ? [] : this.brokerIds,
              departmentIds: this.isPublic ? [] : this.departmentIds,
              forEntity: this.currentWorklist.forEntity,
              workList: this.currentWorklist.workList,
              params: this.currentWorklist.params,
            },
          ])
          this.name = ""
          this.brokerIds = []
          this.dialogVisible = false
          this.isPublic = false
        })
    },
  },
}
</script>
