<template>
  <nice-input
    v-bind="$attrs"
    type="textarea"
    :model-value="modelValue"
    @update:model-value="$emit('update:model-value', $event)"
    @change="$emit('change', $event)"
    :autosize="autosize"
    size="small"
    @enter="$attrs.resize == 'none' ? $event.preventDefault() : undefined"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  expose: ["setValue"],
  props: {
    modelValue: {
      type: String,
    },
    minRows: {
      type: Number,
      default: 3,
    },
    maxRows: {
      type: Number,
      default: 60,
    },
  },
  methods: {
    setValue(value) {
      this.$emit("update:modelValue", value)
    },
  },
  computed: {
    autosize() {
      return { minRows: this.minRows, maxRows: this.maxRows }
    },
  },
})
</script>
<style>
.el-textarea__inner {
  text-overflow: ellipsis;
  word-break: break-word;
}
</style>
