<template>
  <nice-select
    ref="select"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @change="changed"
    :multiple="multiple"
    :fixed-position="true"
    filterable
    clearable
    remote
    allow-create
    :options="items"
    :label-fn="key => key"
    :placeholder="placeholder"
    default-first-option
    :remote-method="fetch"
  >
    <el-option v-for="email in items" :key="email" :label="email" :value="email" />
  </nice-select>
</template>

<script>
import Fuse from "fuse.js"

let brokerSearch = null

export default {
  emits: ["update:modelValue", "change"],
  props: {
    modelValue: {},
    multiple: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: "max@muster.de",
    },
  },
  data() {
    return {
      items: [],
      loading: false,
    }
  },
  methods: {
    changed($event) {
      this.$emit("change", $event)
      this.$refs.select.blur()
    },

    fetch(q) {
      if (!q) return

      this.loading = true

      this.$graphql(`{ emailAddresses(q: "${q}") }`).then(({ emailAddresses }) => {
        const brokers = brokerSearch.search(q).map(result => result.item.email)
        this.items = _.uniq(emailAddresses.concat(brokers))
        this.loading = false
      })
    },
  },
  mounted() {
    if (!brokerSearch)
      brokerSearch = new Fuse(
        this.$db.shopData.brokers.filter(b => !b.archived),
        {
          keys: ["name", "email"],
          threshold: 0.1,
        }
      )
  },
}
</script>
