<template>
  <div class="d-flex align-items-center justify-content-center" v-if="modelValue">
    <nice-input
      size="small"
      type="number"
      v-if="commissionType === 'percentage'"
      v-model="modelValue.percentage"
      @change="modelValue.fixed = null"
    />
    <currency-input v-else v-model="modelValue.fixed" @change="modelValue.percentage = null" />
    <nice-radio-group
      v-model="commissionType"
      class="ml-2 whitespace-nowrap"
      :options="[
        { id: 'percentage', name: '%' },
        { id: 'fixed', name: $db.shopData.isoCurrency || 'EUR' },
      ]"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
    },
  },
  data() {
    return {
      commissionType: "percentage",
    }
  },
  mounted() {
    if (this.value) return
    this.$emit("update:modelValue", { percentage: null, fixed: null })
  },
}
</script>
