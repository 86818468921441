import { MembershipsMap } from "../types/membership"
import APIMapping from "./api-mapping"
import { APIClient, ApiResponse } from "./base-client"

export class Is24CustomerService extends APIClient {
  constructor() {
    super(APIMapping.is24CustomerService)
  }

  async getMemberships(): Promise<ApiResponse<MembershipsMap>> {
    return this.invokeApiWithErrorHandling(`/is24-memberships/type`, "GET")
  }
}

export default new Is24CustomerService()
