export const eagerComponents = Object.entries(import.meta.glob("@/components/**/*.vue", { eager: true })).reduce(
  (acc, [path, definition]) => {
    acc[
      path
        .replace(/^@\//, "")
        .replace(/^\.{2}\//, "")
        .replace(/\.vue$/, "")
    ] = (definition as { default: any }).default
    return acc
  },
  {}
)

const requireComponent = path => {
  return eagerComponents[
    path
      .replace(/^@/, "")
      .replace(/^\.{2}/, "")
      .replace(/\.vue$/, "")
  ]
}

export default requireComponent
