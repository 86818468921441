<template>
  <div :data-index="index" class="searchFilter-item">
    <a href="#" @click.prevent="$emit('remove', index)" class="searchFilter-remove">
      <fa-icon name="times" />
    </a>
    <div class="searchFilter-column">
      <nice-select :model-value="property" @update:model-value="propertyChanged($event)" :options="availableColumns" />
    </div>
    <div style="width: 150px" class="mr-2">
      <nice-select :model-value="match" @update:model-value="$emit('match-changed', $event)" :options="operators" />
    </div>
    <div
      :class="{
        'searchFilter-value-hidden': match === 'exists' || match === 'missing',
      }"
      class="searchFilter-operator"
    >
      <div v-if="column.type === 'boolean'" class="full-width">
        <nice-select
          :model-value="value"
          @update:model-value="$emit('value-changed', $event)"
          :options="[
            { id: true, name: 'Ja' },
            { id: false, name: 'Nein' },
          ]"
        />
      </div>
      <div v-if="column.type === 'array' && match === 'exact'" class="full-width">
        <nice-select
          :model-value="value"
          @update:model-value="$emit('value-changed', $event)"
          :options="column.options"
        />
      </div>
      <nice-select
        multiple
        v-if="column.type === 'array' && match !== 'exact'"
        :model-value="value || []"
        @update:model-value="$emit('value-changed', $event)"
        :options="column.options"
      />
      <nice-input
        size="small"
        v-if="column.type === 'number'"
        type="number"
        :model-value="value"
        @update:model-value="$emit('value-changed', $event)"
      />
    </div>
    <div style="width: 100px">
      <nice-input
        size="small"
        type="number"
        placeholder="Punkte"
        :model-value="points"
        @update:model-value="$emit('points-changed', $event)"
      />
    </div>
  </div>
</template>

<script>
const operatorsMap = {
  string: [
    {
      name: "vorhanden",
      id: "exists",
    },
    {
      name: "fehlt",
      id: "missing",
    },
  ],
  exists_or_missing: [
    {
      name: "vorhanden",
      id: "exists",
    },
    {
      name: "nicht vorhanden",
      id: "missing",
    },
  ],
  array: [
    {
      name: "vorhanden",
      id: "exists",
    },
    {
      name: "ist leer",
      id: "missing",
    },
    {
      name: "ist",
      id: "exact",
    },
    {
      name: "eines von",
      id: "in",
    },
    {
      name: "keines von",
      id: "not_in",
    },
  ],
  boolean: [
    {
      name: "ist",
      id: "exact",
    },
  ],
  number: [
    {
      name: "=",
      id: "exact",
    },
    {
      name: "!=",
      id: "isnt",
    },
    {
      name: "<=",
      id: "lte",
    },
    {
      name: ">=",
      id: "gte",
    },
  ],
}

export default {
  props: ["property", "match", "value", "points", "index", "columns"],

  methods: {
    propertyChanged(value) {
      this.$emit("property-changed", value)
      setTimeout(() => {
        this.$emit("match-changed", _.get(this.operators[0], "value"))
      }, 50)
      this.$emit("value-changed", null)
    },
  },

  computed: {
    availableColumns() {
      return this.columns.map(o => ({ id: o.value, name: o.label }))
    },

    column() {
      return _.find(this.columns, col => col.value === this.property) || {}
    },

    operators() {
      return operatorsMap[this.column.type] || []
    },
  },
}
</script>
