export const PredefinedColors = [
  "#9932cc",
  "#d80082",
  "#dc143c",
  "#d98177",
  "#e35c33",
  "#ff8c00",
  "#e49736",
  "#efc04c",
  "#b7c000",
  "#97bf5c",
  "#67ad5b",
  "#419588",
  "#00b3c2",
  "#00bfff",
  "#2e85f0",
  "#1f58e2",
  "#4053af",
  "#6495ed",
  "#af9ed7",
  "#966cab",
  "#800080",
  "#74564a",
  "#807568",
  "#404040",
]
