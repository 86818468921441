import { getCurrentInstance, onBeforeMount, onBeforeUnmount } from "vue"

// This is a client side state cache that is used to store selected states of the component, it does not persist the state across page reloads.

let cache: Record<string, string> = {}

function saveState(key, data) {
  cache[key] = JSON.stringify(data)
}

function getSavedState(key) {
  const savedState = cache[key]
  return savedState ? JSON.parse(savedState) : null
}

export function useTurboStorage(storageKey: string, storageFields: string[]) {
  const restore = () => {
    if (!storageKey || !storageFields) return

    const inst = getCurrentInstance()
    const state = getSavedState(storageKey)

    if (!state || !inst) return

    const stateToRestore = storageFields.reduce((acc, key) => {
      // check if the key is present in the saved state and the instance (null is a valid value)
      if (state[key] && inst.data[key] !== undefined) {
        acc[key] = state[key]
      }
      return acc
    }, {})

    if (!Object.keys(stateToRestore).length) return

    Object.assign(inst.data, stateToRestore)
  }

  const persist = () => {
    if (!storageKey || !storageFields) return
    const inst = getCurrentInstance()
    if (!inst) return

    saveState(storageKey, _.pick(inst.data, storageFields))
  }

  onBeforeMount(restore)
  onBeforeUnmount(persist)

  return { restore, persist, cache }
}
