<template>
  <a @click="selectPosition(item.value)" class="text-black">
    <div :class="`grid-item ${borderStyle} ${value === item.value ? 'active' : ''}`">
      {{ item.label }}
    </div>
  </a>
</template>

<script>
const borderTemplate = [
  { style: "rounded-tl-lg", position: "NorthWest" },
  { style: "rounded-tr-lg", position: "NorthEast" },
  { style: "rounded-bl-lg", position: "SouthWest" },
  { style: "rounded-br-lg", position: "SouthEast" },
]

export default {
  emits: ["position"],
  props: {
    item: {},
    value: {},
  },
  methods: {
    selectPosition(pos) {
      this.$emit("position", pos)
    },
  },
  computed: {
    borderStyle() {
      return borderTemplate.find(t => t.position == this.item.value)
        ? borderTemplate.find(t => t.position == this.item.value).style
        : ""
    },
  },
}
</script>

<style scoped>
.grid-item {
  padding: 8px 12px;
  width: 122px;
  text-align: center;
  background-color: white;
  cursor: pointer;
}
.grid-item:focus,
.grid-item:hover {
  outline: 1px solid #0f55eb;
}

.active {
  outline: 1px solid #0f55eb;
  color: #0f55eb;
}
</style>
