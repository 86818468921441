<template>
  <div class="flex justify-between">
    <span>
      {{ item.name }}
      <tooltip
        v-if="item.accessBrokerIds && item.accessBrokerIds.length > 0"
        class="text-gray-600 text-sm ml-2"
        :content="mapBrokerNames(item.accessBrokerIds)"
      >
        <fa-icon name="user" />
      </tooltip>
      <tooltip
        v-if="item.accessDepartmentIds && item.accessDepartmentIds.length > 0"
        class="text-gray-600 text-sm ml-2"
        :content="mapDepartmentNames(item.accessDepartmentIds)"
      >
        <fa-icon name="users" />
      </tooltip>
      <span v-if="item.format != 'IS24'" class="text-gray-600 text-sm ml-2">
        {{ item.format }}
      </span>
      <scout-membership v-else :membership-id="item.id" class="ml-3 mb-1" />
      <tooltip
        v-if="item.format == 'FACEBOOK' || item.format == 'INSTAGRAM'"
        class="text-gray-600 text-sm ml-2"
        :content="$t('admin.connections.seeAccount')"
      >
        <a :href="`${item.accountUrl}`" class="text-gray-600 ml-1" target="_blank">
          <fa-icon name="external-link" />
        </a>
      </tooltip>
    </span>
  </div>
</template>

<script>
import fp from "lodash/fp"
import ScoutMembership from "./ScoutMembership.vue"

export default {
  props: {
    item: {
      type: Object,
    },
  },
  components: {
    ScoutMembership,
  },
  methods: {
    mapBrokerNames(ids) {
      return fp.pipe(
        list => list.filter(({ id }) => ids.includes(id)),
        list => list.map(({ name }) => name),
        list => list.join(", ")
      )(this.$db.get("brokers"))
    },
    mapDepartmentNames(ids) {
      return fp.pipe(
        list => list.filter(({ id }) => ids.includes(id)),
        list => list.map(({ name }) => name),
        list => list.join(", ")
      )(this.$db.get("departments"))
    },
  },
}
</script>
