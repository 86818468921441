<template>
  <form-dialog
    :title="$t('worklists.form.title')"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    :saving="saving"
    @submit="createWorklist"
    :disabled="name.length <= 0"
    append-to-body
  >
    <form-section>
      <form-row :title="$t('worklists.form.name')">
        <nice-input size="small" v-model="name" />
      </form-row>
      <form-row :title="$t('worklists.form.public')">
        <nice-switch v-model="isPublic" />
      </form-row>
      <form-row v-if="!isPublic" :title="$t('worklists.form.brokersAccess')">
        <db-select fixed-position collection="activeBrokers" multiple v-model="brokerIds" />
      </form-row>
      <form-row v-if="!isPublic" :title="$t('worklists.form.departmentsAccess')">
        <db-select fixed-position collection="departments" multiple v-model="departmentIds" />
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script>
const entities = {
  for_contacts: 1,
  for_properties: 2,
  for_activities: 3,
}

export default {
  props: ["visible", "selected", "entity"],

  data() {
    return {
      name: "",
      isPublic: false,
      brokerIds: [],
      departmentIds: [],
      saving: false,
    }
  },

  methods: {
    createWorklist() {
      if (this.name.length <= 0 || this.saving) return

      this.saving = true
      const params = JSON.stringify({
        filter_set: {
          must: [{ terms: { id: this.selected.map(Number) } }],
        },
        // per: this.per,
        // sort_by: this.sortBy,
        // order: this.order
      })
      this.$axios
        .post("/services/search_filters", {
          search_filter: {
            name: this.name,
            broker_id: this.broker.id,
            is_public: this.isPublic,
            broker_ids: this.isPublic ? [] : this.brokerIds,
            department_ids: this.isPublic ? [] : this.departmentIds,
            for_entity: entities[this.entity],
            work_list: true,
            params,
          },
        })
        .then(({ data }) => {
          this.saving = false
          App.flashy("Arbeitsliste wurde erfolgreich erstellt!")
          this.$store.commit("searchFilter/replaceItem", {
            id: data.id,
            name: this.name,
            brokerId: this.broker.id,
            isPublic: this.isPublic,
            brokerIds: this.isPublic ? [] : this.brokerIds,
            departmentIds: this.isPublic ? [] : this.teamIds,
            forEntity: this.entity,
            workList: true,
            params,
          })
          this.name = ""
          this.brokerIds = []
          this.departmentIds = []
          this.$emit("update:visible", false)
        })
        .catch(err => {
          this.saving = false
          this.$axios.handleError(err)
        })
    },
  },
  computed: {
    broker() {
      return this.$db.broker
    },
  },
}
</script>
