<template>
  <nice-dialog
    :title="dialogTitle"
    :model-value="visible"
    :append-to-body="appendToBody"
    :width="width"
    @open="onOpen"
    @close="close"
    :close-on-click-modal="closeOnClickModal"
  >
    <form ref="form" @submit.prevent="submit">
      <slot :form="resource" />
      <input type="submit" style="display: none" value="Submit" />
    </form>
    <template v-if="showFooter" #footer>
      <span v-if="footerNote && isDevBackdoor" class="flex-1 absolute left-0 text-gray-400 font-mono text-sm mt-2">
        {{ footerNote }}
      </span>
      <div v-if="footerType === 'twoButtons'">
        <nice-button @click="close">{{ $t("formDialog.backBtn") }}</nice-button>
        <nice-button :type="buttonType" :loading="loading" :disabled="disabled" @click="submit">
          {{ $t(submitButtonTitle) }}
        </nice-button>
      </div>
      <div v-else-if="footerType === 'bigButton'">
        <nice-button class="w-100 h-12" :type="buttonType" :loading="loading" :disabled="disabled" @click="submit">
          {{ $t(submitButtonTitle) }}
        </nice-button>
      </div>
      <div v-else-if="footerType === 'custom'">
        <slot name="custom-footer" v-bind="{ loading, disabled, submit }"></slot>
      </div>
    </template>
  </nice-dialog>
</template>

<script>
import useEnvironment from "@/plugins/use-environment"
import { useI18n } from "vue-i18n"

export default {
  emits: ["update:visible", "close", "complete", "submit"],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
    },
    id: {},
    name: {
      type: String,
      required: false,
    },
    saving: {
      type: Boolean,
    },
    submitFunc: {
      type: Function,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: "primary",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    footerType: {
      type: String,
      default: "twoButtons",
    },
    disabled: {
      default: undefined,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "700px",
    },
    submitButtonTitle: {
      type: String,
      default: "formDialog.submit",
    },
    editable: {
      type: Object,
      required: false,
      default: null,
    },
    validate: {
      type: Function,
      required: false,
    },
    footerNote: {
      type: String,
      default: "",
    },
    closeOnClickModal: {
      type: Boolean,
      default: false,
    },
    handleError: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { isDevBackdoor } = useEnvironment()
    return { isDevBackdoor }
  },
  data() {
    return {
      resource: JSON.parse(JSON.stringify(this.editable)),
      submitting: false,
    }
  },
  watch: {
    editable(newValue) {
      this.resource = JSON.parse(JSON.stringify(newValue))
    },
  },
  methods: {
    onOpen() {
      this.$emit("update:visible", true)

      if (this.autofocus) {
        this.$nextTick(_ => {
          this.$refs.form &&
            this.$refs.form.querySelector("*:not(.el-select) input") &&
            this.$refs.form.querySelector("*:not(.el-select) input").focus()
        })
      }
    },
    close() {
      this.$emit("close")
      this.$emit("update:visible", false)
    },
    submit() {
      if (this.disabled || this.submitting) return
      if (this.validate && !this.validate()) return

      if (this.submitFunc) {
        this.submitting = true
        this.submitFunc(this.resource)
          .then(res => {
            this.$emit("complete", res)
          })
          .catch(this.handleError ? this.$axios.handleError : undefined)
          .finally(_ => (this.submitting = false))
      } else {
        this.$emit("submit")
      }
    },
  },
  computed: {
    dialogTitle() {
      return (
        this.title ||
        (this.id
          ? this.$t("formDialog.edit", { name: this.name }) + ` (${this.id})`
          : this.$t("formDialog.create", { name: this.name }))
      )
    },
    loading() {
      return this.saving || this.submitting
    },
  },
}
</script>

<style>
.el-dialog__body {
  padding: 30px 20px;
}

.hide-header .el-dialog__header {
  display: none;
}
.hide-header .el-dialog__body {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
</style>
