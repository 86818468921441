<template>
  <nice-label v-if="status" :label="status.name" :color="status.color" clickable @click="$emit('click')" />
</template>

<script>
export default {
  emits: ["click"],
  props: {
    propertyStatusId: {},
  },
  computed: {
    status() {
      return this.$db.get("propertyStatuses").find(o => o.id == this.propertyStatusId)
    },
  },
}
</script>
