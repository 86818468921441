<template>
  <div v-if="open">
    <slot />
  </div>
</template>

<script>
const addStyles = (win, styles) => {
  styles.forEach(style => {
    let link = win.document.createElement("link")
    link.setAttribute("rel", "stylesheet")
    link.setAttribute("type", "text/css")
    link.setAttribute("href", style)
    win.document.getElementsByTagName("head")[0].appendChild(link)
  })
}

// function copyStyles(sourceDoc, targetDoc) {
//   Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
//     if (styleSheet.cssRules) {
//       // for <style> elements
//       const newStyleEl = sourceDoc.createElement("style")

//       Array.from(styleSheet.cssRules).forEach(cssRule => {
//         // write the text of each rule into the body of the style element
//         newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText))
//       })

//       targetDoc.head.appendChild(newStyleEl)
//     } else if (styleSheet.href) {
//       // for <link> elements loading CSS from a URL
//       const newLinkEl = sourceDoc.createElement("link")

//       newLinkEl.rel = "stylesheet"
//       newLinkEl.href = styleSheet.href
//       targetDoc.head.appendChild(newLinkEl)
//     }
//   })
// }

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 400,
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: () => window.document.title,
    },
    specs: {
      type: Array,
      default: () => ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    },
    styles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      windowRef: null,
    }
  },
  watch: {
    open(newOpen) {
      if (newOpen) {
        this.openPortal()
      } else {
        this.closePortal()
      }
    },
  },
  methods: {
    openPortal() {
      this.windowRef = window.open("", "", this.specs)
      this.windowRef.document.write(`
        <html>
          <head>
            <title>${this.title}</title>
          </head>
          <body>
          </body>
        </html>
      `)
      this.windowRef.document.body.appendChild(this.$el)
      addStyles(this.windowRef, this.styles)
      // copyStyles(window.document, this.windowRef.document)
      this.windowRef.addEventListener("beforeunload", this.closePortal)
      const windowRef = this.windowRef
      setTimeout(() => {
        windowRef.focus()
        windowRef.print()
        this.autoClose && windowRef.document.close()
        this.autoClose && windowRef.close()
      }, this.timeout)
    },
    closePortal() {
      if (this.windowRef) {
        this.windowRef.close()
        this.windowRef = null
        this.$emit("close")
      }
    },
  },
  mounted() {
    if (this.open) {
      this.openPortal()
    }
  },
  beforeUnmount() {
    if (this.windowRef) {
      this.closePortal()
    }
  },
}
</script>